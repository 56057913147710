         import { FlatList, Flex } from "native-base";
import URL from "../helper/baseURL";
import fetchPost from "../helper/fetchPost";
import { useEffect, useState } from "react";
import { useUser } from "../helper/UserContext";
import FavoritoComponent from "./FavoritoComponent";


const FlatListFavoritos = () => {

    const [viajes, setViajes] = useState([]);
    const { userId } = useUser();

    const reloadFavoritos = () => {
        verViajes(); // Esta función ya está definida para cargar los favoritos
    };

    const verViajes = async () => {
        const BASE_URL = URL.BASE_URL;
        const data = new FormData();
        data.append("id_user", userId);

        const url = `${BASE_URL}favoritos/obtener`;
        console.log("id: ", userId)
        const options = {
            method: 'POST',
            body: data

        };
        const res = await fetchPost(url, options);
        console.log(res.favoritos)
        setViajes(res.favoritos);
        // 


    }

    useEffect(() => {
        verViajes()
    }, [])


    //Cambia numero de columnas segun el tamaño de pantalla
    const breakpoints = {
        base: 0,
        sm: 480,
        md: 768,
        lg: 992,
        xl: 1280,
    };
    // Determina el número de columnas basado en el ancho de la pantalla
    const numColumns =

        window.innerWidth < breakpoints.sm
            ? 1
            : window.innerWidth < breakpoints.md
                ? 1
                : window.innerWidth < breakpoints.lg
                    ? 2
                    : window.innerWidth < breakpoints.xl
                        ? 3
                        : 4; // Puedes ajustar los valores según tus necesidades


    return (
        <Flex w="100%">



            <FlatList
                style={{ width: '100%', marginTop: 5, paddingHorizontal: '2vw' }}
                contentContainerStyle={{ justifyContent: 'center', alignItems: 'center' }}
                data={viajes}
                numColumns={numColumns}
                key={numColumns.toString()} // Usa el número de columnas como clave
                renderItem={({ item }) => (
                    <FavoritoComponent
                        key={item.ID}
                        imageUri={item.fotoportada}
                        titulo={item.nombre}
                        lugar={item.ubicacion}
                        precio={item.precio}
                        id={item.ID}
                        onFavoritoEliminado={reloadFavoritos}
                    />
                )}
            />





        </Flex>
    );


}

export default FlatListFavoritos;