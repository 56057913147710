import React from "react";
import { useUser } from "../../helper/UserContext";
import Login from "../Login";
import VerUsersComponent from "../../components/admin/VerUsersComponent";


const AdminUsersAll = () => {

    const { tipo, userId } = useUser();


    return (
        <>
            {/* {userId !== null && (
                tipo === "24" ? (
                    <VerUsersComponent />
                ) : (
                    <Login />
                )
            )} */}

            <VerUsersComponent />

        </>
    );
}

export default AdminUsersAll; 