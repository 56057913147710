import { View, Text, Stack, Center } from 'native-base'
import ContactoSimple from '../components/ContactoSimple'

const Privacidad = () => {
  const ImagenDeFondo = {
    backgroundImage: 'url("https://mareaproperties.com.mx/imagenes/privacidad.jpg")', // Reemplaza 'ruta-de-tu-imagen.jpg' con la ubicación de tu imagen
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
  }

  return (
    <View w='100%' bg='#dadada'>
      <View style={ImagenDeFondo} w='100%' h={96}>
        <Text
          style={{ fontFamily: 'Circular', textShadow: '2px 2px 4px rgba(0, 0, 0, 1)' }}
          bold
          fontSize={['6xl']}
          mx={10}
          color='#fff'
          alignSelf='center'
          mt={40}
          letterSpacing={2}
          textAlign='center'>
          POLÍTICA DE PRIVACIDAD
        </Text>
      </View>

      <Stack w='100%' direction={['column', 'column', 'row', 'row', 'row']}>
        <Stack
          direction={'column'}
          w={['90%', '90%', '50%', '50%', '50%']}
          mx='5%'
          my={10}
          alignSelf='center'
          justifyContent='center'>
          <Text bold fontSize='xl'>
            Política de Privacidad
          </Text>
          <Text fontSize='2xl' bold>
            Política de Privacidad de Marea Properties
          </Text>

          {/* Sección Introducción */}
          <Text fontSize='lg' bold>
            1. Introducción
          </Text>
          <Text>
            En Marea Properties, respetamos su privacidad y estamos comprometidos con la protección
            de sus datos personales. Esta política de privacidad explica cómo recopilamos, usamos,
            compartimos y protegemos su información personal en relación con nuestros servicios de
            renta vacacional.
          </Text>

          {/* Sección Información que Recopilamos */}
          <Text fontSize='lg' bold>
            2. Información que Recopilamos
          </Text>
          <Text>
            Podemos recopilar diferentes tipos de información personal, incluyendo: Información de
            Identificación (nombre, dirección de correo electrónico, número de teléfono, dirección
            postal y otros datos de contacto), Información de Pago (detalles de tarjeta de
            crédito/débito y otra información financiera necesaria para procesar pagos), Información
            de reserva (fechas de estancia, preferencias de alojamiento y detalles de compañeros de
            viaje), Información Técnica (dirección IP, datos de navegación, tipo de dispositivo y
            preferencias de idioma).
          </Text>

          {/* Sección Uso de su Información */}
          <Text fontSize='lg' bold>
            3. Uso de su Información
          </Text>
          <Text>
            Utilizamos su información para procesar y gestionar sus reservas, comunicarnos con usted
            sobre su reserva o servicios solicitados, mejorar nuestros servicios y personalizar su
            experiencia, realizar análisis y estudios de mercado, enviarle promociones y ofertas
            especiales, si ha optado por recibirlas.
          </Text>

          {/* Sección Compartir su Información */}
          <Text fontSize='lg' bold>
            4. Compartir su Información
          </Text>
          <Text>
            Podemos compartir su información con proveedores de servicios que nos ayudan con
            operaciones comerciales (como procesamiento de pagos, análisis de datos, etc.),
            autoridades legales o reguladoras, cuando sea requerido por ley, y terceros en caso de
            una fusión, venta, reestructuración, adquisición, empresa conjunta, cesión,
            transferencia o disposición de todo o cualquier parte de nuestro negocio.
          </Text>

          {/* Sección Seguridad de la Información */}
          <Text fontSize='lg' bold>
            5. Seguridad de la Información
          </Text>
          <Text>
            Implementamos medidas de seguridad para proteger su información personal contra el
            acceso no autorizado, alteración, divulgación o destrucción.
          </Text>

          {/* Sección Sus Derechos */}
          <Text fontSize='lg' bold>
            6. Sus Derechos
          </Text>
          <Text>
            Usted tiene derecho a acceder, corregir, eliminar o limitar el uso de su información
            personal. También puede oponerse al procesamiento de sus datos o solicitar su
            portabilidad.
          </Text>

          {/* Sección Cambios en la Política de Privacidad */}
          <Text fontSize='lg' bold>
            7. Cambios en la Política de Privacidad
          </Text>
          <Text>
            Nos reservamos el derecho de modificar esta política de privacidad en cualquier momento.
            Cualquier cambio será comunicado a través de nuestro sitio web o por correo electrónico.
          </Text>
        </Stack>

        <Center direction={'column'} w={['90%', '90%', '40%', '40%', '40%']}>
          <ContactoSimple />
        </Center>
      </Stack>
    </View>
  )
}
export default Privacidad
