import React from 'react';
import Slider from 'react-slick';
import { VStack, Stack, Image, Text, Box, View, Center } from 'native-base';
import { FcPrevious, FcNext } from "react-icons/fc";

const NosotrosSwiper = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    const Testimonio = ({ imagenUrl, titulo, texto, fecha, }) => {
        return (
            <Box alignSelf="center"  >
                <Stack direction={["column", "column", "row", "row", "row"]}
                    space={3} alignItems="center">
                    {/* Imagen grande del testimonio */}
                    <Image
                        source={{ uri: imagenUrl }}
                        alt="Imagen Grande"
                        h={[80]}
                        w={["100%", "100%", "50%", "50%", "50%"]}
                    />
                    {/* VStack para el contenido del testimonio */}
                    <VStack flex={1} px={3}   >

                        {/* Título */}
                        <Text fontSize={["lg", "xl", "2xl", "2xl"]}
                            color="coolGray.800"
                            style={{ fontFamily: 'Circular' }} >
                            {titulo}
                        </Text>
                        {/* Texto */}
                        <Text fontSize={["md", "lg", "lg", "xl", "xl"]}
                            color="muted.700"
                            style={{ fontFamily: 'Avenir' }}>
                            {texto}
                        </Text>

                    </VStack>
                </Stack>
            </Box>
        );
    };



    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block" }} // Personaliza este estilo si es necesario
                onClick={onClick}
            >
                <Box bg="#dadada" mt={-8}>
                    <FcNext size={"2rem"} />
                </Box>

            </div>
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block" }} // Personaliza este estilo si es necesario
                onClick={onClick}
            >
                <Center bg="#dadada" mt={-8} >
                    <FcPrevious size={"2rem"} /> {/* Puedes cambiar el tamaño aquí */}

                </Center>
            </div>
        );
    }


    return (
        <View w="100%" alignSelf="center" mt={5} pb={20} >

            <Box w="100%" alignSelf="center" shadow={8} bg="#fff">
                <Slider {...settings}>
                    <Testimonio
                        imagenUrl="https://mareaproperties.com.mx/imagenes/pasarela-cielo.jpg"
                        titulo="SOBRE NOSOTROS"
                        texto="Al igual que las aguas cristalinas que revela su fondo, proporcionamos a nuestros clientes una visión profesional, clara y honesta. Marea Properties, nace de la visión y representación de nuestra esencia como la fuerza y la constancia de las olas, factores fundamentales para hacer la diferencia en el mercado."
                    />
                    <Testimonio
                        imagenUrl="https://mareaproperties.com.mx/imagenes/playa-clearwater.jpg"
                        titulo="MISIÓN"
                        texto="Brindar servicios de administración y concierge de la más alta calidad para propiedades y desarrollos en el Caribe Mexicano. Nos comprometemos a optimizar el valor de las inversiones de nuestros clientes, garantizando una experiencia excepcional para los huéspedes, a través de una gestión transparente, profesional y enfocada en la integridad."
                    />
                    <Testimonio
                        imagenUrl="https://mareaproperties.com.mx/imagenes/vista-panoramica.jpg"
                        titulo="VISIÓN"
                        texto="Ser líderes reconocidos en el mercado de la administración de propiedades en la Riviera Maya. Buscamos ser la elección preferida de los propietarios e inversionistas, destacándonos por nuestra capacidad para maximizar sus ganancias, mantener la excelencia en la gestión y superar las expectativas de los huéspedes en cada estadía."
                    />

                </Slider>
            </Box>

        </View>

    );
};

export default NosotrosSwiper;
