import React, { useState, useEffect } from 'react'
import {
  Stack,
  View,
  Text,
  Divider,
  Box,
  HStack,
  Select,
  VStack,
  CheckIcon,
  Pressable,
  Input,
  useToast,
  Spinner,
} from 'native-base'
import { azulclaro, turquesa } from '../../helper/colors'
import { useNavigate } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll'
import { BiSolidLock } from 'react-icons/bi'
import fetchPost from '../../helper/fetchPost'
import URL from '../../helper/baseURL'

const FormReserva = ({ id, titulo, cuartos, baños, host, precio }) => {
  const navigate = useNavigate()
  const handleClick = (vista) => {
    scroll.scrollToTop()
    navigate(vista)
  }

  const handlePress = () => {
    window.open('https://app.hoone.io/booking/marea-properties', '_blank')
  }

  const [guestNumber, setGuestNumber] = useState('')

  const [startDate, setStartDate] = useState(null)
  const handleDateChange = (event) => {
    setStartDate(event.target.value) // Establece el nuevo valor de startDate
  }

  const [endDate, setEndDate] = useState(null)
  const handleDateChange2 = (event) => {
    setEndDate(event.target.value) // Establece el nuevo valor de startDate
  }

  const [nombre, setNombre] = useState('')
  const [email, setEmail] = useState('')
  const [telefono, setTelefono] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const toast = useToast()

  const mostrarToast = (mensaje) => {
    toast.show({
      description: mensaje,
      placement: 'top', // Puedes cambiar la ubicación según sea necesario
      render: () => {
        return (
          <Box
            bg={azulclaro}
            p={12}
            rounded='sm'
            mb={5}
            borderWidth={3}
            borderColor={turquesa}
            shadow={7}>
            <Text fontFamily='Circular' color='white' fontSize={'2xl'}>
              {mensaje}
            </Text>
          </Box>
        )
      },
    })
  }

  const validarFormulario = () => {
    // Validar nombre
    if (nombre.length <= 3) {
      window.alert('El nombre debe tener más de 3 letras.')
      return false
    }
    if (startDate === null) {
      window.alert('Debe elegir una fecha de llegada')
      return false
    }
    if (endDate === null) {
      window.alert('Debe elegir una fecha de salida')
      return false
    }

    // Validar correo electrónico
    const regexEmail = /\S+@\S+\.\S+/
    if (!regexEmail.test(email)) {
      window.alert('Por favor, introduce un correo electrónico válido.')
      return false
    }

    // Validar teléfono
    if (!telefono.match(/^\d{10,16}$/)) {
      window.alert('El teléfono debe tener al menos 10 dígitos')
      return false
    }

    return true
  }
  const inmuebleData = titulo + ' Cuartos:' + cuartos + '. Precio: $' + precio + '. id:' + id
  const link = 'https://mareaproperties.com.mx/detalle/' + id

  const handleSubmit = async () => {
    validarFormulario()
    if (!validarFormulario()) {
      return // Detiene la función si la validación falla
    } else {
      setIsLoading(true)
      const BASE_URL = URL.BASE_URL

      const dataContacto = new FormData()
      dataContacto.append('nombre', nombre)
      dataContacto.append('email', email)
      dataContacto.append('telefono', telefono)
      dataContacto.append('llegada', startDate)
      dataContacto.append('salida', endDate)
      dataContacto.append('inmueble', inmuebleData)
      dataContacto.append('huespedes', guestNumber)
      dataContacto.append('link', link)
      const url = `${BASE_URL}enviarcorreoReservacion`
      const options = {
        method: 'POST',
        body: dataContacto,
      }

      console.log('url: ', url)
      try {
        const response = await fetchPost(url, options)
        console.log('respuesta: ', response)
        if (response.status) {
          mostrarToast('¡Mensaje enviado! Nos pondremos en contacto contigo')
          setIsLoading(false)
        } else {
          mostrarToast('Error al enviar el mensaje')
          setIsLoading(false)
        }
      } catch (error) {
        mostrarToast('Error al procesar la solicitud')
        setIsLoading(false)
      }
    }
  }

  return (
    <View w='100%' my={10}>
      <Stack direction={'column'} shadow={5} justifyContent='center' alignItems='center'>
        {/* Stack con titulos, azul */}
        <Stack direction='column' bg={turquesa} borderTopRadius={10} w='100%'>
          <Text
            textAlign='center'
            style={{ fontFamily: 'Circular' }}
            my={2}
            color='#fff'
            fontSize={['xl', '2xl']}>
            ${precio}.00{' '}
            <Text fontFamily='Avenir' fontSize={'lg'}>
              por noche
            </Text>
          </Text>
          <Divider w='30%' alignSelf='center' thickness={2} mb={4} />

          <Stack direction={'row'} w='100%' justifyContent='center' space={2} pb={4}>
            <Text
              fontSize={['xs', 'sm', 'md', 'lg', 'lg']}
              color='#fff'
              style={{ fontFamily: 'Avenir' }}>
              {cuartos} Habitaciones
            </Text>
            <Divider orientation='vertical' h={4} alignSelf='center' thickness={1.5} bg='#fff' />
            <Text
              fontSize={['xs', 'sm', 'md', 'lg', 'lg']}
              color='#fff'
              style={{ fontFamily: 'Avenir' }}>
              {baños} Baños
            </Text>
            <Divider orientation='vertical' h={4} alignSelf='center' thickness={1.5} bg='#fff' />
            <Text
              fontSize={['xs', 'sm', 'md', 'lg', 'lg']}
              color='#fff'
              style={{ fontFamily: 'Avenir' }}>
              {host} Huespedes
            </Text>
          </Stack>
        </Stack>

        {/* Salida  y regreso */}
        {/* <VStack bg='#fff' space={5} w='100%' my={4}>
          <HStack w='100%' space={5} justifyContent='center'>
            <VStack w='40%' space={3}>
              <Text bold>Llegada</Text>
              <Box w={40}>
                <input
                  aria-label='Date'
                  type='date'
                  value={startDate} // Establece el valor del input como startDate
                  onChange={handleDateChange} // Maneja los cambios en la fecha
                />
              </Box>
            </VStack>

            <VStack w='40%' space={3}>
              <Text bold>Regreso</Text>
              <Box w={40}>
                <input
                  aria-label='Date'
                  type='date'
                  value={endDate} // Establece el valor del input como startDate
                  onChange={handleDateChange2} // Maneja los cambios en la fecha
                />
              </Box>
            </VStack>
          </HStack>
        </VStack> */}

        {/* Huespedes */}
        {/* <VStack alignItems='center' space={1} w='100%' alignSelf='center'>
          <HStack w='90%' space={2} alignItems={'center'}>
            <Text style={{ fontFamily: 'Circular' }} bold>
              Huespedes
            </Text>
            <Box flex={1} mt={4}>
              <Select
                selectedValue={guestNumber}
                accessibilityLabel='Huéspedes'
                placeholder='Huéspedes'
                _selectedItem={{
                  bg: 'teal.600',
                  endIcon: <CheckIcon size='5' />,
                }}
                mb={5}
                onValueChange={(itemValue) => setGuestNumber(itemValue)}>
                {[...Array(host).keys()].map((number) => (
                  <Select.Item label={`${number + 1}`} value={`${number + 1}`} key={number} />
                ))}
              </Select>
            </Box>
          </HStack>

          <HStack w='90%' space={2} alignItems={'center'}>
            <Text bold style={{ fontFamily: 'Circular' }}>
              Nombre:
            </Text>
            <Input
              flex={1}
              value={nombre}
              placeholder='Escribe tu nombre'
              onChange={(e) => setNombre(e.target.value)}
            />
          </HStack>

          <HStack w='90%' space={2} alignItems={'center'}>
            <Text bold style={{ fontFamily: 'Circular' }}>
              E-mail:
            </Text>
            <Input
              flex={1}
              value={email}
              placeholder='Escribe tu email'
              onChange={(e) => setEmail(e.target.value)}
            />
          </HStack>

          <HStack w='90%' space={2} alignItems={'center'}>
            <Text bold style={{ fontFamily: 'Circular' }}>
              Teléfono:
            </Text>
            <Input
              flex={1}
              value={telefono}
              placeholder='Escribe tu teléfono'
              onChange={(e) => setTelefono(e.target.value)}
            />
          </HStack>
        </VStack> */}
        {/* enviar formulario */}

        <Pressable
          w='90%'
          py={3}
          bg={azulclaro}
          my={4}
          shadow={7}
          borderRadius={5}
          onPress={() => handlePress()}>
          <Text bold fontSize='lg' color='#fff' textAlign='center'>
            RESERVA AHORA
          </Text>
        </Pressable>

        <Pressable
          onPress={() => handleClick('/Faqs')}
          w='90%'
          py={3}
          borderWidth={1}
          shadow={7}
          borderRadius={5}
          borderColor={'muted.500'}>
          <Text bold fontSize='lg' textAlign='center'>
            ¿Preguntas?
          </Text>
        </Pressable>

        <HStack alignSelf='center' my={3} alignItems='center'>
          <BiSolidLock size='1.5em' />
          <Text fontSize='md'>Experiencia de Reserva Segura</Text>
        </HStack>
      </Stack>
    </View>
  )
}
export default FormReserva
