import { Stack, Image, Text, View, Pressable, Center } from 'native-base'
import React from 'react'

const LocacionesComponent = () => {
  const handlePress = () => {
    window.open('https://app.hoone.io/booking/marea-properties', '_blank')
  }

  const Locacion = ({ imagen, texto }) => {
    // onpress de las funciones :
    // onPress={() => navigate("/Propiedades")}
    return (
      <Pressable
        onPress={() => handlePress()}
        flex={1}
        bg='#fff'
        shadow={1}
        _hover={{
          shadow: 9,
          pb: 4,
          mt: -4,
        }}>
        <Stack h={[64, 72, 80, 80, 96]}>
          <Image
            source={{
              uri: imagen,
            }}
            alt='Marea Properties'
            h={[48, 56, 72, 72, 80]}
          />
          <Center justifyContent='center' alignItems='center' flex={1}>
            <Text
              textAlign='center'
              style={{ fontFamily: 'Circular' }}
              fontSize={['xl', 'xl', 'xl', '2xl', '2xl']}>
              {texto}
            </Text>
          </Center>
        </Stack>
      </Pressable>
    )
  }

  return (
    <View w='100%' flex={1} my={4}>
      <Text
        fontSize={['xl', 'xl', '2xl', '4xl', '4xl']}
        fontWeight='bold'
        textAlign='center'
        my={8}
        px={4}
        color='coolGray.800'
        style={{ fontFamily: 'Circular' }}>
        EXPLORA LAS MEJORES UBICACIONES
      </Text>

      <Stack
        w={['75%', '75%', '99%', '95%', '95%']}
        direction={['column', 'column', 'row', 'row', 'row']}
        mx={'auto'}
        alignSelf='center'
        space={[9, 9, 3, 9, 9]}
        alignContent='center'
        justifyContent='center'>
        <Locacion imagen='https://mareaproperties.com.mx/imagenes/cancun01.jpg' texto='Cancún' />
        <Locacion
          imagen='https://mareaproperties.com.mx/imagenes/carmen01.jpg'
          texto='Playa del Carmen'
        />
        <Locacion imagen='https://mareaproperties.com.mx/imagenes/tulum01.png' texto='Tulum' />
      </Stack>
    </View>
  )
}

export default LocacionesComponent
