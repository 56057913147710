import React, { useState } from 'react'
import { TbGridDots } from 'react-icons/tb'
import {
  HStack,
  View,
  Image,
  VStack,
  Text,
  Pressable,
  ZStack,
  Modal,
  ScrollView,
} from 'native-base'

const FotosPortada = ({ portada, galeria }) => {
  const [showModal, setShowModal] = useState(false)
  console.log('portada: ', portada)
  console.log('galeria: ', galeria)

  const ImgSmall = ({ url, top, button }) => {
    return (
      <Pressable flex={1}>
        <Image
          source={{
            uri: url,
          }}
          alt='Alternate Text'
          w='100%'
          flex={1}
          borderTopRightRadius={top}
          borderBottomRightRadius={button}
        />
      </Pressable>
    )
  }
  const modalTopStyle = {
    marginBottom: 'auto',
    marginTop: 24,
  }

  const ImagenScroll = ({ url }) => {
    return (
      <Image
        source={{
          uri: url,
        }}
        alt='Alternate Text'
        alignSelf='center'
        flex={1}
        h={96}
        w='90%'
        borderRadius={10}
        resizeMode='contain'
      />
    )
  }

  return (
    <View w='100%'>
      <ZStack w='100%' h={96}>
        <HStack w='100%' space={2}>
          <Pressable w={['100%', '100%', '50%', '50%', '50%']}>
            <Image
              source={{
                uri: portada,
              }}
              alt='Alternate Text'
              h={96}
              w='100%'
              borderLeftRadius={10}
            />
          </Pressable>

          <VStack w='25%' space={2} display={{ base: 'none', md: 'flex' }}>
            <ImgSmall url={galeria[0]} />
            <ImgSmall url={galeria[1]} />
          </VStack>
          <VStack w='25%' space={2} display={{ base: 'none', md: 'flex' }}>
            <ImgSmall url={galeria[2]} top={10} />
            <ImgSmall url={galeria[3]} button={10} />
          </VStack>
        </HStack>

        <Pressable
          onPress={() => setShowModal(true)}
          borderWidth={1}
          borderColor={'#000'}
          borderRadius={10}
          bg='muted.300'
          p={2}
          bottom={4}
          right={4}
          alignSelf='flex-end'>
          <HStack space={1} alignItems='center' justifyContent='center'>
            <TbGridDots />
            <Text fontFamily='Circular'> Mostrar todas las fotos</Text>
          </HStack>
        </Pressable>
      </ZStack>

      <Modal isOpen={showModal} onClose={() => setShowModal(false)} size='full' h={'700px'}>
        {' '}
        {/* Paso 3 */}
        <Modal.Content {...modalTopStyle} bg='#fff'>
          <Modal.CloseButton />
          <Modal.Body>
            <VStack space={4}>
              {galeria.map((url, index) => (
                <ImagenScroll key={index} url={url} />
              ))}
            </VStack>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </View>
  )
}

export default FotosPortada
