import { View, Text, Stack } from 'native-base'

const TerminosCondiciones = () => {
  const ImagenDeFondo = {
    backgroundImage: 'url("https://mareaproperties.com.mx/imagenes/privacidad.jpg")', // Reemplaza 'ruta-de-tu-imagen.jpg' con la ubicación de tu imagen
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
  }

  return (
    <View w='100%' bg='#dadada'>
      <View style={ImagenDeFondo} w='100%' h={96}>
        <Text
          style={{ fontFamily: 'Circular', textShadow: '2px 2px 4px rgba(0, 0, 0, 1)' }}
          bold
          fontSize={['6xl']}
          mx={10}
          color='#fff'
          alignSelf='center'
          mt={40}
          letterSpacing={2}
          textAlign='center'>
          TÉRMINOS Y CONDICIONES
        </Text>
      </View>

      <Stack w='80%' direction='column' px='10%' alignSelf='center'>
        <Text fontSize='2xl' bold style={{ fontFamily: 'Circular' }} my={10} alignSelf='center'>
          Marea Properties - Términos y Condiciones{' '}
        </Text>
        {/* Acuerdo de Alquiler */}
        <Text style={{ fontFamily: 'Circular' }} fontSize='lg'>
          Acuerdo de Alquiler, Autorización de Tarjeta de Crédito y Exención de Daños:
        </Text>
        <Text fontSize='md' style={{ fontFamily: 'Avenir' }} textAlign='justify'>
          1. Reservas y Pagos:
          {'\n'}
          Todas las reservaciones son no reembolsables, pero ofrecemos un seguro de cancelación
          opcional al 10% de la tarifa de alquiler bruta. El seguro permite cancelar hasta 48 horas
          antes del check-in. Debe adquirirse en el momento de la reserva y notificarse por correo
          electrónico o llamada telefónica 48 horas antes del check-in. Cualquier cancelación sin
          seguro resultará en la confiscación de todos los pagos.
          {'\n'}
          {'\n'}
          2. Reglas y Regulaciones:
          {'\n'}
          Se aplica una política de tolerancia cero con respecto al ruido, fiestas o molestias. Se
          requiere cumplimiento estricto del reglamento condominal, a ser firmado durante el
          check-in. Los huéspedes se comprometen a adherirse al reglamento condominal y comprenden
          que cualquier violación puede resultar en una multa, que será cargada a su cuenta.
          {'\n'}
          {'\n'}
          3. Firma del Reglamento Condominal:
          {'\n'}
          Al realizar el check-in, todos los huéspedes deben firmar el reglamento condominal,
          comprometiéndose a cumplir con las normas establecidas para garantizar un ambiente
          armonioso y respetuoso.
          {'\n'}
          {'\n'}
          4. Multas por Incumplimiento:
          {'\n'}
          En caso de incumplimiento del reglamento condominal, se aplicarán multas específicas según
          la gravedad de la falta. Dichas multas serán notificadas al huésped y cargadas
          directamente a su cuenta.
          {'\n'}
          {'\n'}
          5. Reservas y Aprobación del Gerente:
          {'\n'}
          Todas las reservas están sujetas a la aprobación del Gerente. Las tarifas están sujetas a
          cambios sin previo aviso. Cualquier problema con la reserva se comunicará dentro de las 48
          horas.
          {'\n'}
          {'\n'}
          6. Propiedad y Transacción de Pago:
          {'\n'}
          El huésped alquila la propiedad solo para fines vacacionales. Todas las transacciones se
          realizan a través de la pasarela de Openpay.
          {'\n'}
          {'\n'}
          7. Llegada y Salida:
          {'\n'}
          Check-in no antes de las 4:00 p.m. y check-out no después de las 11:00 a.m. Opciones de
          Check-in Anticipado y Check-out Tardío disponibles por un costo adicional y sujeto a
          disponibilidad.
          {'\n'}
          {'\n'}
          8. Cancelaciones y Reembolsos:
          {'\n'}
          Todos los pagos son no reembolsables. Pago del Depósito debido fuera de los 60 días de
          llegada. Pago del Saldo debido 60 días antes de la llegada. Cancelaciones dentro de los 60
          días resultarán en pérdida total del pago. Ofrecemos un seguro de alquiler vacacional para
          mitigar pérdidas por cancelaciones.
          {'\n'}
          {'\n'}
          9. Indisponibilidad:
          {'\n'}
          En caso de indisponibilidad, se ofrece la opción de sustituir por una unidad comparable o
          cancelar y reembolsar todos los pagos realizados.
          {'\n'}
          {'\n'}
          10. Responsabilidades del Huésped:
          {'\n'}
          El huésped es responsable de cualquier daño causado a la propiedad durante su estancia. El
          huésped autoriza el cargo en su tarjeta de crédito por cualquier daño no cubierto por el
          depósito de seguridad.
          {'\n'}
          {'\n'}
          11. Conducta del Huésped:
          {'\n'}
          Se espera que los huéspedes se comporten de manera respetuosa y cumplan con las leyes y
          regulaciones locales. Marea Properties se reserva el derecho de terminar el acuerdo y
          solicitar la salida inmediata si se violan las normas.
          {'\n'}
          {'\n'}
          12. Fuerza Mayor:
          {'\n'}
          Marea Properties no será responsable por incumplimientos debido a circunstancias fuera de
          su control, como desastres naturales, actos de terrorismo u otras emergencias.
          {'\n'}
          {'\n'}
          13. Cambios en la Propiedad:
          {'\n'}
          Marea Properties se reserva el derecho de realizar cambios en la propiedad, siempre que se
          ofrezca una unidad comparable o superior.
          {'\n'}
          {'\n'}
          14. Disputas y Legislación Aplicable:
          {'\n'}
          Cualquier disputa se resolverá mediante arbitraje de conformidad con las leyes del lugar
          de la propiedad. Agradecemos su atención y comprensión de estos términos. Estamos
          comprometidos a brindarle una experiencia excepcional. Para consultas adicionales,
          contáctenos.
          {'\n'}
          {'\n'}
        </Text>

        <Text bold>
          {' '}
          {'\n'}
          Marea Properties{'\n'}
          [Incluir Dirección Completa de la Empresa]{'\n'}
          [Teléfono de Contacto]{'\n'}
          [Correo Electrónico de Servicio al Cliente]
        </Text>
      </Stack>
    </View>
  )
}
export default TerminosCondiciones
