import React, { useState, useEffect } from "react";
import { Heading, View, FlatList } from "native-base";
import fetchPost from "../../helper/fetchPost";
import URL from "../../helper/baseURL";
import UsersAdminComponent from "./UsersAdminComponent";
import AsyncStorage from "@react-native-async-storage/async-storage";

const VerUsersComponent = () => {


    const [users, setUsers] = useState(null);

    const getUsers = async (userId) => {
        console.log("id verusers", userId);
        console.log("id verusers tipo", typeof (userId))
        const data = new FormData();
        data.append("ID", userId);
        const url = `${URL.BASE_URL}/verUsuariosAdmin`
        const options = {
            method: 'POST',
            body: data
        };
        const res = await fetchPost(url, options);
        console.log("status: ", res.status);
        console.log("data: ", res.data);
        setUsers(res.data)


    }

    useEffect(() => {
        const fetchAndUseUserId = async () => {
            try {
                // Intenta obtener el ID del usuario de AsyncStorage
                const userId = await AsyncStorage.getItem('userId');

                if (userId !== null) {
                    // Si encontramos un ID, llamamos a getUsers
                    getUsers(userId);
                } else {
                    // Aquí podrías manejar el caso de no encontrar un ID
                }
            } catch (error) {
                // Manejar errores aquí
                console.error("Error al obtener el ID del usuario", error);
            }
        };

        fetchAndUseUserId();
    }, []);




    return (
        <View w="100%" mt={[12, 12, 20, 24]} >
            <Heading alignSelf="center" py={3} size="2xl" fontSize="2xl">
                Usuarios registrados
            </Heading>

            <FlatList
                style={{ width: '100%', marginTop: 5, paddingHorizontal: '2vw' }}
                contentContainerStyle={{ justifyContent: 'center', alignItems: 'center' }}
                data={users} // Asegúrate de que 'users' contiene los datos de tu JSON
                keyExtractor={item => item.ID} // Usamos el ID como clave única
                renderItem={({ item }) => (
                    <UsersAdminComponent
                        nombre={item.Nombre}
                        apellido={item.Apellido}
                        correo={item.Correo}
                        telefono={item.Telefono}
                        id={item.ID}
                    />
                )}
            />
        </View>
    )
}

export default VerUsersComponent;