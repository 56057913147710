import React from "react";
import { useUser } from "../../helper/UserContext";
import Login from "../Login";
import UserPanel from "./UserPanel";
import AdminPanel from "./AdminPanel";

const UserPermiso = () => {
    const { tipo, userId } = useUser();
    if (userId === null) {
        return <Login />;
    }


    // Determinar qué componente mostrar basado en el tipo
    switch (tipo) {
        case "0":
            return <UserPanel />;
        case "24":
            return <AdminPanel />;
        default:
            return <Login />;
    }
}

export default UserPermiso;