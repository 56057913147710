import React, { useState } from 'react'
import { HStack, Text, Pressable, Modal, Button, VStack, Image } from 'native-base'
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai'
import { turquesa } from '../../helper/colors'
import { FaWhatsapp, FaRegCopy, FaRegShareSquare, FaEnvelope } from 'react-icons/fa'

const InicioDetalle = ({ titulo }) => {
  const [isFavorite, setIsFavorite] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  // Función para compartir en WhatsApp
  const compartirWhatsApp = () => {
    const urlActual = window.location.href
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(urlActual)}`
    window.open(whatsappUrl, '_blank')
  }

  // Función para copiar el enlace
  const copiarEnlace = () => {
    const urlActual = window.location.href
    navigator.clipboard.writeText(urlActual).then(
      () => {
        alert('Enlace copiado al portapapeles')
      },
      (err) => {
        console.error('Error al copiar el enlace: ', err)
      },
    )
  }

  // Función para compartir por email (simplificada para este ejemplo)
  const compartirEmail = () => {
    const urlActual = window.location.href
    const emailUrl = `mailto:?subject=Mira este enlace&body=Revisa este enlace: ${urlActual}`
    window.open(emailUrl, '_self')
  }

  const BotonCompartir = ({ icon, texto, funcion }) => {
    return (
      <Pressable
        w='90%'
        h={20}
        borderRadius={10}
        shadow={6}
        borderWidth={1}
        borderColor={'muted.300'}
        alignItems='center'
        justifyContent='center'
        onPress={funcion}>
        {' '}
        {/* Asegúrate de llamar a la función cuando se presiona el botón */}
        <HStack space={2} alignItems='center'>
          {' '}
          {/* Agrega espacio entre íconos y texto */}
          {icon} {/* Usa el ícono pasado como prop */}
          <Text fontFamily='Circular'>{texto}</Text>
        </HStack>
      </Pressable>
    )
  }

  const ModalCompartir = () => {
    return (
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}
        top={32}
        mt={0}
        justifyContent='flex-start'>
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>Comparte este espacio</Modal.Header>
          <Modal.Body>
            <HStack space={2}>
              <VStack>
                <Text fontFamily='Circular'>Tulum Penhouse</Text>
                <Image
                  source={{
                    uri: 'https://wallpaperaccess.com/full/317501.jpg',
                  }}
                  alt='Alternate Text'
                  size='sm'
                  alignSelf='center'
                />
              </VStack>

              <VStack>
                <Text>Dirección</Text>
                <HStack space={3}>
                  <Text>2 habitaciones</Text>
                  <Text>3 camas</Text>
                  <Text>2 baños</Text>
                </HStack>
              </VStack>
            </HStack>
            <HStack w='100%' space={2} mt={4}>
              <VStack w='50%' space={2}>
                <BotonCompartir icon={<FaRegCopy />} texto='Copiar enlace' funcion={copiarEnlace} />
                <BotonCompartir icon={<FaEnvelope />} texto='Email' funcion={compartirEmail} />
              </VStack>

              <VStack w='50%' space={2}>
                <BotonCompartir
                  icon={<FaWhatsapp />}
                  texto='WhatsApp'
                  funcion={compartirWhatsApp}
                />
              </VStack>
            </HStack>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group variant='ghost' space='2'>
              <Button>LEARN MORE</Button>
              <Button>Accept</Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    )
  }

  return (
    <HStack justifyContent='center' alignItems='center' mt={2}>
      <ModalCompartir />
      <Text fontFamily='Circular' fontSize={['2xl']}>
        {titulo}
      </Text>
      {/* <HStack space={6}>
                <Pressable onPress={() => setIsOpen(true)}>
                    <HStack space={2}>
                        <FaRegShareSquare />
                        <Text fontFamily="Avenir" underline>Compartir</Text>
                    </HStack>

                </Pressable>
                <Pressable onPress={() => setIsFavorite(!isFavorite)}>
                    <HStack space={2}>
                        {isFavorite ? (
                            <AiFillHeart color={turquesa} size="1em" />
                        ) : (
                            <AiOutlineHeart color={"#000"} bg="red" size="1em" />
                        )}
                        <Text fontFamily="Avenir" underline>Guardar</Text>
                    </HStack>

                </Pressable>
            </HStack> */}
    </HStack>
  )
}
export default InicioDetalle
