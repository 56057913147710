import React from 'react';
import { Box, Text } from 'native-base';

const UsersAdminComponent = ({ nombre, apellido, correo, telefono, id }) => {
    return (
        <Box w={64} borderWidth={1} borderColor="muted.400" p="4"
            rounded="lg" mb="4" shadow={6}>
            <Text fontWeight="bold">ID: <Text fontWeight="normal">{id}</Text></Text>
            <Text fontWeight="bold">Nombre: <Text fontWeight="normal">{`${nombre} ${apellido}`}</Text></Text>
            <Text fontWeight="bold">Correo: <Text fontWeight="normal">{correo}</Text></Text>
            <Text fontWeight="bold">Teléfono: <Text fontWeight="normal">{telefono}</Text></Text>
        </Box>
    );
};

export default UsersAdminComponent;
