import React from "react";
import { Box, Text, VStack, Divider, ScrollView } from "native-base";

const HistoryUserComponent = () => {
    // Datos simulados, reemplazar con datos reales
    const purchaseHistory = [
        { id: 1, date: "2022-01-01", total: "29.99", items: 3 },
        { id: 2, date: "2022-02-15", total: "89.97", items: 5 },
        // ... más datos
    ];

    return (
        <ScrollView w="100%">
            <VStack space={4} mt={24} px={5}>
                {purchaseHistory.map(purchase => (
                    <Box key={purchase.id} borderWidth="1" borderRadius="md" overflow="hidden">
                        <VStack space={2} p={4}>
                            <Text bold>Date: {purchase.date}</Text>
                            <Text>Number of items: {purchase.items}</Text>
                            <Text>Total: ${purchase.total}</Text>
                        </VStack>
                        <Divider />
                    </Box>
                ))}
            </VStack>
        </ScrollView>
    );
};

export default HistoryUserComponent;
