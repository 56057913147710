import React from 'react';
import { Text, View } from 'native-base';
import FlatListFavoritos from '../components/FlatListFavoritos';
const Favoritos = () => {
    return (
        <View >
            <Text alignSelf={"center"} fontSize={"3xl"} my={3}>
                Favoritos
            </Text>

            <FlatListFavoritos />
        </View>

    );
};

export default Favoritos;
