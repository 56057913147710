import { Stack, Image, Text, View, Divider, Pressable, ZStack, Box } from 'native-base'
import React from 'react'
import { turquesa } from '../helper/colors'
import { useNavigate } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll'

const PlayaCarmen = ({ titulo }) => {
  const navigate = useNavigate()
  const handleClick = (vista) => {
    scroll.scrollToTop()
    navigate(vista)
  }

  const ImagenDeFondo = {
    backgroundImage: 'url("https://mareaproperties.com.mx/imagenes/carmen01.jpg")', // Reemplaza 'ruta-de-tu-imagen.jpg' con la ubicación de tu imagen
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
  }

  const Locacion = ({ imagen, texto, rooms, baño, host, id }) => {
    return (
      <Pressable
        flex={1}
        onPress={() => handleClick(`/detalle/${id}`)}
        shadow={3}
        bg='#fff'
        pb={4}
        _hover={{
          shadow: 9,
          pb: 8,
          mt: -4,
        }}>
        <Stack>
          <Image
            source={{
              uri: imagen,
            }}
            alt='Marea Properties'
            h={[56, 64, 72, 72, 80]}
          />
          <Text textAlign='center' mt={2} style={{ fontFamily: 'Circular' }} fontSize='xl'>
            {texto}
          </Text>
          <Divider w='20%' alignSelf='center' bg={turquesa} thickness={2} my={1} />
          <Stack direction={'row'} w='100%' justifyContent='center' space={2}>
            <Text
              style={{ fontFamily: 'Avenir' }}
              color='muted.700'
              fontSize={['md', 'md', 'sm', 'sm', 'md']}>
              {rooms} Habitaciones
            </Text>
            <Divider
              orientation='vertical'
              h={4}
              alignSelf='center'
              thickness={1.5}
              bg='muted.600'
            />
            <Text
              style={{ fontFamily: 'Avenir' }}
              color='muted.700'
              fontSize={['md', 'md', 'sm', 'sm', 'md']}>
              {baño} Baños
            </Text>
            <Divider
              orientation='vertical'
              h={4}
              alignSelf='center'
              thickness={1.5}
              bg='muted.600'
            />
            <Text
              style={{ fontFamily: 'Avenir' }}
              color='muted.700'
              fontSize={['md', 'md', 'sm', 'sm', 'md']}>
              {host} Huespedes
            </Text>
          </Stack>
        </Stack>
      </Pressable>
    )
  }

  return (
    <View w='100%' flex={1} mb={10}>
      <View style={ImagenDeFondo} w='100%' h={64} mb={10}>
        <ZStack w='100%' h={64}>
          <Box w='100%' h={64} bg='muted.700' opacity={0.2} />
          <Text
            style={{ fontFamily: 'Circular', textShadow: '2px 2px 4px rgba(0, 0, 0, 1)' }}
            bold
            fontSize={['4xl', '6xl']}
            color='#fff'
            alignSelf='center'
            mt={20}
            letterSpacing={2}>
            PLAYA DEL CARMEN
          </Text>
        </ZStack>
      </View>

      <Stack
        mb={4}
        flex={1}
        direction={['column', 'column', 'column', 'row', 'row']}
        mx={'auto'}
        alignSelf='center'
        space={3}
        alignContent='center'
        justifyContent='center'>
        <Locacion
          id='3'
          imagen='https://mareaproperties.com.mx/imagenes/03portada.webp'
          texto='Depto. de lujo en Playa del Carmen'
          rooms='3'
          baño='2'
          host='6'
        />
      </Stack>
    </View>
  )
}

export default PlayaCarmen
