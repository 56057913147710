import React, { useEffect, useState } from 'react'
import { View, Stack, VStack, Box, Spinner } from 'native-base'
import { useParams } from 'react-router-dom'
import URL from '../helper/baseURL'
import DetalleEscritorio from '../components/detalleInmueble/DetalleEscritorio'
import FormReserva from '../components/detalleInmueble/FormReserva'
import AmenidadesEscritorio from '../components/detalleInmueble/AmenidadesEscritorio'
import MapaGoogle from '../components/detalleInmueble/MapaGoogle'
import FotosPortada from '../components/detalleInmueble/FotosPortada'
import InicioDetalle from '../components/detalleInmueble/InicioDetalle'
import getInmuebleData from '../components/detalleInmueble/InmuebleData'

const DetalleInmueble = () => {
  const { id } = useParams()
  const [inmueble, setInmueble] = useState()

  useEffect(() => {
    console.log('id: ', id)
    // Obtener datos del inmueble según el ID
    const data = getInmuebleData(id)
    setInmueble(data)
  }, [id])

  //  const { t, i18n } = useTranslation();
  //const idiomaActual = i18n.language;

  const BASE_URL = URL.BASE_URL

  // Estado para almacenar si el formulario debe tener posición fija
  const [isFixed, setIsFixed] = useState(false)

  // Función para actualizar el estado basado en el ancho de la ventana
  const updatePosition = () => {
    const breakpoints = {
      base: 0,
      sm: 480,
      md: 768,
      lg: 992,
      xl: 1280,
    }

    const windowWidth = window.innerWidth
    const shouldBeFixed = windowWidth >= breakpoints.lg // Cambiar según tus necesidades

    setIsFixed(shouldBeFixed)
  }

  useEffect(() => {
    // Actualizar la posición al montar
    updatePosition()

    // Añadir listener para cambios en el tamaño de la ventana
    window.addEventListener('resize', updatePosition)

    // Limpiar el listener al desmontar el componente
    return () => window.removeEventListener('resize', updatePosition)
  }, [])

  return (
    <>
      {inmueble ? (
        <View w='100%' mt={20} alignSelf='center'>
          <Box w='90%' alignSelf='center'>
            <InicioDetalle titulo={inmueble.titulo} />
          </Box>

          <Box w='90%' alignSelf='center' mt={2}>
            <FotosPortada portada={inmueble.fotoportada} galeria={inmueble.galeria} />
          </Box>

          <Stack
            direction={['column', 'column', 'column', 'row', 'row']}
            w='90%'
            alignSelf='center'
            space={10}>
            <VStack w={['100%', '100%', '100%', '60%', '60%']}>
              <View>
                <DetalleEscritorio titulo={inmueble.titulo} texto={inmueble.descripcion} />
              </View>

              <View>
                {Object.entries(inmueble.caracteristicas).map(([titulo, items]) => (
                  <AmenidadesEscritorio key={titulo} titulo={titulo} items={items} />
                ))}
              </View>

              <MapaGoogle linkMapa={inmueble.mapa} />
            </VStack>

            {/* Disponibilidad y Detalles de cuartos Escritorio */}

            <Box
              s
              w={['100%', '100%', '100%', '40%', '40%']}
              //  position={isFixed ? 'sticky' : 'static'}
            >
              <FormReserva
                id={id}
                titulo={inmueble.titulo}
                cuartos={inmueble.cuartos}
                baños={inmueble.baños}
                host={inmueble.host}
                precio={inmueble.precio}
              />
            </Box>
          </Stack>
        </View>
      ) : (
        <Spinner m={20} size={'lg'} />
      )}
    </>
  )
}

export default DetalleInmueble
