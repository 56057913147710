import { FlatList, Flex } from 'native-base'
import { useEffect, useState } from 'react'
import InmuebleComponent from './InmuebleComponent'

const FlatListInmuebles = () => {
  // Datos estáticos para cargar en el FlatList
  const datosEstaticos = [
    {
      ID: '1',
      fotoportada: 'hermoso-hotel.jpg',
      nombre: 'CALIPSO',
      ubicacion: 'Tulum',
      precio: '490.00',
      rooms: '2',
      bath: '1',
      guest: '2',
    },
    {
      ID: '2',
      fotoportada: 'casa-playa.jpg',
      nombre: 'FLOWER',
      ubicacion: 'Cancún',
      precio: '780.00',
      rooms: '2',
      bath: '1',
      guest: '2',
    },
    {
      ID: '3',
      fotoportada: '27.jpg',
      nombre: 'PARADISE',
      ubicacion: 'Isla Mujeres',
      precio: '900.00',
      rooms: '2',
      bath: '1',
      guest: '2',
    },
    {
      ID: '4',
      fotoportada: 'ilustracion.jpg',
      nombre: 'PARADISE',
      ubicacion: 'Isla Mujeres',
      precio: '1890.00',
      rooms: '5',
      bath: '2',
      guest: '8',
    },
  ]

  const [viajes, setViajes] = useState(datosEstaticos)

  // useEffect(() => {
  //     // Simulando la carga de datos como si vinieran de una petición
  //     setViajes(datosEstaticos);
  // }, [])

  // Código para determinar el número de columnas basado en el tamaño de la pantalla
  const breakpoints = {
    base: 0,
    sm: 480,
    md: 768,
    lg: 992,
    xl: 1280,
  }
  const numColumns =
    window.innerWidth < breakpoints.sm
      ? 1
      : window.innerWidth < breakpoints.md
        ? 1
        : window.innerWidth < breakpoints.lg
          ? 1
          : window.innerWidth < breakpoints.xl
            ? 2
            : 2 // Puedes ajustar los valores según tus necesidades

  return (
    <Flex w={['100%', '100%', '90%', '100%', '90%']} alignSelf='center'>
      <FlatList
        // style={{ width: '100%', marginTop: 5, paddingHorizontal: '2vw' }}
        contentContainerStyle={{ justifyContent: 'center', alignItems: 'center' }}
        data={viajes}
        numColumns={numColumns}
        keyExtractor={(item) => item.ID}
        key={numColumns.toString()} // Usa el número de columnas como clave
        renderItem={({ item }) => (
          <InmuebleComponent
            key={item.ID}
            imageUri={item.fotoportada}
            titulo={item.nombre}
            lugar={item.ubicacion}
            precio={item.precio}
            id={item.ID}
            rooms={item.rooms}
            bath={item.bath}
            guest={item.guest}
          />
        )}
      />
    </Flex>
  )
}

export default FlatListInmuebles
