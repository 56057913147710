
import React, { useState } from 'react';
import { Button, Icon, TextArea, View, Input, Heading, Image, FormControl, Select, useToast, Box, Spinner } from 'native-base';
import Loader from '../Loader';
import fetchPost from '../../helper/fetchPost';
import URL from '../../helper/baseURL';
import MapComponent from './MapComponent';



const CrudComponent = () => {
    const toast = useToast();
    const [googleMapLink, setGoogleMapLink] = useState("");

    const handleMapLinkChange = (newLink) => {
        setGoogleMapLink(newLink);
    };


    // Aquí iría la lógica para manejar los estados y la subida de imágenes
    const [isLoading, setIsLoading] = useState(false);
    const [titulo, setTitulo] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [servicios, setServicios] = useState('');
    const [ubicacion, setUbicacion] = useState('');
    const [precio, setPrecio] = useState('');
    const [categoria, setCategoria] = useState('');
    const [imagen, setImagen] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);




    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setImagen(file);

            // Crear una URL para la previsualización
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };


    const AgregarInmueble = async () => {
        if (!validarCampos()) {
            return;
        }

        setIsLoading(true);

        const data = new FormData();
        data.append("nombre", titulo);
        data.append("ubicacion", ubicacion);
        data.append("descripcion", descripcion);
        data.append("servicios", servicios);
        data.append("mapa", googleMapLink);
        data.append("precio", precio);
        data.append("categoria", categoria);
        if (imagen) {
            data.append("fotoportada", imagen);
        }

        // ... Agregar otras imágenes y campos si es necesario

        const url = `${URL.BASE_URL}/crearInmueble`; // Asegúrate de que la URL sea correcta
        const options = {
            method: 'POST',
            body: data
        };
        const res = await fetchPost(url, options);
        console.log("respuesta", res);
        if (res.resultado) {
            toast.show({
                duration: 10000,
                render: () => {
                    return <Box bg="emerald.500" px="3" py="5" rounded="sm" mb={5} fontSize={"lg"}>
                        Se agregó el inmueble con éxito.
                    </Box>;
                }
            });
        } else {
            toast.show({
                duration: 10000,
                render: () => {
                    return <Box bg="danger.500" px="3" py="5" rounded="sm" mb={5} fontSize={"lg"}>
                        Hubo un error, intentalo más tarde.
                    </Box>;
                }
            });
        }
        setIsLoading(false);
    };

    const validarCampos = () => {
        let camposFaltantes = [];
        if (!titulo) camposFaltantes.push("título");
        if (!descripcion) camposFaltantes.push("descripción");
        if (!ubicacion) camposFaltantes.push("ubicación");
        if (!precio) camposFaltantes.push("precio");
        if (!categoria) camposFaltantes.push("categoría");
        if (!imagen) camposFaltantes.push("imagen");

        if (camposFaltantes.length > 0) {
            let mensaje = "Por favor, completa los siguientes campos requeridos: " + camposFaltantes.join(", ") + ".";
            toast.show({
                title: "Campos Faltantes",
                description: mensaje,
                status: "error",
                duration: 4000,
                isClosable: true,
            });
            return false;
        }

        toast.show({
            title: "Éxito",
            description: "Todos los campos están completos.",
            status: "success",
            duration: 2000,
            isClosable: true,
        });
        return true;
    };





    return (

        <View w="90%" mt={[12, 12, 20, 24]} mx="auto" mb={5}>
            <Heading>
                CRUD de Inmuebles: {categoria}
            </Heading>

            <FormControl isRequired>
                <FormControl.Label>Titulo</FormControl.Label>
                <Input placeholder="Titulo del inmueble"
                    onChangeText={(val) => setTitulo(val)}
                    value={titulo} />
            </FormControl>

            <FormControl isRequired>
                <FormControl.Label>Descripción</FormControl.Label>
                <TextArea placeholder="Descripción del inmueble"
                    onChangeText={(val) => setDescripcion(val)}
                    value={descripcion} />
            </FormControl>

            <FormControl isRequired>
                <FormControl.Label>Servicios:</FormControl.Label>
                <TextArea placeholder="Descripción del inmueble"
                    onChangeText={(val) => setServicios(val)}
                    value={servicios} />
            </FormControl>

            <FormControl isRequired>
                <FormControl.Label>Ubicación</FormControl.Label>
                <TextArea placeholder="Ubicación del inmueble"
                    onChangeText={(val) => setUbicacion(val)}
                    value={ubicacion} />
            </FormControl>

            <FormControl isRequired>
                <FormControl.Label>Selecciona la ubicación en el mapa: </FormControl.Label>
                <div>
                    <MapComponent onMapLinkChange={handleMapLinkChange} />
                    <p>Enlace de Google Maps: {googleMapLink}</p>
                    {/* Otros componentes o contenido aquí */}
                </div>
            </FormControl>

            {/* Aquí iría el componente para integrar el mapa */}

            <FormControl>
                <FormControl.Label>Foto Portada</FormControl.Label>
                <input
                    type="file"
                    onChange={handleImageChange}
                    style={{ display: 'none' }}
                    id="file-input"
                    accept="image/*" // Asegúrate de aceptar solo imágenes
                />
                <label htmlFor="file-input">
                    <Button as="span" leftIcon={<Icon name="upload" />}>
                        Seleccionar Foto
                    </Button>
                </label>
                {previewUrl && (
                    <Image
                        source={{ uri: previewUrl }}
                        alt="Previsualización de la imagen"
                        size="2xl" // Puedes ajustar el tamaño según tus necesidades
                    />
                )}
            </FormControl>

            {/* Repetir para las demás fotos */}

            <FormControl isRequired>
                <FormControl.Label>Precio</FormControl.Label>
                <Input placeholder="Precio del inmueble" keyboardType="numeric"
                    onChangeText={(val) => setPrecio(val)}
                    value={precio} />
            </FormControl>

            <FormControl isRequired>
                <FormControl.Label>Categoría</FormControl.Label>
                <Select placeholder="Selecciona una categoría" onValueChange={itemValue => setCategoria(itemValue)}>
                    <Select.Item label="Frente a la playa" value="1" />
                    <Select.Item label="Habitaciones" value="2" />
                    <Select.Item label="Casas particulares" value="3" />
                    <Select.Item label="Albercas increibles" value="4" />
                    <Select.Item label="Adaptados" value="5" />
                </Select>
            </FormControl>


            {isLoading
                ?
                <Spinner size="lg" my={5} />
                :
                <Button mt="5" colorScheme="blue" onPress={() => AgregarInmueble()}>
                    Guardar Inmueble
                </Button>
            }

        </View>

    );
};

export default CrudComponent;
