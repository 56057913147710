import { Heading, View, FlatList, Text, Image, HStack, VStack, Flex, Button, Popover } from "native-base";
import React, { useState, useEffect } from "react";
import fetchPost from "../../helper/fetchPost";
import URL from "../../helper/baseURL";
import { IconContext } from "react-icons";
import { FaRegEdit } from "react-icons/fa";
import { MdOutlineDeleteForever } from "react-icons/md";
import AlertComponent from "../AlertComponent";
import { useNavigate } from "react-router-dom";



const VerInmueblesComponent = () => {
    const [viajes, setViajes] = useState([]);

    const BASE_URL = URL.BASE_URL;
    const navigate = useNavigate();
    const handleClick = (id, nombre) => {
        navigate(`/AdminEditarInmueble/${id}/${nombre}`);
    };

    const verViajes = async () => {
        const BASE_URL = URL.BASE_URL;


        const url = `${BASE_URL}inmueblesportada`
        const options = {
            method: 'POST',

        };
        const res = await fetchPost(url, options);
        console.log(res)
        setViajes(res);
        // 


    }

    useEffect(() => {
        verViajes()
    }, [])
    const [showAlert, setShowAlert] = useState(false);

    const InmuebleHorizontal = ({ id, imageUri, titulo, lugar, duracion, precio }) => {
        const [isOpen, setIsOpen] = useState(false);

        const handleOpen = () => setIsOpen(!isOpen);
        const handleClose = () => setIsOpen(false);
        const borrarInmueble = async (id) => {
            console.log("FUNCION BORRAR id:", id);
            const BASE_URL = URL.BASE_URL;
            const data = new FormData();
            data.append("id", id);

            const url = `${BASE_URL}eliminarInmueble`
            const options = {
                method: 'POST',
                body: data,

            };
            const res = await fetchPost(url, options);
            console.log("respuesta borrar: ", res);
            if (res.resultado) {
                verViajes();
                setShowAlert(true);

            }


        }

        return (
            <HStack flex={1} width={"100%"} m={2} shadow={6} space={2} borderRadius={10} borderColor={"#aaaaaa"} borderWidth={1}>

                <Image w={40} h={48} borderLeftRadius={10}
                    source={{
                        uri: `${BASE_URL}uploads/${imageUri}`
                    }} alt="detalle venta" />


                <Flex p={2}>
                    <VStack>
                        <Text bold fontSize={["md", "md", "lg", "xl"]}>
                            Título: <Text bold>{titulo}</Text>
                        </Text>
                        <Text bold fontSize={["md", "md", "lg", "xl"]}>
                            Ubicación: <Text bold>{lugar}</Text>
                        </Text>
                        <Text bold fontSize={["sm", "sm", "md", "lg"]}>
                            Precio: <Text bold>${precio}</Text>
                        </Text>
                    </VStack>



                    <VStack space={2} mt={2}>
                        <Button colorScheme="info" onPress={() => handleClick(id, titulo)}>
                            <HStack alignContent="center" justifyContent="center" space={2}>
                                <IconContext.Provider value={{ color: "#eeeeee", size: "1.2em" }}>
                                    <FaRegEdit />
                                </IconContext.Provider>
                                <Text color="#ffffff">Editar</Text>

                            </HStack>

                        </Button>

                        <Popover
                            isOpen={isOpen}
                            onClose={handleClose}
                            trigger={(triggerProps) => {
                                return (


                                    <Button colorScheme="warning" {...triggerProps} onPress={handleOpen}>
                                        <HStack alignContent="center" justifyContent="center" space={2}>
                                            <IconContext.Provider value={{ color: "#eeeeee", size: "1.3em" }}>
                                                <MdOutlineDeleteForever />
                                            </IconContext.Provider>
                                            <Text color="#ffffff">Borrar</Text>
                                        </HStack>
                                    </Button>
                                );
                            }}
                        >
                            <Popover.Content accessibilityLabel="Delete Confirmation" w="56">
                                <Popover.Arrow />
                                <Popover.CloseButton />
                                <Popover.Header>Confirmación</Popover.Header>
                                <Popover.Body >
                                    ¿Estás seguro de que quieres borrar {titulo}?



                                    <Button colorScheme="danger" onPress={() => borrarInmueble(id)}>Sí, borrar</Button>

                                </Popover.Body>
                            </Popover.Content>
                        </Popover>

                    </VStack>







                </Flex>
            </HStack>
        );
    }


    return (
        <View w="100%" mt={[12, 12, 20, 24]} >
            <Heading my={10}>
                Visualiza todos los imnuebles, edita o borra.
            </Heading>
            {showAlert
                ?
                <AlertComponent title="Se elimino el inmueble de forma exitosa." color />

                : null
            }
            <FlatList
                style={{ width: '100%', marginTop: 5, paddingHorizontal: '1vw' }}
                contentContainerStyle={{ justifyContent: 'center', alignItems: 'center' }}
                data={viajes}
                // Usa el número de columnas como clave
                renderItem={({ item }) => (
                    <InmuebleHorizontal
                        key={item.ID}
                        imageUri={item.fotoportada}
                        titulo={item.nombre}
                        lugar={item.ubicacion}
                        precio={item.precio}
                        id={item.ID}
                    />
                )}
            />



        </View>
    )
}

export default VerInmueblesComponent;