import React, { useState, useCallback } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';


const containerStyle = {
    width: '400px',
    height: '400px'
};

const initialPosition = {
    lat: 21.1213982, // Puedes cambiar estas coordenadas iniciales
    lng: -86.8564038
};

function MapComponent({ onMapLinkChange }) {


    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDr8uHCyUhZYtzQW6Gwy0_xrV9wZSRNeHU"
    });



    const [map, setMap] = useState(null);
    const [markerPosition, setMarkerPosition] = useState(initialPosition);

    const onLoad = useCallback(function callback(map) {
        setMap(map);
        map.setCenter(initialPosition);
        map.setZoom(10); // Aquí puedes ajustar el nivel de zoom inicial
    }, []);

    const onUnmount = useCallback(function callback(map) {
        setMap(null);
    }, []);

    const onMarkerDragEnd = (event) => {
        const newLat = event.latLng.lat();
        const newLng = event.latLng.lng();
        setMarkerPosition({ lat: newLat, lng: newLng });
        const newLink = `https://www.google.com/maps/?q=${newLat},${newLng}`;


        // Llamar a la función del componente padre
        onMapLinkChange(newLink);
    };

    return (
        <div>
            {isLoaded ? (
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={markerPosition}
                    zoom={10} // Y también aquí si quieres un zoom inicial diferente
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                >
                    <Marker
                        position={markerPosition}
                        draggable={true}
                        onDragEnd={onMarkerDragEnd}
                    />
                </GoogleMap>
            ) : <></>}

            <div>
                <input
                    type="text"
                    value={markerPosition.lat}
                    readOnly
                />
                <input
                    type="text"
                    value={markerPosition.lng}
                    readOnly
                />
            </div>

        </div>
    );
}

export default React.memo(MapComponent);
