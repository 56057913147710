import React from 'react';
import { Button, Popover, Icon } from 'native-base';
import { CiLogout } from "react-icons/ci";
import { IconContext } from 'react-icons';


const LogoutConfirmationPopover = ({ onConfirm, triggerProps }) => {
    return (
        <Popover
            trigger={triggerProps => {
                return (
                    <IconContext.Provider value={{ color: "white", size: "2em" }}>
                        <Button maxW={"80%"} minW={72} my={10} alignSelf="center" w={40} leftIcon={<Icon as={CiLogout} />} {...triggerProps} colorScheme="red">
                            Cerrar sesión
                        </Button>
                    </IconContext.Provider>
                );
            }}
        >
            <Popover.Content accessibilityLabel="Confirmar cierre de sesión" w="56">
                <Popover.Arrow />
                <Popover.CloseButton />
                <Popover.Header>Confirmación</Popover.Header>
                <Popover.Body>
                    ¿Estás seguro de que quieres cerrar sesión?
                    <Button mt={4} colorScheme="red" onPress={onConfirm}>
                        Confirmar
                    </Button>
                </Popover.Body>
            </Popover.Content>
        </Popover>
    );
};

export default LogoutConfirmationPopover;
