import React from 'react';
import { Modal, Button, VStack, Center, Heading } from 'native-base';
import { useNavigate } from 'react-router-dom';

function AuthModal({ isOpen, onClose }) {
    const navigate = useNavigate();

    const handleLogin = () => {
        navigate('/login');
        onClose();
    };

    const handleRegister = () => {
        navigate('/Registro');
        onClose();
    };

    return (
        <Center>
            <Modal isOpen={isOpen} onClose={onClose}>
                <Modal.Content maxWidth="400px">
                    <Modal.CloseButton />
                    <Modal.Header>Inicia sesión o regístrate</Modal.Header>
                    <Modal.Body>
                        <VStack space={4}>
                            <Heading size="md">
                                Inicia sesión o regístrate para continuar
                            </Heading>
                            <Button onPress={handleLogin} colorScheme="blue">
                                Iniciar Sesión
                            </Button>
                            <Button onPress={handleRegister} colorScheme="green">
                                Registrarse
                            </Button>
                        </VStack>
                    </Modal.Body>
                </Modal.Content>
            </Modal>
        </Center>
    );
}

export default AuthModal;
