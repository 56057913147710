import { Text, View } from "native-base"

const DetalleEscritorio = ({ texto, titulo }) => {

    return (
        <View w="100%" mt={10}>
            <Text style={{ fontFamily: 'Circular' }} fontSize="2xl">
                {titulo}
            </Text>
            <Text style={{ fontFamily: 'Avenir' }} textAlign="justify"
                color="#5f5f5f" fontSize="md" >
                {texto}
            </Text>

        </View>
    )
}
export default DetalleEscritorio;