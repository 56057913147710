const getInmuebleData = (id) => {
  switch (true) {
    case id === '1':
      return {
        id: '1',
        titulo: 'Depto. de lujo con desayuno frente al mar incluido',
        fotoportada: 'https://mareaproperties.com.mx/imagenes/01portada.webp',
        direccion: '',
        ubicacion: '',
        precio: 2300,
        descripcion:
          'Departamento en Tulum con decoración inspirada en brisas marinas y tonos suaves. A pasos de la playa, cuenta con 1 habitación, sofá cama, 2 baños y cocina totalmente equipada. Disfruta de la alberca y acceso al club de playa. Desayuno incluido para comenzar tu día con energía frente al mar.',
        description: '', // Para inglés
        servicios: '',
        mapa: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d59919.62569280243!2d-87.50534706973613!3d20.17669953434856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4fd40310e42a4b%3A0xeedc0db93ff9ab7!2sTulum%2C%20Q.R.!5e0!3m2!1ses-419!2smx!4v1712777378546!5m2!1ses-419!2smx',
        galeria: [
          'https://mareaproperties.com.mx/imagenes/01galeria01.webp',
          'https://mareaproperties.com.mx/imagenes/01galeria02.webp',
          'https://mareaproperties.com.mx/imagenes/01galeria03.webp',
          'https://mareaproperties.com.mx/imagenes/01galeria04.webp',
          'https://mareaproperties.com.mx/imagenes/01galeria05.webp',
          'https://mareaproperties.com.mx/imagenes/01galeria06.webp',
          'https://mareaproperties.com.mx/imagenes/01galeria07.webp',
          'https://mareaproperties.com.mx/imagenes/01galeria08.webp',
          'https://mareaproperties.com.mx/imagenes/01galeria09.webp',
          'https://mareaproperties.com.mx/imagenes/01galeria10.webp',
          'https://mareaproperties.com.mx/imagenes/01galeria11.webp',
          'https://mareaproperties.com.mx/imagenes/01galeria12.webp',
          'https://mareaproperties.com.mx/imagenes/01galeria13.webp',
          'https://mareaproperties.com.mx/imagenes/01galeria14.webp',
        ],
        cuartos: 1,
        baños: 2,
        host: 4,
        caracteristicas: {
          Amenidades: [
            'Acceso a la playa',
            'Wifi',
            'TV',
            'Cocina',
            'Área para trabajar',
            'Alberca',
            'Aire acondicionado',
            'Estacionamiento gratuito en las instalaciones',
          ],
        },
        InfoRoom: [],
      }
    case id === '2':
      return {
        id: '2',
        titulo: 'Depto. de lujo con desayuno frente al mar incluido',
        fotoportada: 'https://mareaproperties.com.mx/imagenes/02portada.webp',
        direccion: '',
        ubicacion: '',
        precio: 2300,
        descripcion:
          '¡Bienvenido a nuestro lujoso departamento en un exclusivo condominio! Sumérgete en la elegancia y comodidad, donde la calidez del fuego se une a la brisa marina. Disfruta de la serenidad en la alberca y mantente conectado con nuestro internet de alta velocidad. Con recámara espaciosa, cocina equipada y sala acogedora, ¡tu escape junto al mar te espera con el lujo y confort que mereces!',
        description: '', // Para inglés
        servicios: '',
        mapa: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d59919.62569280243!2d-87.50534706973613!3d20.17669953434856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4fd40310e42a4b%3A0xeedc0db93ff9ab7!2sTulum%2C%20Q.R.!5e0!3m2!1ses-419!2smx!4v1712777378546!5m2!1ses-419!2smx',
        galeria: [
          'https://mareaproperties.com.mx/imagenes/02galeria01.webp',
          'https://mareaproperties.com.mx/imagenes/02galeria02.webp',
          'https://mareaproperties.com.mx/imagenes/02galeria03.webp',
          'https://mareaproperties.com.mx/imagenes/02galeria04.webp',
          'https://mareaproperties.com.mx/imagenes/02galeria05.webp',
          'https://mareaproperties.com.mx/imagenes/02galeria06.webp',
          'https://mareaproperties.com.mx/imagenes/02galeria07.webp',
          'https://mareaproperties.com.mx/imagenes/02galeria08.webp',
          'https://mareaproperties.com.mx/imagenes/02galeria09.webp',
          'https://mareaproperties.com.mx/imagenes/02galeria10.webp',
        ],
        cuartos: 1,
        baños: 2,
        host: 4,
        caracteristicas: {
          Amenidades: [
            'Aire acondicionado',
            'Alberca',
            'Área para trabajar',
            'Cocina',
            'TV',
            'Wifi',
            'Acceso a la playa',
            'Estacionamiento gratuito en las instalaciones',
          ],
        },
        InfoRoom: [],
      }
    case id === '3':
      return {
        id: '3',
        titulo: 'Depto. de lujo en Playa de Carmen',
        fotoportada: 'https://mareaproperties.com.mx/imagenes/03portada.webp',
        direccion: '',
        ubicacion: '',
        precio: 1999,
        descripcion:
          'Este lujoso departamento en el hermoso paraiso de Playa del Carmen ofrece una experiencia de primera clase. Con sus 3 habitaciones, 2 baños, sala y comedor elegantes, brinda espacio y confort. Además, cuenta con una alberca reluciente, área de barbacoa y gimnasio para mantenerse activo. Totalmente equipado con comodidades modernas, este refugio de lujo promete unas vacaciones inolvidables en el paraíso',
        description: '', // Para inglés
        servicios: '',
        mapa: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d59733.107679677596!2d-87.08007125!3d20.656587599999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4e4323d22d4e61%3A0xe8c10b783bab4adc!2sPlaya%20del%20Carmen%2C%20Q.R.!5e0!3m2!1ses-419!2smx!4v1712938721620!5m2!1ses-419!2smx',
        galeria: [
          'https://mareaproperties.com.mx/imagenes/03portada.webp',
          'https://mareaproperties.com.mx/imagenes/03galeria01.webp',
          'https://mareaproperties.com.mx/imagenes/03galeria02.webp',
          'https://mareaproperties.com.mx/imagenes/03galeria03.webp',
          'https://mareaproperties.com.mx/imagenes/03galeria04.webp',
          'https://mareaproperties.com.mx/imagenes/03galeria05.webp',
          'https://mareaproperties.com.mx/imagenes/03galeria06.webp',
          'https://mareaproperties.com.mx/imagenes/03galeria07.webp',
          'https://mareaproperties.com.mx/imagenes/03galeria08.webp',
          'https://mareaproperties.com.mx/imagenes/03galeria09.webp',
          'https://mareaproperties.com.mx/imagenes/03galeria10.webp',
          'https://mareaproperties.com.mx/imagenes/03galeria11.webp',
          'https://mareaproperties.com.mx/imagenes/03galeria12.webp',
          'https://mareaproperties.com.mx/imagenes/03galeria13.webp',
          'https://mareaproperties.com.mx/imagenes/03galeria14.webp',
        ],
        cuartos: 3,
        baños: 2,
        host: 6,
        caracteristicas: {
          Amenidades: [
            'Acceso a la playa',
            'Wifi',
            'TV',
            'Cocina',
            'Área para trabajar',
            'Alberca',
            'Aire acondicionado',
            'Estacionamiento gratuito en las instalaciones',
          ],
        },
        InfoRoom: [],
      }
    case id === '4':
      return {
        id: '4',
        titulo: 'Depto. de lujo con desayuno frente al mar incluido',
        fotoportada: 'https://mareaproperties.com.mx/imagenes/04portada.webp',
        direccion: '',
        ubicacion: '',
        precio: 1600,
        descripcion:
          '¡Bienvenido a nuestro paraíso en Tulum! Ofrecemos cocina completa, dos camas matrimoniales y sofá cama, TV en las habitaciones y una vista impresionante a la selva. A 400 metros de la playa, incluimos desayuno en nuestro beach club, acceso gratuito, aire acondicionado y opción de bicicletas. ¡Descubre la magia de Tulum con nosotros!',
        description: '', // Para inglés
        servicios: '',
        mapa: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d59919.62569280243!2d-87.50534706973613!3d20.17669953434856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4fd40310e42a4b%3A0xeedc0db93ff9ab7!2sTulum%2C%20Q.R.!5e0!3m2!1ses-419!2smx!4v1712777378546!5m2!1ses-419!2smx',
        galeria: [
          'https://mareaproperties.com.mx/imagenes/04portada.webp',
          'https://mareaproperties.com.mx/imagenes/04galeria01.webp',
          'https://mareaproperties.com.mx/imagenes/04galeria02.webp',
          'https://mareaproperties.com.mx/imagenes/04galeria03.webp',
          'https://mareaproperties.com.mx/imagenes/04galeria04.webp',
          'https://mareaproperties.com.mx/imagenes/04galeria05.webp',
          'https://mareaproperties.com.mx/imagenes/04galeria06.webp',
          'https://mareaproperties.com.mx/imagenes/04galeria07.webp',
          'https://mareaproperties.com.mx/imagenes/04galeria08.webp',
          'https://mareaproperties.com.mx/imagenes/04galeria09.webp',
          'https://mareaproperties.com.mx/imagenes/04galeria10.webp',
          'https://mareaproperties.com.mx/imagenes/04galeria11.webp',
          'https://mareaproperties.com.mx/imagenes/04galeria12.webp',
        ],
        cuartos: 2,
        baños: 2,
        host: 6,
        caracteristicas: {
          Amenidades: [
            'Acceso a la playa',
            'Terraza o balcón',
            'Wifi',
            'TV',
            'Cocina',
            'Área para trabajar',
            'Alberca',
            'Aire acondicionado',
            'Estacionamiento gratuito en las instalaciones',
          ],
        },
        InfoRoom: [],
      }
    default:
      return null
  }
}

export default getInmuebleData
