import { useToast } from 'native-base';
import { Box } from 'native-base';

const useCustomToast = () => {
    const toast = useToast();

    const showToast = ({ duration, text, color, position }) => {
        toast.show({
            duration,
            render: () => {
                return (
                    <Box bg={color} p={4} rounded="sm" mb={5} shadow={6}
                        borderWidth={2} borderColor="white" fontWeight="bold">
                        {text}
                    </Box>
                );
            },
            placement: position,
        });
    };

    return showToast;
};

export default useCustomToast;