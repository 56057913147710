import React from 'react'
import { View, Stack, VStack, Box, Text } from 'native-base'

import ContactoSimple from '../../components/ContactoSimple'

const Cancelaciones = () => {
  const ImagenDeFondo = {
    backgroundImage: 'url("https://mareaproperties.com.mx/imagenes/playa99.jpg")', // Reemplaza 'ruta-de-tu-imagen.jpg' con la ubicación de tu imagen
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
  }

  return (
    <View w='100%' bg='#dadada'>
      <View style={ImagenDeFondo} w='100%' h={96}>
        <Text
          style={{ fontFamily: 'Circular', textShadow: '2px 2px 4px rgba(0, 0, 0, 1)' }}
          bold
          fontSize={{ base: '5xl', lg: '6xl' }}
          mx={10}
          color='#fff'
          alignSelf='center'
          mt={{ base: 16, lg: 40 }}
          letterSpacing={2}
          textAlign='center'>
          CANCELACIONES Y REEMBOLSOS
        </Text>
      </View>

      <Stack
        direction={['column', 'column', 'column', 'row', 'row']}
        w='90%'
        alignSelf='center'
        space={10}>
        <VStack
          w={['100%', '100%', '100%', '60%', '60%']}
          space={4}
          bg='#fff'
          shadow={6}
          my={10}
          py={6}
          px={12}
          flex={1}>
          <Text style={{ fontFamily: 'Circular' }} fontSize='lg'>
            La Política de Reembolso para Huéspedes se aplica además de los Términos y Condiciones
            de Marea Properties
          </Text>
          <Text style={{ fontFamily: 'Avenir' }} fontSize='lg'>
            Las reservas canceladas al menos 30 días antes del inicio de la estancia recibirán un
            reembolso del 100%.
            {'\n'} {'\n'}
            Las reservas canceladas al menos 14 días antes del inicio de la estancia recibirán un
            reembolso del 50%.
          </Text>
        </VStack>

        {/* Disponibilidad y Detalles de cuartos Escritorio */}

        <Box w={['100%', '100%', '100%', '40%', '40%']} position='sticky' top={24}>
          <ContactoSimple />
        </Box>
      </Stack>
    </View>
  )
}

export default Cancelaciones
