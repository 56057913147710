import React, { useState, useRef } from 'react'
import { Menu, Pressable, HStack, Text, Center } from 'native-base'
import { FaCaretDown } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll'

const NosotrosMenu = () => {
  const [isOpen, setIsOpen] = useState(false)
  const closeTimer = useRef(null)
  const [isHovered1, setIsHovered1] = useState(false)
  const navigate = useNavigate()
  const handleClickMenu = (vista) => {
    scroll.scrollToTop()
    navigate(vista)
  }

  const handleMouseEnter = () => {
    if (isOpen === true) {
      setIsHovered1(false)
      setIsOpen(false)
    } else {
      setIsHovered1(true)
      setIsOpen(true)
    }
  }

  return (
    <Menu
      zIndex={99999}
      shadow={6}
      top={[0, 0, 0, 0, 0]}
      right={20}
      display={{ base: 'none', lg: 'flex' }}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      trigger={(triggerProps) => (
        <Pressable
          accessibilityLabel='More options menu'
          {...triggerProps}
          //   onMouseEnter={handleMouseEnter}
          //   onMouseLeave={handleMouseLeave}
          onPress={() => handleClickMenu('/Nosotros')}
          alignSelf='center'
          display={{ base: 'none', lg: 'flex' }}
          p={0.5}
          justifyContent='center'
          flex={1}>
          <HStack justifyContent='center'>
            <Text
              color={isHovered1 ? '#75eeff' : '#fff'}
              fontSize={['2xs', '2xs', '2xs', 'xs', 'sm']}
              textAlign='center'
              py={2}
              style={{ fontFamily: 'Circular' }}>
              NOSOTROS
            </Text>
            <Center>
              <Pressable p={2} onPress={() => handleMouseEnter()}>
                <FaCaretDown color={isHovered1 ? '#75eeff' : '#fff'} />
              </Pressable>
            </Center>
          </HStack>
        </Pressable>
      )}>
      <Menu.Item onPress={() => handleClickMenu('/Unete')}>
        <Text style={{ fontFamily: 'Circular' }} fontSize={['2xs', '2xs', '2xs', 'xs', 'sm']}>
          ÚNETE A NUESTRO EQUIPO
        </Text>
      </Menu.Item>
      <Menu.Item onPress={() => handleClickMenu('/Faqs')}>
        <Text style={{ fontFamily: 'Circular' }} fontSize={['2xs', '2xs', '2xs', 'xs', 'sm']}>
          PREGUNTAS FRECUENTES
        </Text>
      </Menu.Item>
      <Menu.Item onPress={() => handleClickMenu('/Cancelaciones')}>
        <Text fontSize={['2xs', '2xs', '2xs', 'xs', 'sm']} style={{ fontFamily: 'Circular' }}>
          CANCELACIONES Y REEMBOLSOS
        </Text>
      </Menu.Item>
    </Menu>
  )
}
export default NosotrosMenu
