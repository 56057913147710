import React, { useState } from 'react'
import { View, Stack, VStack, Box, Text, Center, Pressable, HStack, Button } from 'native-base'
import { FaCirclePlus, FaCircleMinus } from 'react-icons/fa6'
import ContactoSimple from '../../components/ContactoSimple'
import { azulclaro, turquesa } from '../../helper/colors'
import { useNavigate } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll'

const Faqs = () => {
  const navigate = useNavigate()
  const handleClickMenu = (vista) => {
    scroll.scrollToTop()
    navigate(vista)
  }

  const ImagenDeFondo = {
    backgroundImage: 'url("https://mareaproperties.com.mx/imagenes/cancun01.jpg")', // Reemplaza 'ruta-de-tu-imagen.jpg' con la ubicación de tu imagen
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
  }

  const ImagenDeFondo2 = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("https://mareaproperties.com.mx/imagenes/img67.jpg")`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  }

  const Faq = ({ titulo, texto }) => {
    const [isOpen, setIsOpen] = useState(false)

    return (
      <Center>
        <Pressable
          w={'100%'}
          onPress={() => setIsOpen(!isOpen)}
          borderTopRadius={10}
          borderWidth={1}
          borderColor={'muted.400'}
          bg={isOpen ? azulclaro : turquesa}
          pl={3}
          h='70px'>
          <HStack alignItems='center' justifyContent='space-between' w='100%'>
            <Text style={{ fontFamily: 'Circular' }} fontSize='md' color='#fff' w='80%'>
              {titulo}
            </Text>
            <Center
              alignSelf='flex-end'
              bg='muted.400:alpha.50'
              h='70px'
              borderTopRightRadius={10}
              w='70px'>
              {!isOpen ? (
                <FaCirclePlus color='#fff' size='2em' />
              ) : (
                <FaCircleMinus color='#fff' size='2em' />
              )}
            </Center>
          </HStack>
        </Pressable>
        {isOpen && (
          <Text
            p={4}
            borderBottomRadius={10}
            borderWidth={1}
            borderColor='muted.400'
            shadow={4}
            bg={'#fff'}
            borderButtomRadius={10}
            textAlign={'justify'}
            fontSize={['sm', 'sm', 'md', 'md']}
            alignSelf={'center'}
            w={'100%'}>
            {texto}
          </Text>
        )}
      </Center>
    )
  }

  const Titulo = ({ texto }) => {
    return (
      <Text style={{ fontFamily: 'Circular' }} fontSize='lg'>
        {texto}
      </Text>
    )
  }

  return (
    <View w='100%' bg='#dadada'>
      <View style={ImagenDeFondo} w='100%' h={96}>
        <Text
          style={{ fontFamily: 'Circular', textShadow: '2px 2px 4px rgba(0, 0, 0, 1)' }}
          bold
          fontSize={['6xl']}
          mx={10}
          color='#fff'
          alignSelf='center'
          mt={{ base: 16, lg: 40 }}
          letterSpacing={2}
          textAlign='center'>
          PREGUNTAS FRECUENTES
        </Text>
      </View>

      <Stack
        direction={['column', 'column', 'column', 'row', 'row']}
        w='90%'
        alignSelf='center'
        space={5}>
        <VStack w={['100%', '100%', '100%', '60%', '60%']} space={4} my={10} flex={1}>
          <Titulo texto='Mi Cuenta' />
          <Faq
            titulo='¿Está incluida la tarifa de limpieza en el precio total o es adicional?'
            texto='A su llegada, la propiedad estará perfectamente limpia. La tarifa de limpieza se cobra adicionalmente a la reserva, y contamos con servicios adicionales para hacer de su estancia una experiencia única.'
          />

          <Titulo texto='Hospedaje con Nosotros' />
          <Faq
            titulo='¿Qué encontraré en la unidad?'
            texto='Cada propiedad cuenta con ropa de cama (sábanas y toallas). Suministros de higiene como papel higiénico, toallas de papel, jabón para platos y esponja, jabón corporal y champú.'
          />
          <Faq
            titulo='¿Cuál es su política de cancelación?'
            texto='Sabemos que debido a la situación global actual hay cambios repentinos, por eso hemos modificado nuestras políticas de cancelación para que pueda planificar su viaje con total confianza. Consulte nuestra sección de políticas de cancelación.'
          />
          <Faq
            titulo='¿Tienen política de mascotas?'
            texto='Muchas de nuestras propiedades son amigables con las mascotas, asegúrese de verificar que la unidad que busca reservar tenga la amenidad para mascotas activada.'
          />
          <Faq
            titulo='¿Qué edad debo tener para alquilar una propiedad?'
            texto='Casi en todas partes puede alquilar una propiedad a partir de los 18 años. En la descripción de la propiedad, puede ver si la propiedad tiene requisitos de edad adicionales.'
          />
          <Faq
            titulo='Tengo un bebé, ¿cuenta en el número total de ocupantes?'
            texto='Los bebés menores de dos años no se consideran como huéspedes adicionales, hay algunas propiedades que restringen bebés, puede encontrar esta información en la descripción del lugar cuando sea el caso.'
          />
          <Faq
            titulo='¿Mi estancia tiene algún seguro de viaje?'
            texto='En caso de que tuviera que ser cancelada por enfermedad, fallecimiento de un familiar, retrasos, cancelaciones de vuelos o cierres de carreteras, consulte nuestras políticas de cancelación y estaremos encantados de ayudarle para que pueda visitarnos en otra fecha.'
          />

          <Titulo texto='Reservas' />
          <Faq
            titulo='¿Cómo cambio huéspedes o mascotas en mi reserva?'
            texto='Se pueden hacer ajustes a la reserva, se puede hacer directamente en los detalles de la reserva, pero también nuestro equipo de atención al cliente siempre estará disponible para ayudarlo.'
          />

          <Titulo texto='Check-In' />
          <Faq
            titulo='¿El check-in es sin contacto?'
            texto='Su seguridad y nuestro equipo son nuestra prioridad. Todas nuestras propiedades cuentan con cerraduras que facilitan su llegada. Todas nuestras unidades tienen un sistema de auto check-in habilitado.'
          />

          <Titulo texto='Propietarios' />
          <Faq
            titulo='Tengo una propiedad que quiero alquilar con Marea Properties, ¿qué debo hacer?'
            texto='Póngase en contacto con nosotros para poder asignar a una persona a cargo del área. Estaremos muy contentos de asistirle.'
          />
        </VStack>

        {/* Disponibilidad y Detalles de cuartos Escritorio */}

        <VStack w={['100%', '100%', '100%', '35%', '35%']} position='sticky' top={24}>
          <VStack
            px={10}
            style={ImagenDeFondo2}
            h={[64, 64, 80, 96, 96]}
            mt={5}
            space={10}
            alignItems='center'
            justifyContent='center'>
            <Text
              color='#fff'
              fontFamily='Circular'
              fontSize={'xl'}
              alignSelf='center'
              textAlign='center'>
              Inicia Tus Vacaciones Ahora
            </Text>
            <Button onPress={() => handleClickMenu('/Destinos')}>
              <Text fontFamily={'Circular'} color='#fff' fontSize={'xl'}>
                EXPLORA RENTAS
              </Text>
            </Button>
          </VStack>
          <Box flex={1}>
            <ContactoSimple />
          </Box>
        </VStack>
      </Stack>
    </View>
  )
}

export default Faqs
