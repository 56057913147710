import React, { useEffect } from "react";
import { Text, View, Stack, VStack, Pressable, Heading } from "native-base";
import { useUser } from "../../helper/UserContext";
import { useNavigate } from "react-router";
import { PiUserList, PiShoppingCartBold } from "react-icons/pi";
import { MdOutlineWorkHistory } from "react-icons/md";
import { IconContext } from "react-icons";
import { LiaUsersSolid } from "react-icons/lia";
import { BsShop, BsHouseHeart } from "react-icons/bs";
import { LuHotel } from "react-icons/lu";
import { TbHomeEdit } from "react-icons/tb";



import LogoutConfirmationPopover from "../../components/LogoutConfirmationPopover";
const AdminPanel = () => {
    const { logout, nombre, apellido, correo, userId } = useUser();

    const navigate = useNavigate();

    const handleSalir = () => {
        // Lógica para el inicio de sesión
        logout();
        navigate("/");
    };

    useEffect(() => {
        console.log("id user admin: ", userId)
    }, [])



    const OpcionUser = ({ funcion, icon: IconComponent, titulo, texto }) => {

        return (
            <Pressable onPress={funcion} maxW={"80%"} minW={72}>
                <VStack py={3} px={6} shadow={6} borderRadius={20} borderColor="muted.300" borderWidth={2}>

                    <IconContext.Provider value={{ size: "3em" }}>
                        <IconComponent />
                    </IconContext.Provider>

                    <Text bold>{titulo}</Text>
                    <Text>{texto}</Text>
                </VStack>
            </Pressable>

        );

    }

    const UserNav = (nav) => {
        navigate(nav);
    }

    return (
        <View mt={[12, 12, 20, 24]}>
            <Heading bold fontSize={"2xl"} textAlign="center" mt={3}>
                BIENVENIDO
            </Heading>
            <Stack ml={12} mb={2}>
                <Text bold fontSize={"2xl"} textAlign="justify" px={10}>
                    Cuenta
                </Text>

                <Text bold fontSize={"lg"} px={10}>
                    {nombre} {apellido}.
                </Text>
                <Text fontWeight={"semibold"} fontSize={"md"} px={10}>
                    {correo}
                </Text>

            </Stack>



            <Stack alignSelf="center" direction={["column", "column", "row", "row"]} space={4} px={4}>
                <OpcionUser
                    titulo="Información personal"
                    texto="Visualiza y edita tus datos personales"
                    funcion={() => UserNav("/UserInfo")}
                    icon={PiUserList} />
                <OpcionUser
                    titulo="Carrito de compras"
                    texto="Continua con tu compra"
                    funcion={() => UserNav("/Carrito")}
                    icon={PiShoppingCartBold} />

                <OpcionUser
                    titulo="Favoritos"
                    texto="Visualiza tus favoritos"
                    funcion={() => UserNav("/Favoritos")}
                    icon={BsHouseHeart} />
                <OpcionUser
                    titulo="Historial de compras"
                    texto="Revisa el historial de tus compras"
                    funcion={() => UserNav("/UserHistory")}
                    icon={MdOutlineWorkHistory} />





            </Stack>

            <Stack alignSelf="center" direction={["column", "column", "row", "row"]} space={4} mt={3} px={4}>
                <OpcionUser
                    titulo="Ver Usuarios registrados"
                    texto="Lista de todos los usuarios registrados"
                    funcion={() => UserNav("/VerUsuarios")}
                    icon={LiaUsersSolid} />

                <OpcionUser
                    titulo="Ver Ventas realizadas"
                    texto="Revisa las reservaciones de tus usuarios"
                    funcion={() => UserNav("/VerVentas")}
                    icon={BsShop} />

                <OpcionUser
                    titulo="Agregar Inmueble"
                    texto="Agrega nuevo inmueble"
                    funcion={() => UserNav("/AdminAgregarInmueble")}
                    icon={LuHotel} />

                <OpcionUser
                    titulo="Ver Inmuebles registrados"
                    texto="Revisa y edita inmuebles"
                    funcion={() => UserNav("/AdminVerInmuebles")}
                    icon={TbHomeEdit} />






            </Stack>
            <LogoutConfirmationPopover onConfirm={handleSalir} />


        </View>
    );

}
export default AdminPanel;