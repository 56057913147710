import React from 'react';
import { Spinner, Center } from 'native-base';

const Loader = ({ size, color }) => {
    return (
        <Center flex={1}>
            <Spinner size={size} color={color} />
        </Center>
    );
};

export default Loader;
