import React from 'react'
import Slider from 'react-slick'
import { Image, Box, View, Center } from 'native-base'
import { FcPrevious, FcNext } from 'react-icons/fc'

const OficinasSwiper = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{ ...style, display: 'block' }} // Personaliza este estilo si es necesario
        onClick={onClick}>
        <Box bg='#dadada' mt={-8}>
          <FcNext size={'2rem'} />
        </Box>
      </div>
    )
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{ ...style, display: 'block' }} // Personaliza este estilo si es necesario
        onClick={onClick}>
        <Center bg='#dadada' mt={-8}>
          <FcPrevious size={'2rem'} /> {/* Puedes cambiar el tamaño aquí */}
        </Center>
      </div>
    )
  }

  return (
    <View w='100%' alignSelf='center' mt={5} pb={20}>
      <Box w='100%' alignSelf='center' shadow={8} h={96} bg='#fff'>
        <Slider {...settings}>
          <Image
            source={{
              uri: 'https://mareaproperties.com.mx/imagenes/oficina01.jpg',
            }}
            alt='Alternate Text'
            w={'100%'}
            h={96}
          />

          <Image
            source={{
              uri: 'https://mareaproperties.com.mx/imagenes/oficina02.jpg',
            }}
            alt='Alternate Text'
            w={'100%'}
            h={96}
          />

          <Image
            source={{
              uri: 'https://mareaproperties.com.mx/imagenes/oficina03.jpg',
            }}
            alt='Alternate Text'
            w={'100%'}
            h={96}
          />
          <Image
            source={{
              uri: 'https://mareaproperties.com.mx/imagenes/oficina04.jpg',
            }}
            alt='Alternate Text'
            w={'100%'}
            h={96}
          />
          <Image
            source={{
              uri: 'https://mareaproperties.com.mx/imagenes/oficina05.jpg',
            }}
            alt='Alternate Text'
            w={'100%'}
            h={96}
          />
          <Image
            source={{
              uri: 'https://mareaproperties.com.mx/imagenes/oficina06.jpg',
            }}
            alt='Alternate Text'
            w={'100%'}
            h={96}
          />
          <Image
            source={{
              uri: 'https://mareaproperties.com.mx/imagenes/oficina07.jpg',
            }}
            alt='Alternate Text'
            w={'100%'}
            h={96}
          />
        </Slider>
      </Box>
    </View>
  )
}

export default OficinasSwiper
