import React from 'react'
import { HStack, View, Text, Stack, Box } from 'native-base'
import { FaLocationDot } from 'react-icons/fa6'
import MapaGoogle from './detalleInmueble/MapaGoogle'
import OficinasSwiper from './Swipers/OficinasSwiper'

const OficinasComponent = () => {
  return (
    <View my={3}>
      <Text textAlign={'center'} bold fontSize={'4xl'}>
        CONOCE NUESTRAS OFICINAS
      </Text>
      <HStack
        w='80%'
        space={3}
        my={3}
        alignSelf={'center'}
        alignContent={'center'}
        alignItems={'center'}>
        <Text fontSize={'lg'} w='100%' textAlign={'center'} alignSelf={'center'}>
          <FaLocationDot size={20} /> CENOTE TERCER CIELO C.P 77766, AV. COSTERA BOCA PAILA,
          Supermanzana KM 7.6, Tulum, Q.R.
        </Text>
      </HStack>
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        w={'90%'}
        alignSelf={'center'}
        space={2}
        justifyContent={'center'}
        alignItems={'center'}>
        <Box w={{ base: '90%', lg: '40%' }} mt={10}>
          <MapaGoogle linkMapa='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3745.500563374525!2d-87.45755799999999!3d20.1548734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4fd100513e2811%3A0xd1f2a17207cb0ff2!2sMarea%20Properties!5e0!3m2!1ses-419!2smx!4v1714498090361!5m2!1ses-419!2smx' />
        </Box>

        <Box w={{ base: '90%', lg: '40%' }} mt={10} ml={10}>
          <OficinasSwiper />
        </Box>
      </Stack>
    </View>
  )
}
export default OficinasComponent
