import React from 'react'
import Slider from 'react-slick'
import { VStack, Stack, Image, Text, Box, View, Center } from 'native-base'
import { FcPrevious, FcNext } from 'react-icons/fc'
import { turquesa } from '../helper/colors'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'

const TestimonioComponent = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }

  const Testimonio = ({ imagenUrl, titulo, texto, fecha }) => {
    return (
      <Box alignSelf='center'>
        <Stack direction={['column', 'column', 'row', 'row', 'row']} space={3} alignItems='center'>
          {/* Imagen grande del testimonio */}
          <Image
            source={{ uri: imagenUrl }}
            alt='Imagen Grande'
            h={[56, 72, 72, 80, 80]}
            w={['100%', '100%', '50%', '50%', '50%']}
          />
          {/* VStack para el contenido del testimonio */}
          <VStack flex={1} space={4} h={80}>
            {/* Foto pequeña alineada a la derecha */}
            <Image
              source={{ uri: 'https://mareaproperties.com.mx/imagenes/rating.png' }}
              alt='Foto Pequeña'
              size='sm'
              alignSelf='flex-end'
              resizeMode='cover'
            />
            {/* Título */}
            <Text
              fontSize={['lg', 'xl', '2xl', '2xl']}
              color='coolGray.800'
              pl={5}
              mt={-10}
              style={{ fontFamily: 'Circular' }}>
              {titulo}
            </Text>
            {/* Texto */}
            <Text
              fontSize={['md', 'lg', 'lg', 'xl', 'xl']}
              color='muted.700'
              px={4}
              style={{ fontFamily: 'Avenir' }}>
              {texto}
            </Text>
            {/* Fecha */}
            <Text
              fontSize={['sm', 'md', 'lg', 'xl', 'xl']}
              style={{ fontFamily: 'Avenir' }}
              mb={5}
              color='#000'
              alignSelf='flex-end'
              pr={12}>
              {fecha}
            </Text>
          </VStack>
        </Stack>
      </Box>
    )
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{ ...style, display: 'block' }} // Personaliza este estilo si es necesario
        onClick={onClick}>
        <Box bg={turquesa} mt={-8}>
          <IoIosArrowForward size={'2rem'} color='#fff' />
        </Box>
      </div>
    )
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{ ...style, display: 'block' }} // Personaliza este estilo si es necesario
        onClick={onClick}>
        <Center bg={turquesa} mt={-8} mr={5} pr={5}>
          <IoIosArrowBack size={'2rem'} color='#fff' /> {/* Puedes cambiar el tamaño aquí */}
        </Center>
      </div>
    )
  }

  return (
    <View w='100%' alignSelf='center' py={8} bg={turquesa}>
      <Text
        fontSize={['2xl', '2xl', '2xl', '4xl', '4xl']}
        fontWeight='bold'
        textAlign='center'
        mb={4}
        color='coolGray.200'
        style={{ fontFamily: 'Circular' }}>
        TESTIMONIOS DE INVITADOS
      </Text>

      <Box w={['69%', '75%', '80%', '85%', '90%']} alignSelf='center' bg='#fff' shadow={9}>
        <Slider {...settings}>
          <Testimonio
            imagenUrl='https://mareaproperties.com.mx/imagenes/pasarela-cielo.jpg'
            titulo='Ana S.'
            texto='Increíble experiencia con las rentas vacacionales. ¡Servicio de primera y lugares de ensueño!'
            fecha='19 de diciembre de 2023'
          />
          <Testimonio
            imagenUrl='https://mareaproperties.com.mx/imagenes/playa-clearwater.jpg'
            titulo='Carlos M.'
            texto='La mejor selección de casas vacacionales. ¡Cada viaje se convierte en una aventura inolvidable!'
            fecha='5 de enero de 2024'
          />
          <Testimonio
            imagenUrl='https://mareaproperties.com.mx/imagenes/vista-panoramica.jpg'
            titulo='Luisa P.'
            texto='Servicio excepcional y alojamientos de lujo. ¡Mis vacaciones nunca habían sido tan perfectas!'
            fecha='12 de enero de 2024'
          />
        </Slider>
      </Box>
    </View>
  )
}

export default TestimonioComponent
