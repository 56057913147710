import { Stack, Image, Text, View, Divider, Pressable } from 'native-base'
import React from 'react'
import { turquesa } from '../helper/colors'
import { useNavigate } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll'

const PropiedadesDestacadas = ({ titulo }) => {
  const navigate = useNavigate()
  const handleClick = (vista) => {
    scroll.scrollToTop()
    navigate(vista)
  }
  const handlePress = () => {
    window.open('https://app.hoone.io/booking/marea-properties', '_blank')
  }

  const Locacion = ({ imagen, texto, rooms, baño, host, id }) => {
    return (
      <Pressable
        flex={1}
        onPress={() => handlePress()}
        shadow={3}
        bg='#fff'
        pb={4}
        _hover={{
          shadow: 9,
          pb: 8,
          mt: -4,
        }}>
        <Stack>
          <Image
            source={{
              uri: imagen,
            }}
            alt='Marea Properties'
            h={[56, 64, 72, 72, 80]}
          />
          <Text textAlign='center' mt={2} style={{ fontFamily: 'Circular' }} fontSize='xl'>
            {texto}
          </Text>
          <Divider w='20%' alignSelf='center' bg={turquesa} thickness={2} my={1} />
          <Stack direction={'row'} w='100%' justifyContent='center' space={2}>
            <Text
              style={{ fontFamily: 'Avenir' }}
              color='muted.700'
              fontSize={['md', 'md', 'sm', 'sm', 'md']}>
              {rooms} Habitaciones
            </Text>
            <Divider
              orientation='vertical'
              h={4}
              alignSelf='center'
              thickness={1.5}
              bg='muted.600'
            />
            <Text
              style={{ fontFamily: 'Avenir' }}
              color='muted.700'
              fontSize={['md', 'md', 'sm', 'sm', 'md']}>
              {baño} Baños
            </Text>
            <Divider
              orientation='vertical'
              h={4}
              alignSelf='center'
              thickness={1.5}
              bg='muted.600'
            />
            <Text
              style={{ fontFamily: 'Avenir' }}
              color='muted.700'
              fontSize={['md', 'md', 'sm', 'sm', 'md']}>
              {host} Huespedes
            </Text>
          </Stack>
        </Stack>
      </Pressable>
    )
  }

  return (
    <View w='100%' flex={1} mb={10}>
      <Text
        fontSize={['2xl', '2xl', '2xl', '4xl', '4xl']}
        fontWeight='bold'
        textAlign='center'
        my={6}
        color='coolGray.800'
        style={{ fontFamily: 'Circular' }}>
        {titulo}
      </Text>

      <Stack
        mb={4}
        w={{ base: '75%', lg: '95%' }}
        flex={1}
        direction={['column', 'column', 'column', 'row', 'row']}
        mx={'auto'}
        alignSelf='center'
        space={3}
        alignContent='center'
        justifyContent='center'>
        <Locacion
          id='1'
          imagen='https://mareaproperties.com.mx/imagenes/01portada.webp'
          texto='Depto. de lujo en Tulum frente al mar'
          rooms='1'
          baño='2'
          host='4'
        />
        <Locacion
          id='2'
          imagen='https://mareaproperties.com.mx/imagenes/02portada.webp'
          texto='Depto. de lujo en Tulum frente al mar'
          rooms='1'
          baño='2'
          host='4'
        />

        <Locacion
          id='4'
          imagen='https://mareaproperties.com.mx/imagenes/04portada.webp'
          texto='Depto. de lujo en Tulum frente al mar'
          rooms='2'
          baño='2'
          host='6'
        />

        <Locacion
          id='3'
          imagen='https://mareaproperties.com.mx/imagenes/03portada.webp'
          texto='Depto. de lujo en Playa del Carmen'
          rooms='3'
          baño='2'
          host='6'
        />
      </Stack>
    </View>
  )
}

export default PropiedadesDestacadas
