import { Stack, Pressable, View, Text, Center, Image, Box, VStack } from 'native-base'
import ContactoComponent from '../components/ContactoComponent'
import { FaRegCircleCheck } from 'react-icons/fa6'
import { turquesa } from '../helper/colors'
import { animateScroll as scroll } from 'react-scroll'
import { FaTools, FaHome } from 'react-icons/fa'
import { BiSupport } from 'react-icons/bi'
import { TbPresentationAnalytics } from 'react-icons/tb'
import AliadosSwiper from '../components/Swipers/AliadosSwiper'

const PropertyManagement = () => {
  const Beneficio = ({ icon, titulo, texto }) => {
    return (
      <Pressable
        bg='#fff'
        flex={1}
        mb={4}
        shadow={2}
        px={5}
        _hover={{
          shadow: 7,
          pb: 4,
          mt: -4,
        }}>
        <Stack pt={3} pb={8}>
          <Center mt={3}>
            <FaRegCircleCheck size={56} color='#008284' />
          </Center>
          <Text
            textAlign='center'
            mt={3}
            style={{ fontFamily: 'Circular' }}
            fontSize={['lg', 'xl', 'xl', '2xl', '2xl']}>
            {titulo}
          </Text>
          <Text
            textAlign='center'
            mt={3}
            color='#685f5f'
            px={2}
            style={{ fontFamily: 'Avenir' }}
            fontSize={['md', 'md', 'lg', 'lg', 'lg']}>
            {texto}
          </Text>
        </Stack>
      </Pressable>
    )
  }

  const Servicio = ({ icono, texto }) => {
    return (
      <VStack>
        <Center>{icono}</Center>

        <Text textAlign={'center'} fontWeight={'semibold'} fontSize={'lg'}>
          {texto}
        </Text>
      </VStack>
    )
  }

  return (
    <View w='100%' bg='#dadada'>
      <ContactoComponent />

      <Text
        fontSize={['2xl', '2xl', '2xl', '4xl', '4xl']}
        fontWeight='bold'
        mt={16}
        alignSelf='center'
        color='coolGray.800'
        style={{ fontFamily: 'Circular' }}>
        ¿POR QUÉ MAREA PROPERTIES?
      </Text>

      <Text
        fontSize={['sm', 'md', 'md', 'lg', 'lg']}
        textAlign='center'
        my={3}
        px={10}
        color='coolGray.600'
        style={{ fontFamily: 'Avenir' }}>
        Nos ocupamos de todos los aspectos relacionados con la distribución de su inmueble,
        promoviéndolo ante posibles huéspedes en diversas plataformas. Nuestro equipo se encarga de
        la gestión de pagos, los procesos de entrada y salida, además de la comunicación y mensajes
        con los huéspedes las 24 horas del día, los 7 días de la semana. Permítanos tomar el control
        y asegurar que su alquiler vacacional sea exitoso y libre de estrés.
      </Text>

      <Stack
        space={6}
        alignSelf='center'
        justifyContent='center'
        direction={['column', 'column', 'row', 'row', 'row']}
        my={3}
        w='90%'>
        <Beneficio
          titulo='Visita'
          texto='Nosotros visitaremos personalmente su propiedad para realizar una evaluación y proporcionar recomendaciones expertas.'
        />
        <Beneficio
          titulo='Contrato'
          texto='Una vez que hayamos realizado una evaluación de su propiedad y acordado los términos, firmaremos un contrato de gestión de servicios.'
        />
        <Beneficio
          titulo='Publicación'
          texto='Creamos perfiles de su propiedad en diversas plataformas para maximizar la visibilidad y atraer a posibles huéspedes.'
        />
      </Stack>
      <Stack
        direction={['column', 'column', 'row', 'row', 'row']}
        space={6}
        alignSelf='center'
        my={3}
        w={['90%', '90%', '60%', '60%', '60%']}
        justifyContent='center'>
        <Beneficio
          titulo='Informes'
          texto='Le proporcionaremos informes regulares sobre el rendimiento de su propiedad. Manténgase informado y confíe en nosotros para ayudarle a tomar decisiones basadas en datos para obtener resultados óptimos.'
        />
        <Beneficio
          titulo='Producción'
          texto='Comience a obtener rendimientos de su propiedad hoy mismo.'
        />
      </Stack>

      {/* Nuestros servicios */}

      <Stack direction={['column', 'column', 'row', 'row', 'row']} w='100%' my={10}>
        <Stack
          zIndex={9}
          direction='column'
          w={['90%', '90%', '60%', '60%', '60%']}
          py={8}
          mt={3}
          mr={[5, 5, '-12%', '-12%', '-12%']}
          mb={10}
          alignSelf='center'
          borderRadius={10}
          ml={[5]}
          bg='#fff'
          mx='auto'
          shadow={8}>
          <Text
            fontSize={['xl', 'xl', '2xl', '2xl', '2xl']}
            fontWeight='bold'
            alignSelf='center'
            color='coolGray.800'
            style={{ fontFamily: 'Circular' }}>
            Nuestros servicios
          </Text>
          <Text
            px={8}
            textAlign='justify'
            style={{ fontFamily: 'Avenir' }}
            fontSize={['sm', 'sm', 'md', 'md', 'md']}>
            Marea Properties ofrece una administración integral de 360 grados para su casa de
            vacaciones. Nos encargamos de todo, desde preparar su unidad para la comercialización
            hasta su mantenimiento, pago de facturas, y ofrecimiento de servicio al cliente,
            servicios de conserjería y más. Nuestro objetivo es maximizar sus ingresos por alquiler
            y asegurar que tenga una experiencia sin complicaciones al poseer una segunda vivienda.
            {'\n'} {'\n'}
            Invertimos considerablemente en tecnología y contratamos al mejor talento local para
            brindarle un servicio de alta calidad. Todo nuestro personal está certificado por la
            industria hotelera, asegurando que cumplimos con sus máximas exigencias. Con Marea
            Properties, puede disfrutar de los beneficios de poseer una casa de vacaciones sin el
            estrés.
          </Text>

          <Stack direction={{ base: 'column', lg: 'row' }} alignSelf={'center'} space={10}>
            <VStack alignSelf={'center'} space={10} mt={10}>
              <Servicio icono={<FaTools size={'3em'} />} texto='Administración y mantenimiento' />
              <Servicio
                icono={<FaHome size={'3em'} />}
                texto='Manejo y comercialización de propiedad'
              />
            </VStack>
            <VStack alignSelf={'center'} space={10} mt={10}>
              <Servicio icono={<BiSupport size={'3em'} />} texto='Asesoría' />
              <Servicio
                icono={<TbPresentationAnalytics size={'3em'} />}
                texto='Estrategias comerciales de Revenue'
              />
            </VStack>
          </Stack>
        </Stack>

        <Image
          source={{
            uri: 'https://mareaproperties.com.mx/imagenes/img97.jpg',
          }}
          alt='Alternate Text'
          w={['90%', '90%', '50%', '50%', '50%']}
          h={96}
          mt={12}
          borderRadius={10}
          mr={[4, 4, 16, 16, 16]}
          ml={[4, 4, 0, 0, 0]}
        />
      </Stack>

      {/* ALIADOS */}
      <Text bold style={{ fontFamily: 'Circular' }} fontSize={'4xl'} textAlign={'center'}>
        SINERGÍAS COMERCIALES
      </Text>
      {/* <VStack bg='#fff' borderRadius={20} w='95%' alignSelf={'center'} shadow={7}>
        <Stack
          w='100%'
          flex={1}
          alignSelf='center'
          justifyContent='space-between'
          direction={{ base: 'column', lg: 'row' }}
          py={12}>
          <Image
            source={{
              uri: 'https://luvtulum.com/wp-content/uploads/2018/02/LuvTulum_Letters_Logo_dark3.png',
            }}
            alt='Alternate Text'
            alignSelf={'center'}
            w={{ base: '80%', lg: '30%' }}
            h={32}
            resizeMode='center'
          />
          <Image
            source={{
              uri: 'https://mareaproperties.com.mx/imagenes/anderson.png',
            }}
            alt='Alternate Text'
            alignSelf={'center'}
            w={{ base: '80%', lg: '30%' }}
            h={32}
            resizeMode='center'
          />
          <Image
            source={{
              uri: 'https://mareaproperties.com.mx/imagenes/logoaliado.png',
            }}
            alt='Alternate Text'
            alignSelf={'center'}
            w={{ base: '80%', lg: '30%' }}
            h={32}
            resizeMode='center'
          />
        </Stack>
        <Stack
          w='100%'
          flex={1}
          alignSelf='center'
          justifyContent='space-between'
          direction={{ base: 'column', lg: 'row' }}
          pb={12}>
          <Image
            source={{
              uri: 'https://static.wixstatic.com/media/7efc9e_ebe0bd51c4e9455eb3e482312e343884~mv2.png/v1/fit/w_320,h_320,q_90/7efc9e_ebe0bd51c4e9455eb3e482312e343884~mv2.png',
            }}
            alt='Alternate Text'
            alignSelf={'center'}
            w={{ base: '80%', lg: '30%' }}
            h={32}
            resizeMode='center'
          />
          <Image
            source={{
              uri: 'https://tulumtables.com/images/clubs/logo/balabamba-tulum.png',
            }}
            alt='Alternate Text'
            alignSelf={'center'}
            w={{ base: '80%', lg: '30%' }}
            h={32}
            resizeMode='center'
          />
          <Image
            source={{
              uri: 'https://www.siteminder.com/wp-content/uploads/2019/03/ahau-tulum-1.jpg',
            }}
            alt='Alternate Text'
            alignSelf={'center'}
            w={{ base: '80%', lg: '30%' }}
            h={32}
            resizeMode='center'
          />
        </Stack>
      </VStack> */}
      <Box w='90%' py={10} alignSelf={'center'} bg='#fff' borderRadius={10} shadow={6}>
        <AliadosSwiper />
      </Box>

      <Center>
        <Text
          fontSize={['2xl', '2xl', '4xl', '4xl', '4xl']}
          fontWeight='bold'
          mt={5}
          alignSelf='center'
          color='coolGray.800'
          style={{ fontFamily: 'Circular' }}>
          ¿Interesado? Hablemos
        </Text>
      </Center>
      <Center mb={32} mt={6}>
        <Pressable
          onPress={() => {
            scroll.scrollToTop()
          }}
          shadow={8}
          flex={1}
          py={2}
          px={4}
          borderRadius={5}
          bg={turquesa}>
          <Text
            style={{ fontFamily: 'Circular' }}
            color='#fff'
            fontSize={['xl', 'xl', '2xl', '2xl', '2xl']}>
            Evaluación de alquiler gratuita
          </Text>
        </Pressable>
      </Center>
    </View>
  )
}
export default PropertyManagement
