import * as React from "react";
import { Box, Heading, VStack, FormControl, Input, Button, Center, HStack, Text, Icon } from "native-base";
import { useState } from "react";
import fetchPost from "../helper/fetchPost";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { FaUserPen } from "react-icons/fa6";
import { MdAlternateEmail, MdPhone, MdOutlinePassword } from "react-icons/md";
import { Link } from "react-router-dom";
import URL from "../helper/baseURL";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";

const Registro = () => {
  const navigate = useNavigate();


  const [correo, setCorreo] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [name, setName] = useState("");
  const [apellido, setApellido] = useState("");
  const [phone, setPhone] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  //mostrar /ocultar password
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const [show2, setShow2] = useState(false);
  const handleClick2 = () => setShow2(!show2);

  const estiloLink = {
    color: '#6568f1',
    fontWeight: 'bold',
    fontSize: '16px', // Tamaño de fuente similar a "sm"
    textDecoration: 'none',
    textAlign: 'center',// Para eliminar el subrayado predeterminado
  };


  const NuevoUsuario = async () => {
    setIsLoading(true);
    if (!validarCredenciales()) {
      setIsLoading(false);// Si la validación falla, detener la ejecución
      return;
    }
    const dataLogin = new FormData();
    dataLogin.append("correo", correo.trim());
    dataLogin.append("password", password);
    dataLogin.append("nombre", name);
    dataLogin.append("apellido", apellido);
    dataLogin.append("telefono", phone);
    const url = `${URL.BASE_URL}/registro`
    const options = {
      method: 'POST',
      body: dataLogin
    };
    const res = await fetchPost(url, options);
    console.log("res", res);
    window.alert(res.mensaje);
    setIsLoading(false);
    if (res.resultado) {
      navigate("/Login");
    }

  }


  // Función para validar el correo electrónico y la contraseña
  const validarCredenciales = () => {
    // Verifica si el correo contiene '@'
    const esCorreoValido = correo.includes('@');
    // Verifica si la contraseña tiene más de 5 caracteres
    const esPasswordValida = password.length > 4;

    if (!esCorreoValido) {
      window.alert('Por favor ingresa un correo electrónico válido.');
      return false;
    }

    if (!esPasswordValida) {
      window.alert('La contraseña debe tener más de 5 caracteres.');
      return false;
    }

    if (name.trim() === '') {
      window.alert("Por favor, ingresa tu nombre.");
      return false;
    }
    if (apellido.trim() === '') {
      window.alert("Por favor, ingresa tu apellido.");
      return false;
    }
    if (phone.trim() === '') {
      window.alert("Por favor, ingresa tu teléfono.");
      return false;
    } else if (phone.trim().length < 10 || phone.trim().length > 13) {
      window.alert("El teléfono debe tener entre 10 y 13 números.");
      return false;
    }
    if (password.trim() === '') {
      window.alert("Por favor, ingresa tu nueva contraseña.");
      return false;
    }
    if (password2.trim() === '') {
      window.alert("Por favor, confirma tu nueva contraseña.");
      return false;
    }
    if (password !== password2) {
      window.alert("La nueva contraseña y la confirmación no coinciden.");
      return false;
    }

    return true;
  };


  return (
    <Center w="100%" mt={[12, 12, 20, 24]}>
      <Box safeArea p="2" w="90%" maxW="90%" py="8">
        <Heading textAlign="center" flex={1} color="coolGray.800" fontWeight="semibold">

          ¡Registrate para continuar!
        </Heading>
        {/* <Heading mt="1" color="coolGray.600" _dark={{
          color: "warmGray.200"
        }} fontWeight="medium" size="xs">
          
        </Heading> */}


        <VStack space={3} mt="5">
          <FormControl>
            <FormControl.Label>Correo electrónico: </FormControl.Label>
            <Input
              placeholder='Email'
              onChangeText={(val) => setCorreo(val.toUpperCase())}
              value={correo}
              InputLeftElement={
                <Center ml={2}>
                  <Icon
                    as={<MdAlternateEmail />}
                  />
                </Center>}
            />
          </FormControl>

          <FormControl>
            <FormControl.Label>Nombre: </FormControl.Label>
            <Input
              placeholder='Nombre'
              onChangeText={(val) => setName(val)}
              value={name} InputLeftElement={
                <Center ml={2}>
                  <Icon
                    as={<FaUserPen />}
                  />
                </Center>}
            />
          </FormControl>

          <FormControl>
            <FormControl.Label>Apellido(s): </FormControl.Label>
            <Input
              placeholder='Apellido(s)'
              onChangeText={(val) => setApellido(val)}
              value={apellido} InputLeftElement={
                <Center ml={2}>
                  <Icon
                    as={<FaUserPen />}
                  />
                </Center>}
            />
          </FormControl>

          <FormControl>
            <FormControl.Label>Teléfono:</FormControl.Label>
            <Input
              placeholder='Teléfono'
              onChangeText={(val) => setPhone(val.toUpperCase())}
              value={phone}
              InputLeftElement={
                <Center ml={2}>
                  <Icon
                    as={<MdPhone />}
                  />
                </Center>}
            />
          </FormControl>
          <FormControl>
            <FormControl.Label>Contraseña</FormControl.Label>
            <Input type={show ? 'text' : 'password'}
              placeholder='Contraseña'
              onChangeText={(val) => setPassword(val)}
              value={password}
              InputRightElement={
                <Button
                  ml={1}
                  variant='link'
                  roundedLeft={0}
                  roundedRight='md'
                  onPress={handleClick}
                >
                  {show ? (
                    <FaRegEyeSlash />

                  ) : (
                    <FaRegEye />
                  )}
                </Button>}
              InputLeftElement={
                <Center ml={2}>
                  <Icon
                    as={<MdOutlinePassword />}
                  />
                </Center>}
            />


          </FormControl>

          <FormControl>
            <FormControl.Label>Confirmar contraseña</FormControl.Label>
            <Input type={show2 ? 'text' : 'password'}
              placeholder='Confirmar Contraseña'
              onChangeText={(val) => setPassword2(val)}
              value={password2} pl={2}
              InputRightElement={
                <Button
                  ml={1}
                  variant='link'
                  roundedLeft={0}
                  roundedRight='md'
                  onPress={handleClick2}
                >
                  {show2 ? (
                    <FaRegEyeSlash />

                  ) : (
                    <FaRegEye />
                  )}
                </Button>}
              InputLeftElement={
                <Center ml={2}>
                  <Icon
                    as={<MdOutlinePassword />}
                  />
                </Center>}
            />
          </FormControl>

          {


            isLoading ?
              <Loader size="lg" color="primary.500" />
              :
              <Button mt="2" minW={40} maxW={80} colorScheme="indigo" alignSelf="center"
                onPress={() => NuevoUsuario()}>
                Registrarse
              </Button>
          }


          <HStack mt="6" justifyContent="center" alignContent={"center"}>
            <Text fontSize="sm" mt={-1} color="coolGray.600" _dark={{
              color: "warmGray.200"
            }} >
              ¿Ya tienes cuenta?{" "}
            </Text>
            <Link to="/login" style={estiloLink}>
              Iniciar Sesión
            </Link>
          </HStack>

        </VStack>



      </Box>
    </Center>
  );
};

export default Registro;