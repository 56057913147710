import React, { useState } from 'react';

const TranslatePage = () => {
    const [language, setLanguage] = useState('es');
    const [text1, setText1] = useState('Hola, este es un texto de prueba.');
    const [text2, setText2] = useState('Este es otro texto de prueba.');
    const [translatedText1, setTranslatedText1] = useState('');
    const [translatedText2, setTranslatedText2] = useState('');

    const translateText = async (text, lang) => {
        try {
            const response = await fetch('https://mareaproperties.com.mx/back/public/translate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ text, lang }),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            return data.text; // Asegúrate de que esta sea la ruta correcta del texto traducido en la respuesta JSON
        } catch (error) {
            console.error("Error translating text:", error);
        }
    };


    const handleLanguageChange = async (newLanguage) => {
        const translation1 = await translateText(text1, newLanguage);
        const translation2 = await translateText(text2, newLanguage);
        setTranslatedText1(translation1 || text1);
        setTranslatedText2(translation2 || text2);
    };

    return (
        <div>
            <h2>{translatedText1 || text1}</h2>
            <h2>{translatedText2 || text2}</h2>
            <button onClick={() => handleLanguageChange('es')}>Español</button>
            <button onClick={() => handleLanguageChange('en')}>English</button>
            <button onClick={() => handleLanguageChange('fr')}>Français</button>
        </div>
    );
};

export default TranslatePage;
