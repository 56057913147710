import React, { useState } from 'react'
import { Pressable, Modal, Button, Center } from 'native-base'
import { GrLanguage } from 'react-icons/gr'

const LenguajeSelector = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Pressable onPress={() => setIsOpen(true)} alignItems='center' justifyContent='center'>
        <GrLanguage size={24} color={'#dadada'} />
      </Pressable>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}
        top={32}
        justifyContent='flex-start'>
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>Selecciona idioma</Modal.Header>
          <Modal.Body>
            <Center h={20}>
              <div id='google_translate_element'></div>
            </Center>
          </Modal.Body>
          <Modal.Footer>
            <Button onPress={() => setIsOpen(false)}>Aceptar</Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>
  )
}

export default LenguajeSelector
