import React from "react";
import { useUser } from "../../helper/UserContext";
import Login from "../Login";
import VerInmueblesComponent from "../../components/admin/VerInmueblesComponent";


const AdminInmuebles = () => {

    const { tipo, userId } = useUser();


    return (
        <>
            {userId !== null && (
                tipo === "24" ? (
                    <VerInmueblesComponent />
                ) : (
                    <Login />
                )
            )}
        </>
    );
}

export default AdminInmuebles; 