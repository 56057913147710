import React, { useState } from 'react'
import { Text, Pressable, HStack, Box, Image, Stack, VStack } from 'native-base'
import { IconContext } from 'react-icons'

import { useNavigate } from 'react-router-dom'
import { FiMail } from 'react-icons/fi'
import { animateScroll as scroll } from 'react-scroll'
import whatsapp from '../Lotties/whatsapp.json'
import Lottie from 'lottie-react'
import { FaFacebook, FaInstagram } from 'react-icons/fa'
import { FaLocationDot } from 'react-icons/fa6'
import { TfiYoutube } from 'react-icons/tfi'
import { PiTiktokLogoBold } from 'react-icons/pi'

const Footer = () => {
  const ButtonMenu = ({ link, text }) => {
    const navigate = useNavigate()
    const handleClickMenu = (vista) => {
      scroll.scrollToTop()
      navigate(vista)
    }
    const [hover, setHover] = useState(false)

    const handleMouseEnter = () => setHover(true)
    const handleMouseLeave = () => setHover(false)

    return (
      <Pressable
        onPress={() => handleClickMenu(link)}
        w='auto'
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <Text
          style={{ fontFamily: 'Circular' }}
          p={1}
          fontSize={['xs', 'xs', 'sm', 'md', 'lg']}
          color={hover ? '#008284' : '#000'}>
          {text}
        </Text>
      </Pressable>
    )
  }

  const handlePressEmail = () => {
    console.log('email')
    // La dirección de correo electrónico a la que se enviará el correo
    // const toEmail = 'contacto@mareaproperties.com.mx';

    // El asunto del correo electrónico
    // const subject = 'Asunto del correo electrónico';

    // Construye la URL "mailto" con la dirección de correo electrónico y el asunto
    // const mailtoUrl = `mailto:${toEmail}?subject=${encodeURIComponent(subject)}`;

    // Abre la URL en una nueva pestaña
    // window.open(mailtoUrl, '_blank');
  }

  const ImagenDeFondo = {
    backgroundImage: `linear-gradient(rgba(11, 163, 170, 0.8), rgba(11, 163, 170, 0.9)), url("https://mareaproperties.com.mx/imagenes/fondointro.png")`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  }

  const handlePressWA = () => {
    // El número de teléfono de WhatsApp al que se enviará un mensaje (puedes cambiarlo según tus necesidades)
    // const whatsappNumber = "9982304219";
    const whatsappNumber = '9987133458'
    // Construye la URL de WhatsApp con el número de teléfono
    const whatsappUrl = `https://wa.me/${whatsappNumber}`
    // Abre la URL en una nueva pestaña
    window.open(whatsappUrl, '_blank')
  }

  const handlePressFB = () => {
    // La URL de Facebook a la que se redirigirá al presionar el ícono
    const facebookUrl = 'https://www.facebook.com/mareaproperties.mx'

    // Abre la URL en una nueva pestaña
    window.open(facebookUrl, '_blank')
  }

  const handlePressIG = () => {
    // La URL de Facebook a la que se redirigirá al presionar el ícono
    const facebookUrl = 'https://www.instagram.com/mareaproperties.mx'

    // Abre la URL en una nueva pestaña
    window.open(facebookUrl, '_blank')
  }

  const handlePressMap = () => {
    // La URL de Facebook a la que se redirigirá al presionar el ícono
    const facebookUrl = 'https://maps.app.goo.gl/8fyD12QSo1MJkkJv7?g_st=ic'

    // Abre la URL en una nueva pestaña
    window.open(facebookUrl, '_blank')
  }

  const handlePressLP = () => {
    // La URL de Facebook a la que se redirigirá al presionar el ícono
    const lpUrl = 'https://nuevo.lpmarketinggroup.com.mx/'

    // Abre la URL en una nueva pestaña
    window.open(lpUrl, '_blank')
  }

  const handlePress = (link) => {
    window.open(link, '_blank')
  }

  return (
    <footer>
      {/* Boton WhatsAP */}
      <Pressable
        bottom={0}
        zIndex={9}
        position={'fixed'}
        justifyContent={'flex-start'}
        w={[16, 16, 24, 32]}
        h={[16, 16, 24, 32]}
        right={2}
        onPress={() => handlePressWA()}>
        <Lottie animationData={whatsapp} loop={true} />
      </Pressable>
      <VStack>
        <HStack
          alignSelf={'center'}
          alignItems='center'
          justifyContent='center'
          bg={'#008284'}
          w='100%'
          style={ImagenDeFondo}>
          <Box w='15%'>
            <Image
              source={{
                uri: 'https://mareaproperties.com.mx/imagenes/palmera02.png',
              }}
              alt='Alternate Text'
              h={[40]}
              w={[40]}
              resizeMode='contain'
            />
          </Box>

          <Stack direction='column' w='70%' alignItems='center' justifyContent='center'>
            <Image
              source={{
                uri: 'https://mareaproperties.com.mx/imagenes/MAREAwhite.png',
              }}
              alt='Alternate Text'
              h={[16]}
              w={[32]}
            />

            {/* Direccion */}
            <Pressable
              w='100%'
              onPress={() => {
                handlePressMap()
              }}>
              <HStack alignSelf={'center'} justifyContent='center' alignItems='center'>
                <IconContext.Provider value={{ color: '#edf5f7', size: '1.5em' }}>
                  <FaLocationDot />
                </IconContext.Provider>
                <Text
                  color='#ffffff'
                  fontSize={[9, 'sm', 'md', 'lg', 'lg']}
                  style={{ fontFamily: 'Circular' }}
                  mx={2}
                  letterSpacing={1.5}>
                  CENOTE TERCER CIELO C.P 77766, AV. COSTERA BOCA PAILA, Supermanzana KM 7.6, Tulum,
                  Q.R.
                </Text>
              </HStack>
            </Pressable>
            {/* Correo contacto */}
            <Pressable
              flex={1}
              onPress={() => {
                handlePressEmail()
              }}>
              <HStack alignSelf={'center'} justifyContent='center' alignItems='center'>
                <IconContext.Provider value={{ color: '#edf5f7', size: '2em' }}>
                  <FiMail />
                </IconContext.Provider>
                <Text
                  color='#ffffff'
                  fontSize={[9, 'sm', 'md', 'lg', 'lg']}
                  style={{ fontFamily: 'Circular' }}
                  mx={2}
                  letterSpacing={1.5}>
                  reservaciones@mareaproperties.com.mx
                </Text>
              </HStack>
            </Pressable>

            {/* Redes sociales */}
            <HStack space={3} justifyContent='center' alignSelf={'center'} alignItems={'center'}>
              <Pressable
                p={4}
                onPress={() => {
                  handlePressFB()
                }}>
                <IconContext.Provider value={{ color: '#edf5f7', size: '2.5em' }}>
                  <FaFacebook />
                </IconContext.Provider>
              </Pressable>
              <Pressable
                p={4}
                onPress={() => {
                  handlePressIG()
                }}>
                <IconContext.Provider value={{ color: '#edf5f7', size: '2.5em' }}>
                  <FaInstagram />
                </IconContext.Provider>
              </Pressable>

              <Pressable
                p={4}
                onPress={() => {
                  handlePress('https://www.youtube.com/@mareapropertiesmx')
                }}>
                <IconContext.Provider value={{ color: '#edf5f7', size: '2.5em' }}>
                  <TfiYoutube />
                </IconContext.Provider>
              </Pressable>

              <Pressable
                p={4}
                onPress={() => {
                  handlePress('https://www.tiktok.com/@marea.properties')
                }}>
                <IconContext.Provider value={{ color: '#edf5f7', size: '2.5em' }}>
                  <PiTiktokLogoBold />
                </IconContext.Provider>
              </Pressable>
            </HStack>
          </Stack>

          <Box w='15%'>
            <Image
              source={{
                uri: 'https://mareaproperties.com.mx/imagenes/palmera01.png',
              }}
              alt='Alternate Text'
              h={[40]}
              w={[40]}
              resizeMode='contain'
              alignSelf={'flex-end'}
            />
          </Box>
        </HStack>

        <Stack
          direction={['column', 'row', 'row', 'row', 'row']}
          alignItems='center'
          alignSelf='center'
          justifyContent='center'
          py={2}
          mb={2}
          flex={1}
          w='90%'>
          <ButtonMenu link='/' text='© 2024 Marea Properties' />
          <HStack>
            <ButtonMenu link='/Terminos' text='Términos y condiciones' />
            <ButtonMenu link='/Privacidad' text='Política de privacidad' />
          </HStack>
        </Stack>
        <Pressable onPress={() => handlePressLP()}>
          <HStack space={4} alignSelf={'center'} alignItems={'center'} justifyContent={'center'}>
            <Text textAlign={'center'} fontFamily={'circular'}>
              Created and designed by:
            </Text>
            <Image
              alignSelf={'center'}
              source={{
                uri: 'https://mareaproperties.com.mx/imagenes/LogoLP.jpg',
              }}
              alt='Alternate Text'
              w={40}
              h={20}
            />
          </HStack>
        </Pressable>
      </VStack>
    </footer>
  )
}

export default Footer
