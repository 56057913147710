import React, { useState, useEffect } from "react";
import { Text, Pressable, Image, VStack, Center, Stack, HStack, ZStack } from "native-base";

import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import URL from "../helper/baseURL";
import AuthModal from "./AuthModal";
import { useUser } from "../helper/UserContext";
import fetchPost from "../helper/fetchPost";
import useCustomToast from "./CustomToast";
import { turquesa } from "../helper/colors";
import { IoBedOutline, IoPersonOutline } from "react-icons/io5";
import { LuBath } from "react-icons/lu";
import { animateScroll as scroll } from 'react-scroll';



const InmuebleComponent = ({ id, imageUri, titulo, lugar, duracion, precio, rooms, bath, guest }) => {
    const showToast = useCustomToast();
    const [favoriteChangedByClick, setFavoriteChangedByClick] = useState(false);

    const [initialFavoriteStatusChecked, setInitialFavoriteStatusChecked] = useState(false);

    const BASE_URL = URL.BASE_URL;
    const BASE_URL2 = "https://mareaproperties.com.mx/imagenes/";
    const [isFavorite, setIsFavorite] = useState(false);
    //para navegar a otras vistas
    const navigate = useNavigate();
    const handleClick = (vista) => {
        scroll.scrollToTop();
        navigate(vista)
    };

    const { userId } = useUser();



    const handleClick2 = () => {
        navigate(`/detail/${id}/${titulo}`);
    };

    const handleFavoriteClick = () => {
        if (!userId) {
            openModal();
        } else {
            setIsFavorite(!isFavorite);
            setFavoriteChangedByClick(true); // Cambio iniciado por clic
        }
    };
    // useEffect(() => {
    //     if (initialFavoriteStatusChecked && favoriteChangedByClick) {
    //         const manejarCambioFavorito = async () => {
    //             if (isFavorite) {
    //                 await AgregarFavorito();
    //             } else {
    //                 await EliminarFavorito();
    //             }
    //             setFavoriteChangedByClick(false);
    //         };

    //         manejarCambioFavorito();
    //     }
    // }, [isFavorite, initialFavoriteStatusChecked, favoriteChangedByClick]);



    const AgregarFavorito = async () => {


        const data = new FormData();
        data.append("id_user", userId);
        data.append("id_inmueble", id);
        const url = `${URL.BASE_URL}favoritos/agregar`;
        const options = {
            method: 'POST',
            body: data
        };
        const res = await fetchPost(url, options);
        //console.log("respuesta", res.status);
        if (res && res.status) {
            showToast({
                duration: 5000,
                text: "Favorito agregado!",
                color: "success.500",
                position: "top"
            });
        } else {
            showToast({
                duration: 5000,
                text: "Error, verifica tu conexión e intenta más tarde",
                color: "error.500", // Cambié el color a rojo para indicar un error
                position: "top"
            });
        }


    };
    const EliminarFavorito = async () => {


        const data = new FormData();
        data.append("id_user", userId);
        data.append("id_inmueble", id);
        const url = `${URL.BASE_URL}favoritos/eliminar`;
        const options = {
            method: 'POST',
            body: data
        };
        const res = await fetchPost(url, options);
        if (res && res.status) {
            showToast({
                duration: 5000,
                text: "Favorito Eliminado!",
                color: "warning.500",
                position: "top"
            });
        } else {
            showToast({
                duration: 5000,
                text: "Error, verifica tu conexión e intenta más tarde",
                color: "red.500", // Cambié el color a rojo para indicar un error
                position: "top"
            });
        }

    };


    const CheckFavorito = async () => {


        const data = new FormData();
        data.append("id_user", userId);
        data.append("id_inmueble", id);
        const url = `${URL.BASE_URL}favoritos/check`;
        const options = {
            method: 'POST',
            body: data
        };
        const res = await fetchPost(url, options);

        if (res && res.existe) {
            setIsFavorite(true);
            console.log("respuesta", res.existe);
        }



    };

    // useEffect(() => {
    //     const verificarEstadoInicialFavorito = async () => {
    //         await CheckFavorito();
    //         setInitialFavoriteStatusChecked(true);
    //     };

    //     verificarEstadoInicialFavorito();
    // }, []);


    //logica de modal:
    const [isModalOpen, setModalOpen] = useState(false);

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    return (
        <Pressable onPress={() => handleClick("/detalle")} >
            <VStack m={4} w={[72, "29em", "30em", "29em", "35em"]} borderColor={"#c4c4c4"} mx={5} borderWidth={1} borderRadius={10} pb={2} bg={"#fff"} shadow={7}>

                {/* Imagen */}
                <ZStack position="relative" w="100%" h={[56, 72, 72, 72, 80]}>
                    <Image h={[56, 72, 72, 72, 80]} w="100%" borderTopRadius={10} source={{
                        uri: `${BASE_URL2}${imageUri}`
                    }} alt="Alternate Text" />
                    <Center bg="muted.200" borderRadius={20} py={1} px={2} m={2}>
                        <Text style={{ fontFamily: 'Avenir' }}>{lugar}</Text>
                    </Center>

                    {/* Ícono de favorito */}
                    <Pressable bg="muted.200" borderRadius={20} p={1}
                        position="absolute"
                        top={2} right={2}
                        onPress={() => setIsFavorite(!isFavorite)}
                    >
                        {isFavorite ? (
                            <AiFillHeart color={turquesa} size="2em" />
                        ) : (
                            <AiOutlineHeart color={turquesa} bg="red" size="2em" />
                        )}
                    </Pressable>
                </ZStack>


                <AuthModal isOpen={isModalOpen} onClose={closeModal} />

                {/* Titulo */}

                <Text style={{ fontFamily: 'Circular' }} fontSize={"xl"} p={2} ml={3} >
                    {titulo}
                </Text>


                <Stack direction={["column", "row", "row", "row", "row"]} ml={[2, 3, 4, 5, 5]} >
                    <HStack>
                        <IoBedOutline size={"1.5em"} color="#c7c7c7" />
                        <Text style={{ fontFamily: 'Avenir' }} fontSize={"1em"} mt={1} mr={4} ml={2}>
                            {rooms} Habitaciones
                        </Text>
                    </HStack>

                    <HStack>
                        <LuBath size={"1.5em"} color="#c7c7c7" />

                        <Text style={{ fontFamily: 'Avenir' }} fontSize={"1em"} mt={1} mr={4} ml={2}>
                            {bath} Baños
                        </Text>
                    </HStack>

                    <HStack>
                        <IoPersonOutline size={"1.5em"} color="#c7c7c7" />

                        <Text style={{ fontFamily: 'Avenir' }} fontSize={"1em"} mt={1} mr={4} ml={2}>
                            {guest} Huespedes
                        </Text>
                    </HStack>
                </Stack>


                <HStack py={2} px={4} justifyContent="space-between">
                    <Pressable p={2} flex={1} mx={2} mt={2} h={10} bg={turquesa} shadow={5}
                        borderRadius={5} justifyContent="center" alignItems="center">
                        <Text py={2} px={4} textAlign="center" color="#fff" style={{ fontFamily: 'Circular' }} >
                            Reserva Ahora
                        </Text>
                    </Pressable>
                    <VStack alignSelf="flex-end">
                        <Text alignSelf="flex-end" fontSize={"md"} color="#5f5f5f" >Desde </Text>
                        <Text bold fontSize={["md", "xl"]} color="#5f5f5f" >${precio} MXN </Text>
                        <Text alignSelf="flex-end" fontSize={"md"} color="#5f5f5f" >diarios </Text>
                    </VStack>
                </HStack>
            </VStack>
        </Pressable>
    );
};

export default InmuebleComponent;
