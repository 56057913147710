import React, { useState, useEffect } from 'react'
import { View, Modal, Button, HStack, VStack } from 'native-base'
import ContactoComponent from '../components/ContactoComponent'
import VideoComponent from '../components/VideoComponent'
import LocacionesComponent from '../components/LocacionesComponent'
import VacacionComponent from '../components/VacacionComponent'
import TestimonioComponent from '../components/TestimonioComponent'
import PropiedadesDestacadas from '../components/PropiedadesDestacadas'
import OficinasComponent from '../components/OficinasComponent'
import { animateScroll as scroll } from 'react-scroll'
import Popup from 'reactjs-popup'
import AsyncStorage from '@react-native-async-storage/async-storage'

const Home = () => {
  const [showModal, setShowModal] = useState(false)
  const contentStyle = { background: '#000' }
  const overlayStyle = { background: 'rgba(0,0,0,0.5)' }
  const arrowStyle = { color: '#000' }
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     async function checkPopupStatus() {
  //       try {
  //         const popupShown = await AsyncStorage.getItem('popupShown')
  //         if (!popupShown) {
  //           // Si popupShown es null o undefined, significa que el popup no se ha mostrado aún
  //           setShowModal(true)
  //           await AsyncStorage.setItem('popupShown', 'true')
  //         }
  //       } catch (error) {
  //         console.error('Error fetching AsyncStorage:', error)
  //       }
  //     }

  //     checkPopupStatus()
  //   }, 3000) // 3000 ms = 3 segundos

  //   return () => {
  //     clearTimeout(timer) // Limpiar el timeout si el componente se desmonta
  //   }
  // }, [])

  // const PopupExample = () => (
  //   <Popup
  //     open={showModal}
  //     position='bottom center'
  //     {...{ contentStyle, overlayStyle, arrowStyle }}>
  //     <VStack bg='#fff'>
  //       <Button
  //         w={10}
  //         alignSelf={'flex-end'}
  //         borderRightRadius={0}
  //         onPress={() => setShowModal(false)}>
  //         X
  //       </Button>
  //       <ContactoComponent />
  //     </VStack>
  //   </Popup>
  // )

  return (
    <View alignContent={'center'} w='100%' bg='#dadada'>
      {/* <Divider thickness={5} bg={["#f00", "#0f0", "#00f", "#f0f", "#0ff"]} w="99%" /> */}

      <VideoComponent />
      <PropiedadesDestacadas titulo='PROPIEDADES DE LUJO DISPONIBLES' />

      <VacacionComponent />
      <TestimonioComponent />
      <LocacionesComponent />

      <OficinasComponent />

      <ContactoComponent />
    </View>
  )
}

export default Home
