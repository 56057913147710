import React, { useState } from 'react';
import { VStack, FormControl, Input, Button, View, Text, Center, Icon, Heading, Alert, Stack } from 'native-base';
import { useUser } from '../../helper/UserContext';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { FaUserPen } from "react-icons/fa6";
import fetchPost from '../../helper/fetchPost';
import URL from '../../helper/baseURL';
import { MdAlternateEmail, MdPhone, MdOutlinePassword } from "react-icons/md";
import Loader from '../Loader';

function InfoUserComponent() {
    const { correo, apellido, nombre, userId, telefono, actualizaUser } = useUser();
    const [name, setName] = useState(nombre);
    const [lastName, setLastName] = useState(apellido);
    const [phone, setPhone] = useState(telefono);

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [alertMessage, setAlertMessage] = useState('');
    const [isAlertVisible, setIsAlertVisible] = useState(false);

    const showAlert = (message) => {
        setAlertMessage(message);
        setIsAlertVisible(true);
    };


    const validateUserInfo = () => {
        if (name.trim() === '') {
            showAlert("Por favor, ingresa tu nombre.");
            return false;
        }
        if (lastName.trim() === '') {
            showAlert("Por favor, ingresa tu apellido.");
            return false;
        }
        if (phone.trim() === '') {
            showAlert("Por favor, ingresa tu teléfono.");
            return false;
        } else if (phone.trim().length < 10 || phone.trim().length > 13) {
            showAlert("El teléfono debe tener entre 10 y 13 números.");
            return false;
        }

        // Agrega aquí más validaciones si lo necesitas
        return true;
    };

    const validatePasswordChange = () => {
        if (currentPassword.trim() === '') {
            showAlert("Por favor, ingresa tu contraseña actual.");
            return false;
        }
        if (newPassword.trim() === '') {
            showAlert("Por favor, ingresa tu nueva contraseña.");
            return false;
        }
        if (confirmPassword.trim() === '') {
            showAlert("Por favor, confirma tu nueva contraseña.");
            return false;
        }
        if (newPassword !== confirmPassword) {
            showAlert("La nueva contraseña y la confirmación no coinciden.");
            return false;
        }
        // Agrega aquí más validaciones si lo necesitas
        return true;
    };




    const handleChangePassword = () => {
        if (validatePasswordChange()) {
            window.alert("Contraseña cambiada");
            // Aquí puedes manejar la lógica de envío del formulario de cambio de contraseña
        }
    };

    //contraseña icono mostrar
    const [show, setShow] = useState(false);
    const handleClick = () => setShow(!show);
    const [show2, setShow2] = useState(false);
    const handleClick2 = () => setShow2(!show2);
    const [show3, setShow3] = useState(false);
    const handleClick3 = () => setShow3(!show3);

    const [isLoading, setIsLoading] = useState(false);

    const handleUpdate = (nombreUser, apellidoUser, telUser) => {
        // Lógica para el inicio de sesión
        actualizaUser(nombreUser, apellidoUser, telUser);
    };

    const handleSubmit = async () => {
        setIsLoading(true);

        if (!validateUserInfo()) {
            setIsLoading(false);// Si la validación falla, detener la ejecución
            return;
        }
        const dataLogin = new FormData();
        dataLogin.append("ID", userId);
        dataLogin.append("Nombre", name);
        dataLogin.append("Apellido", lastName);
        dataLogin.append("Telefono", phone);
        const url = `${URL.BASE_URL}/actualizarInfoUser`
        const options = {
            method: 'POST',
            body: dataLogin
        };
        const res = await fetchPost(url, options);

        setIsLoading(false);
        if (res.exito) {
            handleUpdate(name, lastName, phone)
            window.alert(res.mensaje);
            console.log("hola clg");
        } else {
            window.alert(res.mensaje);
        }

    }



    return (
        <View mt={24} w="100%">


            <Heading alignSelf="center">
                Mi información personal
            </Heading>
            <Text mb={3} alignSelf="center">Visualiza y edita tu información</Text>


            <Stack alignSelf="center" justifyContent="space-between" direction={["column", "column", "row", "row"]} space={4} width="90%" mx="auto">
                <VStack w={["90%", "90%", "40%", "40%"]}>
                    <FormControl>
                        <FormControl.Label>Nombre</FormControl.Label>
                        <Input shadow={6} value={name} onChangeText={setName} placeholder={nombre}
                            InputLeftElement={
                                <Center ml={2}>
                                    <Icon
                                        as={<FaUserPen />}
                                    />
                                </Center>}
                        />
                    </FormControl>

                    <FormControl>
                        <FormControl.Label>Apellido</FormControl.Label>
                        <Input shadow={6} value={lastName} onChangeText={setLastName} placeholder={apellido}
                            InputLeftElement={
                                <Center ml={2}>
                                    <Icon
                                        as={<FaUserPen />}
                                    />
                                </Center>}
                        />
                    </FormControl>

                    <FormControl>
                        <FormControl.Label>Teléfono</FormControl.Label>
                        <Input shadow={6} value={phone} onChangeText={setPhone} keyboardType="phone-pad" placeholder={telefono}
                            InputLeftElement={
                                <Center ml={2}>
                                    <Icon
                                        as={<MdPhone />}
                                    />
                                </Center>}
                        />
                    </FormControl>

                    <FormControl>
                        <FormControl.Label>Correo Electrónico</FormControl.Label>
                        <Input shadow={6} bg="#d1d1d1" _focus={{ backgroundColor: '#d1d1d1' }} value={correo} isReadOnly={true}
                            InputLeftElement={
                                <Center ml={2}>
                                    <Icon
                                        as={<MdAlternateEmail />}
                                    />
                                </Center>} />
                    </FormControl>
                    {isAlertVisible && (
                        <Alert w="100%" status="error">
                            <Alert.Icon />
                            <Text>{alertMessage}</Text>
                        </Alert>
                    )}

                    {
                        isLoading ?
                            <Loader size="lg" color="teal.500" />
                            :
                            <Button shadow={6} w="70%" alignSelf="center" my={4} onPress={handleSubmit}>
                                Actualizar Información
                            </Button>

                    }




                </VStack>
                {/* Cambiar contraseña */}
                <VStack w={["90%", "90%", "40%", "40%"]} >




                    <Text my={1} bold alignSelf="center">Cambiar contraseña</Text>
                    <FormControl>
                        <FormControl.Label>Contraseña Actual</FormControl.Label>
                        <Input shadow={6} value={currentPassword} onChangeText={setCurrentPassword} type={show ? 'text' : 'password'}
                            InputRightElement={
                                <Button
                                    ml={1}
                                    variant='link'
                                    roundedLeft={0}
                                    roundedRight='md'
                                    onPress={handleClick}
                                >
                                    {show ? (
                                        <FaRegEyeSlash />

                                    ) : (
                                        <FaRegEye />
                                    )}
                                </Button>}
                            InputLeftElement={
                                <Center ml={2}>
                                    <Icon
                                        as={<MdOutlinePassword />}
                                    />
                                </Center>}
                        />
                    </FormControl>

                    <FormControl>
                        <FormControl.Label>Nueva Contraseña</FormControl.Label>
                        <Input shadow={6} value={newPassword} onChangeText={setNewPassword} type={show2 ? 'text' : 'password'}
                            InputRightElement={
                                <Button
                                    ml={1}
                                    variant='link'
                                    roundedLeft={0}
                                    roundedRight='md'
                                    onPress={handleClick2}
                                >
                                    {show2 ? (
                                        <FaRegEyeSlash />

                                    ) : (
                                        <FaRegEye />
                                    )}
                                </Button>}
                            InputLeftElement={
                                <Center ml={2}>
                                    <Icon
                                        as={<MdOutlinePassword />}
                                    />
                                </Center>}
                        />
                    </FormControl>


                    <FormControl>
                        <FormControl.Label>Confirmar Contraseña</FormControl.Label>
                        <Input shadow={6} value={confirmPassword} onChangeText={setConfirmPassword} type={show3 ? 'text' : 'password'}
                            InputRightElement={
                                <Button
                                    ml={1}
                                    variant='link'
                                    roundedLeft={0}
                                    roundedRight='md'
                                    onPress={handleClick3}
                                >
                                    {show3 ? (
                                        <FaRegEyeSlash />

                                    ) : (
                                        <FaRegEye />
                                    )}
                                </Button>}
                            InputLeftElement={
                                <Center ml={2}>
                                    <Icon
                                        as={<MdOutlinePassword />}
                                    />
                                </Center>}
                        />
                    </FormControl>


                    <Button mb={10} w="70%" alignSelf="center" my={4} colorScheme="danger" shadow={6} onPress={handleChangePassword}>Cambiar Contraseña</Button>


                </VStack>
            </Stack>

        </View>
    );
}

export default InfoUserComponent;
