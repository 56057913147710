import { View, Text } from 'native-base'
import PropiedadesDestacadas from '../components/PropiedadesDestacadas'

const Destinos = () => {
  const ImagenDeFondo = {
    backgroundImage: 'url("https://mareaproperties.com.mx/imagenes/home.jpg")', // Reemplaza 'ruta-de-tu-imagen.jpg' con la ubicación de tu imagen
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
  }
  return (
    <View w='100%' pb={20} bg='#dadada'>
      <View style={ImagenDeFondo} w='100%' h={96}>
        <Text
          style={{ fontFamily: 'Circular', textShadow: '2px 2px 4px rgba(0, 0, 0, 1)' }}
          bold
          fontSize={'6xl'}
          mx={10}
          color='#fff'
          alignSelf='center'
          mt={{ base: 16, lg: 40 }}
          letterSpacing={2}
          textAlign='center'>
          PROPIEDADES DE LUJO DISPONIBLES
        </Text>
      </View>
      <PropiedadesDestacadas />
    </View>
  )
}
export default Destinos
