import React, { useState } from "react";
import { Text, Pressable, Image, VStack, Center, HStack, ZStack, Button, Popover } from "native-base";
import { IoLocationOutline } from "react-icons/io5";
import { IconContext } from "react-icons";
import { useNavigate } from "react-router-dom";
import URL from "../helper/baseURL";
import { useUser } from "../helper/UserContext";
import fetchPost from "../helper/fetchPost";
import useCustomToast from "./CustomToast";




const FavoritoComponent = ({ id, imageUri, titulo, lugar, onFavoritoEliminado, precio }) => {
    const showToast = useCustomToast();


    const BASE_URL = URL.BASE_URL;

    //para navegar a otras vistas
    const navigate = useNavigate();

    const { userId } = useUser();
    const [showPopover, setShowPopover] = useState(false);
    const handleBorrar = () => {
        // Cambiar el estado para mostrar el Popover
        setShowPopover(true);
    }

    const handleClick = () => {
        navigate(`/detail/${id}/${titulo}`);
    };

    const EliminarFavorito = async () => {


        const data = new FormData();
        data.append("id_user", userId);
        data.append("id_inmueble", id);
        const url = `${URL.BASE_URL}favoritos/eliminar`;
        const options = {
            method: 'POST',
            body: data
        };
        const res = await fetchPost(url, options);
        if (res && res.status) {
            showToast({
                duration: 5000,
                text: "Favorito Eliminado!",
                color: "warning.500",
                position: "top"
            });
            onFavoritoEliminado();
        } else {
            showToast({
                duration: 5000,
                text: "Error, verifica tu conexión e intenta más tarde",
                color: "red.500", // Cambié el color a rojo para indicar un error
                position: "top"
            });
        }

    };









    return (

        <VStack m={4} borderColor={"#c4c4c4"} borderWidth={1} borderRadius={10} pb={2} bg={"#eeeeee"} shadow={7}>
            <Pressable onPress={() => handleClick()} >
                <ZStack position="relative" size={64}>
                    <Image size={64} borderTopRadius={10} source={{
                        uri: `${BASE_URL}uploads/${imageUri}`
                    }} alt="Alternate Text" />


                </ZStack>


                <Center>
                    <Text bold fontSize={"lg"} p={2}  >{titulo}</Text>
                </Center>

                <HStack justifyContent={"center"} >
                    <IconContext.Provider value={{ color: "#44c5d3", size: "1.5em" }}>
                        <IoLocationOutline />
                    </IconContext.Provider>
                    <Text fontSize={"md"} marginTop={-0.5} px={2}>{lugar}</Text>
                </HStack>


                <HStack py={2} alignSelf="center">
                    <Text bold fontSize={"xl"} >${precio}MXN </Text>
                    <Text fontSize={"xl"}>noche</Text>
                </HStack>
            </Pressable>


            <Popover
                isOpen={showPopover}
                onClose={() => setShowPopover(false)}
                trigger={(triggerProps) => {
                    return <Button {...triggerProps} colorScheme="warning" w="80%" alignSelf="center" onPress={() => handleBorrar()}>
                        Eliminar de favoritos
                    </Button>;
                }}
            >
                <Popover.Content accessibilityLabel="Confirmar eliminación" w="56">
                    <Popover.Arrow />
                    <Popover.CloseButton />
                    <Popover.Header>Confirmación</Popover.Header>
                    <Popover.Body>
                        ¿Estás seguro de que quieres eliminar este favorito?
                        <Button mt={4} colorScheme="red" onPress={() => {
                            EliminarFavorito();
                            setShowPopover(false); // Cierra el Popover
                        }}>
                            Confirmar
                        </Button>
                    </Popover.Body>
                </Popover.Content>
            </Popover>




        </VStack>
    );
};

export default FavoritoComponent;
