import React from 'react';

const MapaGoogle = ({ linkMapa }) => {
    // Asegúrate de que el link del mapa es válido y está bien formado.
    // Podrías agregar más validaciones o lógica según sea necesario.
    return (
        <div style={{ overflow: 'hidden', padding: '0', position: 'relative', height: '400px', width: '100%', marginBottom: "50px" }}>
            <iframe
                width="100%"
                height="100%"
                style={{ border: 0 }}
                loading="lazy"
                allowFullScreen
                title='Mapa Marea Properties'
                src={linkMapa}>
            </iframe>
        </div>
    );
};

export default MapaGoogle;