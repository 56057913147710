import React, { createContext, useContext, useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userId, setUserId] = useState(null);
  const [tipo, setTipo] = useState(null);
  const [nombre, setNombre] = useState(null);
  const [apellido, setApellido] = useState(null);
  const [correo, setCorreo] = useState(null);
  const [telefono, setTelefono] = useState(null);
  const [carrito, setCarrito] = useState([]);
  const [totalContext, setTotalContext] = useState(0);

  // Obtener el estado del carrito desde AsyncStorage al cargar la página
  useEffect(() => {
    const obtenerCarritos = async () => {
      try {
        const carritosActuales = await AsyncStorage.getItem('carrito');
        const carritosParseados = JSON.parse(carritosActuales) || [];
        setCarrito(carritosParseados);
      } catch (error) {
        console.error('Error al obtener carritos desde AsyncStorage:', error);
      }
    };

    obtenerCarritos();
  }, []);




  // Obtener el estado del usuario
  useEffect(() => {
    const obtenerUsuario = async () => {
      try {
        const usuarioActual = await AsyncStorage.getItem('userId');
        setUserId(usuarioActual);

        const usuarioTipoActual = await AsyncStorage.getItem('tipo');
        setTipo(usuarioTipoActual);

        // Obtener los datos adicionales
        const nombreActual = await AsyncStorage.getItem('nombre');
        setNombre(nombreActual);

        const apellidoActual = await AsyncStorage.getItem('apellido');
        setApellido(apellidoActual);

        const correoActual = await AsyncStorage.getItem('correo');
        setCorreo(correoActual);

      } catch (error) {
        console.error('Error al obtener datos desde AsyncStorage:', error);
      }
    };
    obtenerUsuario();
  }, []);

  const agregarAlCarrito = (nuevoCarrito) => {
    setCarrito([...carrito, nuevoCarrito]);
  };

  const editarCarrito = (indice, nuevaInformacion) => {
    setCarrito((prevCarritos) => {
      const nuevosCarritos = [...prevCarritos];
      nuevosCarritos[indice] = { ...nuevosCarritos[indice], ...nuevaInformacion };
      return nuevosCarritos;
    });
  };

  const eliminarCarrito = async (identificador) => {
    try {
      const nuevosCarritos = carrito.filter((elemento) => elemento.index !== identificador);

      // Actualiza AsyncStorage con los nuevos carritos
      await AsyncStorage.setItem('carrito', JSON.stringify(nuevosCarritos));

      // Actualiza el estado local
      setCarrito(nuevosCarritos);
      console.log("carrito de borrar: ", nuevosCarritos);
      console.log("carrito de borrar setcarrito: ", carrito);
      console.log('Elemento del carrito eliminado exitosamente.');
    } catch (error) {
      console.error('Error al intentar eliminar un elemento del carrito:', error);
    }
  };

  const login = async (id, tipoUser, nombreUser, apellidoUser, correoUser, telUser) => {
    setUserId(id);
    setTipo(tipoUser);
    setNombre(nombreUser);
    setApellido(apellidoUser);
    setCorreo(correoUser);
    setTelefono(telUser)

    try {
      await AsyncStorage.setItem('userId', id);
      await AsyncStorage.setItem('tipo', tipoUser);
      await AsyncStorage.setItem('nombre', nombreUser);
      await AsyncStorage.setItem('apellido', apellidoUser);
      await AsyncStorage.setItem('correo', correoUser);
      await AsyncStorage.setItem('telefono', telUser);
    } catch (error) {
      console.error('Error saving data to AsyncStorage in login(): ', error);
    }
  };

  const actualizaUser = async (nombreUser, apellidoUser, telUser) => {

    setNombre(nombreUser);
    setApellido(apellidoUser);
    setTelefono(telUser)

    try {
      await AsyncStorage.setItem('nombre', nombreUser);
      await AsyncStorage.setItem('apellido', apellidoUser);
      await AsyncStorage.setItem('telefono', telUser);
    } catch (error) {
      console.error('Error saving data to AsyncStorage in login(): ', error);
    }
  };

  const logout = async () => {
    setUserId(null);
    setTipo(null);
    try {
      await AsyncStorage.removeItem('userId');
      await AsyncStorage.removeItem('tipo');
    } catch (error) {
      console.error('Error saving data to AsyncStorage f. login( ): ', error);
    }
  };

  // Función para borrar todos los datos del carrito
  const borrarTodoCarrito = async () => {
    try {
      await AsyncStorage.removeItem('carrito');
      console.log('Carrito borrado exitosamente.');
      setCarrito([]);
    } catch (error) {
      console.error('Error al intentar borrar el carrito:', error);
    }
  };

  useEffect(() => {
    AsyncStorage.setItem('carrito', JSON.stringify(carrito));
  }, [carrito]);




  // Llama a la función para obtener el GranTotal


  const totalStripe = (usd) => {

    setTotalContext(usd);
    console.log("Total usd:", totalContext);
  };



  return (
    <UserContext.Provider
      value={{ actualizaUser, userId, nombre, apellido, telefono, correo, tipo, carrito, login, totalContext, totalStripe, logout, agregarAlCarrito, editarCarrito, eliminarCarrito, borrarTodoCarrito }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};
