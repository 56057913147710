import * as React from "react";
import { Box, Heading, VStack, FormControl, Input, Button, Center, HStack, Text, Image } from "native-base";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import fetchPost from "../helper/fetchPost";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { useUser } from "../helper/UserContext";
import { Link } from "react-router-dom";
import URL from "../helper/baseURL";
import Loader from "../components/Loader";

const Login = () => {


  const { login } = useUser();
  const handleLogin = (id_user, tipoUser, nombreUser, apellidoUser, correoUser, telUser) => {
    // Lógica para el inicio de sesión
    login(id_user, tipoUser, nombreUser, apellidoUser, correoUser, telUser);
  };

  const estiloLink = {
    color: '#6568f1',
    fontWeight: 'bold',
    fontSize: '16px', // Tamaño de fuente similar a "sm"
    textDecoration: 'none',
    textAlign: 'center',// Para eliminar el subrayado predeterminado
  };

  const [correo, setCorreo] = useState("");
  const [password, setPassword] = useState("");

  // const [correo, setCorreo] = useState("CONTACTO@mareaproperties.com.mx");
  // const [password, setPassword] = useState("12345qwerty/*");

  const [isLoading, setIsLoading] = useState(false);

  //show/hide pass
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  //para navegar a otras vistas
  const navigate = useNavigate();


  const Login = async () => {
    setIsLoading(true);
    // Primero validar las credenciales
    if (!validarCredenciales()) {
      setIsLoading(false); // Si la validación falla, detener la ejecución
      return;
    }
    const dataLogin = new FormData();
    dataLogin.append("correo", correo.trim());
    dataLogin.append("password", password);
    const url = `${URL.BASE_URL}/login`
    const options = {
      method: 'POST',
      body: dataLogin
    };
    const res = await fetchPost(url, options);
    console.log("respuesta login", res);

    if (res.resultado === false) {
      window.alert(res.mensaje)
      return;

    } else {
      handleLogin(res.user.id_usuario, res.user.TipoUsuario, res.user.nombre, res.user.apellido, res.user.correo, res.user.telefono)
      window.alert(res.mensaje);

      setIsLoading(false);

      if (res.user.TipoUsuario === "0" || "24") {
        navigate("/UserPanel");
      } else if (res.user.TipoUsuario === "24") {
        navigate("/UserPanel");
      } else {
        window.alert("No tienes permisos necesarios para acceder a esta sección");
      }
    }

  }


  // Función para validar el correo electrónico y la contraseña
  const validarCredenciales = () => {
    // Verifica si el correo contiene '@'
    const esCorreoValido = correo.includes('@');
    // Verifica si la contraseña tiene más de 5 caracteres
    const esPasswordValida = password.length > 5;

    if (!esCorreoValido) {
      window.alert('Por favor ingresa un correo electrónico válido.');
      return false;
    }

    if (!esPasswordValida) {
      window.alert('La contraseña debe tener más de 5 caracteres.');
      return false;
    }

    return true;
  };


  return (
    <Center w="100%" mt={[12, 12, 20, 24]}>

      <Image
        source={{
          uri: "https://mareaproperties.com.mx/imagenes/MAREA2.png"
        }}
        alt="Marea properties" resizeMode="contain"
        h={20} w={40} alignSelf={"center"}

      />
      <Box safeArea p="2" py="8" w="90%" maxW="290">
        <Heading size="lg" fontWeight="600" color="coolGray.800" _dark={{
          color: "warmGray.50"
        }}>
          Bienvenido
        </Heading>
        <Heading mt="1" _dark={{
          color: "warmGray.200"
        }} color="coolGray.600" fontWeight="medium" size="xs">
          Inicia sesión para continuar
        </Heading>

        <VStack space={3} mt="5">
          <FormControl>
            <FormControl.Label>Correo electrónico:</FormControl.Label>
            <Input
              placeholder='Email'
              onChangeText={(val) => setCorreo(val.toUpperCase())}
              value={correo} />
          </FormControl>
          <FormControl>
            <FormControl.Label>Contraseña</FormControl.Label>
            <Input type={show ? 'text' : 'password'}
              placeholder='Contraseña'
              onChangeText={(val) => setPassword(val)}
              value={password}
              InputRightElement={
                <Button
                  ml={1}
                  variant='link'
                  roundedLeft={0}
                  roundedRight='md'
                  onPress={handleClick}
                >
                  {show ? (
                    <FaRegEyeSlash />

                  ) : (
                    <FaRegEye />
                  )}
                </Button>} />
            {/* <Link _text={{
              fontSize: "xs",
              fontWeight: "500",
              color: "indigo.500"
            }} alignSelf="flex-end" mt="1">
            ¿Olvidaste tu contraseña?
              </Link> */}
          </FormControl>

          {

            isLoading ?
              <Loader size="lg" color="primary.500" />
              :
              <Button mt="2" colorScheme="indigo"
                onPress={() => Login()}>
                Iniciar sesion
              </Button>
          }




          <HStack mt="6" justifyContent="center" alignContent={"center"}>
            <Text fontSize="sm" mt={-1} color="coolGray.600" _dark={{
              color: "warmGray.200"
            }} >
              ¿Eres nuevo?{" "}
            </Text>
            <Link to="/Registro" style={estiloLink} >
              Registrarse
            </Link>
          </HStack>
        </VStack>
      </Box>
    </Center>
  );
};

export default Login; 