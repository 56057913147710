import React from 'react'
import { Text, View, HStack, FlatList } from 'native-base'
import { FaCheck } from 'react-icons/fa6'
import { turquesa } from '../../helper/colors'
const AmenidadesEscritorio = ({ items, titulo }) => {
  return (
    <View w='100%' my={4}>
      <Text style={{ fontFamily: 'Circular', fontSize: 20, marginBottom: 5 }}>{titulo}</Text>
      <FlatList
        data={items}
        contentContainerStyle={{
          justifyContent: 'flex-start', // Asegura que el contenido comience desde el principio
          alignContent: 'stretch', // Estira los elementos para cubrir el espacio disponible
        }}
        numColumns={2}
        renderItem={({ item, index }) => (
          <HStack
            key={index}
            width={'50%'} // Ajusta el ancho para ocupar exactamente la mitad del contenedor
            alignItems={item.id % 2 === 0 ? 'flex-end' : 'flex-start'}>
            <FaCheck color={turquesa} size='1.5em' />
            <Text
              style={{
                fontFamily: 'Avenir',
                textAlign: 'flex-start',
                color: '#5f5f5f',
                fontSize: 14,
                marginTop: 2,
              }}>
              {item}
            </Text>
          </HStack>
        )}
        keyExtractor={(item, index) => index.toString()}
      />
    </View>
  )
}

export default AmenidadesEscritorio
