import React, { useState, useEffect, useRef } from 'react'
import { Outlet, Link, useNavigate } from 'react-router-dom'
import { HStack, Image, Pressable, Center, Text } from 'native-base'
import { animateScroll as scroll } from 'react-scroll'
import MenuHamburger from './header/MenuHamburger'
import NosotrosMenu from './header/NosotrosMenu'
import { turquesa } from '../helper/colors'
import LenguajeSelector from './header/LenguajeSelector'
import ReactPixel from 'react-facebook-pixel'
import { Helmet } from 'react-helmet'

const Header = () => {
  const headerRef = useRef(null)
  const navigate = useNavigate()

  const handleClickMenu = (vista) => {
    scroll.scrollToTop()
    navigate(vista)
  }

  const [translateWidgetLoaded, setTranslateWidgetLoaded] = useState(false)

  useEffect(() => {
    if (!translateWidgetLoaded) {
      const googleTranslateScript = document.createElement('script')
      googleTranslateScript.type = 'text/javascript'
      googleTranslateScript.async = true
      googleTranslateScript.src =
        '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
      document.body.appendChild(googleTranslateScript)

      window.googleTranslateElementInit = function () {
        new window.google.translate.TranslateElement(
          {
            pageLanguage: 'es',
            includedLanguages: 'en,es,fr',
          },
          'google_translate_element',
        )
      }
    }
  }, [translateWidgetLoaded])

  useEffect(() => {
    ReactPixel.init('377527814659843')
    ReactPixel.pageView()
  }, [])

  const headerStyle = {
    // position: 'fixed',
    top: 0, // Aplicando el estado de paddingTop aquí
    left: 0,
    right: 0,
    zIndex: 99,
  }

  const handlePress = () => {
    window.open('https://app.hoone.io/booking/marea-properties', '_blank')
  }

  const ButtonMenu = ({ link, texto, border }) => {
    const [isHovered, setIsHovered] = useState(false)
    return (
      <Pressable
        // onMouseEnter={() => setIsHovered(true)}
        // onMouseLeave={() => setIsHovered(false)}
        onPress={() => handleClickMenu(link)}
        alignSelf={'center'}
        display={{ base: 'none', lg: 'flex' }}
        p={0.5}
        justifyContent='center'
        flex={1}
        borderWidth={border}>
        <Text
          color={isHovered ? '#75eeff' : '#fff'}
          flex='1'
          fontSize={['2xs', '2xs', '2xs', 'xs', 'sm']}
          textAlign='center'
          py={2}
          style={{ fontFamily: 'Circular' }}>
          {texto}
        </Text>
      </Pressable>
    )
  }

  const [isHoveredContacto, setIsHoveredContacto] = useState(false)

  return (
    <>
      <style>
        {`
                    .goog-te-banner-frame.skiptranslate {
                        display: none !important;
                    }
                `}
      </style>
      <header ref={headerRef} style={headerStyle}>
        <Helmet>
          <meta name='facebook-domain-verification' content='z8he4107jyasvuqi6fgy1nane39f3g' />
        </Helmet>
        <nav>
          <HStack
            // borderColor={['#ff0', '#0f0', '#00f', '#0f0', '#f50']}
            // borderWidth={3}
            bg={turquesa}
            zIndex={1}
            pt={0}
            h={20}
            w='100%'
            alignItems='center'
            justifyContent='center'
            space={4}>
            {/* Botones de Menú a la Izquierda */}
            <HStack justifyContent='flex-start' display={{ base: 'none', lg: 'flex' }} w='35%'>
              {/* <DestinosMenu /> */}
              {/* boton destinos */}
              <Pressable
                accessibilityLabel='More options menu'
                onPress={() => handlePress()}
                alignSelf='center'
                display={{ base: 'none', lg: 'flex' }}
                p={0.5}
                justifyContent='center'
                flex={1}>
                <Text
                  color={'#fff'}
                  fontSize={['2xs', '2xs', '2xs', 'xs', 'sm']}
                  textAlign='center'
                  py={2}
                  style={{ fontFamily: 'Circular' }}>
                  RENTAS VACACIONALES
                </Text>
              </Pressable>
              <ButtonMenu link='/BienesRaices' texto='GESTIÓN DE PROPIEDADES' />
            </HStack>

            {/* Imagen LOGO Centrada */}
            <Center
              bg={turquesa}
              // style={estiloOnda}
              h={16}
              w={[56, 56, 64, 64, 64]}>
              <Link to='/' onClick={() => handleClickMenu('/')}>
                <Image
                  source={{
                    uri: 'https://mareaproperties.com.mx/imagenes/MAREAgold.png',
                  }}
                  alt='Marea Properties'
                  w={[32, 32, 40, 40, 40]}
                  h={16} // Puedes ajustar el tamaño según sea necesario
                  resizeMode='contain'
                />
              </Link>
            </Center>

            {/* Botones de Menú y Contacto a la Derecha */}

            <HStack
              right={3}
              justifyContent='flex-end'
              w={['40%', '35%', '35%', '35%', '35%']}
              space={5}>
              <Pressable
                display={{ base: 'none', md: 'flex' }}
                // onMouseEnter={() => setIsHoveredContacto(true)}
                // onMouseLeave={() => setIsHoveredContacto(false)}
                onPress={() => handleClickMenu('/Contacto')}
                alignSelf={'center'}
                p={0.5}
                justifyContent='center'>
                <Text
                  px={[1, 2, 2, 2, 2]}
                  color={isHoveredContacto ? '#75eeff' : '#fff'}
                  fontSize={['2xs', '2xs', '2xs', 'xs', 'sm']}
                  textAlign='center'
                  py={1}
                  style={{ fontFamily: 'Circular' }}>
                  CONTACTO
                </Text>
              </Pressable>
              <NosotrosMenu />

              {/* <Text
                w={40}
                style={{ fontFamily: 'Circular' }}
                justifyContent='center'
                textAlign='center'
                display={{ base: 'none', xl: 'flex' }}
                px={2}
                color='#fff'
                fontSize={['2xs', 'xs', 'sm', 'md']}
                alignSelf='center'>
                +52 555 555 5555
              </Text>
              <Center display={{ base: 'static', xl: 'none' }}>
                <FaPhone size={20} color={'#fafafa'} />
              </Center> */}

              <Center>
                <LenguajeSelector />
              </Center>

              {/* <Center w={10}>
                                <div id="google_translate_element" style={{ position: 'absolute', right: 0, top: 0 }}></div>
                            </Center> */}

              {/* <Pressable
                onPress={() => (userId ? handleClickMenu('/login') : handleClickMenu('/login'))}
                alignItems='center'
                justifyContent='center'>
                <FaUserAlt size={24} color={'#dadada'} />
              </Pressable> */}

              {/* <MenuUsersLenguaje /> */}
              <Center alignSelf='flex-end'>
                <MenuHamburger />
              </Center>
            </HStack>
          </HStack>
        </nav>
      </header>
      <Outlet />
    </>
  )
}

export default Header
