import React from "react";
import { useUser } from "../../helper/UserContext";
import Login from "../Login";
import EditarInmueble from "../../components/admin/EditarInmueble";
import { useParams } from "react-router-dom";

const AdminEditCrud = () => {

    const { tipo, userId } = useUser();
    const { id, nombre } = useParams();
    console.log("id admin crud: ", id);
    console.log("nombre admin crud: ", nombre);

    return (
        <>
            {userId !== null && (
                tipo === "24" ? (
                    <EditarInmueble id={id} nombre={nombre} />
                ) : (
                    <Login />
                )
            )}
        </>
    );
}

export default AdminEditCrud; 