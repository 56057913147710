import React, { useEffect, useState } from "react";
import { View, Text, FormControl, Heading, useToast, Button, Icon, Input, Box, TextArea, Image, Select, Spinner } from "native-base";
import MapComponent from "./MapComponent";
import fetchPost from "../../helper/fetchPost";
import URL from "../../helper/baseURL";

const EditarInmueble = ({ id, nombre }) => {


    const toast = useToast();
    const [googleMapLink, setGoogleMapLink] = useState("");
    const BASE_URL = URL.BASE_URL;

    const handleMapLinkChange = (newLink) => {
        setGoogleMapLink(newLink);
    };

    const DetalleInmueble = async (id) => {
        setIsLoading2(true);
        // Primero validar las credenciales

        const data = new FormData();
        data.append("id", id);

        const url = `${URL.BASE_URL}/detalleInmueble`
        const options = {
            method: 'POST',
            body: data
        };
        const res = await fetchPost(url, options);
        console.log("respuesta DETALLE", res);
        console.log("respuesta DETALLE2", res.inmueble.ID);
        setTitulo(res.inmueble.nombre);
        setDescripcion(res.inmueble.descripcion);
        setPrecio(res.inmueble.precio);
        setUbicacion(res.inmueble.ubicacion);
        setServicios(res.inmueble.servicios);
        setGoogleMapLink(res.inmueble.mapa);
        setCategoria(res.inmueble.categoria);
        setFotoPortada(res.inmueble.fotoportada);
        setCategoria(res.inmueble.categoria);
        setIsLoading2(false);

    }

    useEffect(() => {
        DetalleInmueble(id);
    }, [id])


    // Aquí iría la lógica para manejar los estados y la subida de imágenes
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading2, setIsLoading2] = useState(true);
    const [titulo, setTitulo] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [servicios, setServicios] = useState('');
    const [ubicacion, setUbicacion] = useState('');
    const [precio, setPrecio] = useState('');
    const [categoria, setCategoria] = useState('');
    const [imagen, setImagen] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [fotoPortada, setFotoPortada] = useState(null);



    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setImagen(file);

            // Crear una URL para la previsualización
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };


    const EditarInmueble = async () => {
        if (!validarCampos()) {
            return;
        }

        setIsLoading(true);

        const data = new FormData();
        data.append("id", id);
        data.append("nombre", titulo);
        data.append("ubicacion", ubicacion);
        data.append("descripcion", descripcion);
        data.append("servicios", servicios);
        data.append("mapa", googleMapLink);
        data.append("precio", precio);
        data.append("categoria", categoria);
        if (imagen) {
            data.append("fotoportada", imagen);
        }

        // ... Agregar otras imágenes y campos si es necesario

        const url = `${URL.BASE_URL}/editarInmueble`; // Asegúrate de que la URL sea correcta
        const options = {
            method: 'POST',
            body: data
        };
        const res = await fetchPost(url, options);
        console.log("respuesta", res);
        if (res.resultado) {
            toast.show({
                duration: 10000,
                render: () => {
                    return <Box bg="success.500" px="3" py="5" rounded="sm" mb={5} fontSize={"lg"}>
                        Se editó el inmueble con éxito.
                    </Box>;
                }
            });
        } else {
            toast.show({
                duration: 10000,
                render: () => {
                    return <Box bg="danger.500" px="3" py="5" rounded="sm" mb={5} fontSize={"lg"}>
                        Hubo un error, intentalo más tarde.
                    </Box>;
                }
            });
        }
        setIsLoading(false);
    };

    const validarCampos = () => {
        let camposFaltantes = [];
        if (!titulo) camposFaltantes.push("título");
        if (!descripcion) camposFaltantes.push("descripción");
        if (!ubicacion) camposFaltantes.push("ubicación");
        if (!precio) camposFaltantes.push("precio");
        if (!categoria) camposFaltantes.push("categoría");
        // if (!imagen) camposFaltantes.push("imagen");

        if (camposFaltantes.length > 0) {
            let mensaje = "Por favor, completa los siguientes campos requeridos: " + camposFaltantes.join(", ") + ".";
            toast.show({
                title: "Campos Faltantes",
                description: mensaje,
                status: "error",
                duration: 4000,
                isClosable: true,
            });
            return false;
        }

        toast.show({
            title: "Éxito",
            description: "Todos los campos están completos.",
            status: "success",
            duration: 2000,
            isClosable: true,
        });
        return true;
    };



    console.log("props id: ", id);
    console.log("props id: ", nombre);



    return (


        <View alignContent={"center"} mt={[12, 12, 20, 24]} w="100%">
            <Text>
                Hola nombre: {nombre} {"\n"}
                id : {id}
            </Text>

            <Heading>
                CRUD de Inmuebles:
            </Heading>

            {isLoading2 ? <Spinner size="xl" p={10} /> :
                <>
                    <FormControl isRequired>
                        <FormControl.Label>Titulo</FormControl.Label>
                        <Input placeholder="Titulo del inmueble"
                            onChangeText={(val) => setTitulo(val)}
                            value={titulo} />
                    </FormControl>

                    <FormControl isRequired>
                        <FormControl.Label>Descripción</FormControl.Label>
                        <TextArea placeholder="Descripción del inmueble"
                            onChangeText={(val) => setDescripcion(val)}
                            value={descripcion} />
                    </FormControl>

                    <FormControl isRequired>
                        <FormControl.Label>Servicios:</FormControl.Label>
                        <TextArea placeholder="Descripción del inmueble"
                            onChangeText={(val) => setServicios(val)}
                            value={servicios} />
                    </FormControl>

                    <FormControl isRequired>
                        <FormControl.Label>Ubicación</FormControl.Label>
                        <TextArea placeholder="Ubicación del inmueble"
                            onChangeText={(val) => setUbicacion(val)}
                            value={ubicacion} />
                    </FormControl>

                    <FormControl isRequired>
                        <FormControl.Label>Selecciona la ubicación en el mapa: </FormControl.Label>
                        <div>
                            <MapComponent onMapLinkChange={handleMapLinkChange} />
                            <p>Enlace de Google Maps: {googleMapLink}</p>
                            {/* Otros componentes o contenido aquí */}
                        </div>
                    </FormControl>



                    <FormControl>

                        {fotoPortada && imagen === null && (
                            <>
                                <FormControl.Label>Foto Portada</FormControl.Label>
                                <Image
                                    source={{
                                        uri: `${BASE_URL}uploads/${fotoPortada}`
                                    }}
                                    alt="Previsualización de la imagen"
                                    size="2xl" // Puedes ajustar el tamaño según tus necesidades
                                />
                            </>
                        )}
                        <input
                            type="file"
                            onChange={handleImageChange}
                            style={{ display: 'none' }}
                            id="file-input"
                            accept="image/*" // Asegúrate de aceptar solo imágenes
                        />

                        {previewUrl && (
                            <>
                                <FormControl.Label>Foto Portada: </FormControl.Label>
                                <Image
                                    source={{ uri: previewUrl }}
                                    alt="Previsualización de la imagen"
                                    size="2xl" // Puedes ajustar el tamaño según tus necesidades
                                />
                            </>
                        )}
                        <label htmlFor="file-input">
                            <Button as="span" leftIcon={<Icon name="upload" />}>
                                Seleccionar Foto
                            </Button>
                        </label>
                    </FormControl>

                    {/* Repetir para las demás fotos */}

                    <FormControl isRequired>
                        <FormControl.Label>Precio</FormControl.Label>
                        <Input placeholder="Precio del inmueble" keyboardType="numeric"
                            onChangeText={(val) => setPrecio(val)}
                            value={precio} />
                    </FormControl>

                    <FormControl isRequired>
                        <FormControl.Label>Categoría</FormControl.Label>
                        <Select placeholder="Selecciona una categoría" defaultValue={categoria} onValueChange={itemValue => setCategoria(itemValue)}>
                            <Select.Item label="Frente a la playa" value="1" />
                            <Select.Item label="Habitaciones" value="2" />
                            <Select.Item label="Casas particulares" value="3" />
                            <Select.Item label="Albercas increibles" value="4" />
                            <Select.Item label="Adaptados" value="5" />
                        </Select>
                    </FormControl>



                    {isLoading
                        ?
                        <Spinner size="lg" my={5} />
                        :
                        <Button mt="5" colorScheme="blue" onPress={() => EditarInmueble()}>
                            Guardar Inmueble
                        </Button>
                    }

                </>
            }




        </View>
    );
}
export default EditarInmueble;