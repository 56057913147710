import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'
import NoPage from './pages/NoPage'
import Home from './pages/Home'
import Footer from './components/Footer'
import Propiedades from './pages/Propiedades'
import global_en from './locates/en/global.json'
import global_es from './locates/es/global.json'
import './assets/fonts/fonts.css'
import About from './pages/About'
import Privacidad from './pages/Privacidad'
import TerminosCondiciones from './pages/TerminosCondiciones'
import PropertyManagement from './pages/PropertyManagement'
import Destinos from './pages/Destinos'
import Contacto from './pages/Contacto'
import DetalleInmueble from './pages/DetalleInmueble'
import Unete from './pages/Nosotros/Unete'
import Faqs from './pages/Nosotros/Faqs'
import Cancelaciones from './pages/Nosotros/Cancelaciones'
import Test from './pages/Test'
import Header from './components/Header'
import Login from './pages/Login'
import Registro from './pages/Registro'
import UserPermiso from './pages/private/UserPermiso'

import Carrito from './pages/Carrito'
import UserHistory from './pages/private/UserHistory'
import UserInfo from './pages/private/UserInfo'
import AdminUsersAll from './pages/private/AdminUsersAll'
import AdminVentas from './pages/private/AdminVentas'
import AdminCrud from './pages/private/AdminCrud'
import AdminInmuebles from './pages/private/AdminInmuebles'
import Favoritos from './pages/Favoritos'
import AdminEditCrud from './pages/private/AdminEditCrud'
import PlayaCarmen from './pages/PlayaCarmen'
import Tulum from './pages/Tulum'

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'auto',
  fallbackLng: 'es',
  resources: {
    en: {
      global: global_en,
    },
    es: {
      global: global_es,
    },
  },
})

function App() {
  return (
    <I18nextProvider i18n={i18next}>
      <Router>
        <Routes>
          <Route path='/' element={<Header />}>
            <Route index element={<Home />} />
            <Route path='/Destinos' element={<Destinos />} />
            <Route path='/Propiedades' element={<Propiedades />} />
            <Route path='/BienesRaices' element={<PropertyManagement />} />
            <Route path='/Contacto' element={<Contacto />} />
            <Route path='*' element={<NoPage />} />
            <Route path='/Privacidad' element={<Privacidad />} />
            <Route path='/Terminos' element={<TerminosCondiciones />} />
            <Route path='/Nosotros' element={<About />} />
            <Route path='/Unete' element={<Unete />} />
            <Route path='/Faqs' element={<Faqs />} />
            <Route path='/Cancelaciones' element={<Cancelaciones />} />
            <Route path='/detalle/:id' element={<DetalleInmueble />} />
            <Route path='/test' element={<Test />} />
            <Route path='/login' element={<Login />} />
            <Route path='/Registro' element={<Registro />} />
            <Route path='/UserPanel' element={<UserPermiso />} />
            <Route path='/Carrito' element={<Carrito />} />
            <Route path='/Favoritos' element={<Favoritos />} />

            <Route path='/PlayaCarmen' element={<PlayaCarmen />} />
            <Route path='/Tulum' element={<Tulum />} />

            <Route path='/UserPanel' element={<UserPermiso />} />
            <Route path='/UserInfo' element={<UserInfo />} />
            <Route path='/UserHistory' element={<UserHistory />} />
            <Route path='/VerUsuarios' element={<AdminUsersAll />} />
            <Route path='/VerVentas' element={<AdminVentas />} />
            <Route path='/AdminAgregarInmueble' element={<AdminCrud />} />
            <Route path='/AdminVerInmuebles' element={<AdminInmuebles />} />
            <Route path='/AdminEditarInmueble/:id/:nombre/' element={<AdminEditCrud />} />
          </Route>
        </Routes>
        <Footer />
      </Router>
    </I18nextProvider>
  )
}

export default App
