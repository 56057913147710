import { Text, View, Stack, VStack, Pressable } from "native-base";
import { useUser } from "../../helper/UserContext";
import { useNavigate } from "react-router";
import { PiUserList, PiShoppingCartBold } from "react-icons/pi";
import { MdOutlineWorkHistory } from "react-icons/md";
import { IconContext } from "react-icons";
import LogoutConfirmationPopover from "../../components/LogoutConfirmationPopover";
import { BsHouseHeart } from "react-icons/bs";

const UserPanel = () => {
    const { logout, nombre, apellido, correo } = useUser();

    const navigate = useNavigate();

    const handleSalir = () => {
        // Lógica para el inicio de sesión
        logout();
        navigate("/");
    };



    const OpcionUser = ({ funcion, icon: IconComponent, titulo, texto }) => {

        return (
            <Pressable onPress={funcion}>
                <VStack py={3} px={6} shadow={6} borderRadius={20} borderColor="muted.300" borderWidth={2}>

                    <IconContext.Provider value={{ size: "3em" }}>
                        <IconComponent />
                    </IconContext.Provider>

                    <Text bold>{titulo}</Text>
                    <Text>{texto}</Text>
                </VStack>
            </Pressable>

        );

    }

    const UserNav = (nav) => {
        navigate(nav);
    }

    return (
        <View mt={24}>
            <Text bold fontSize={"2xl"} textAlign="center">
                BIENVENIDO
            </Text>

            <Text bold fontSize={"2xl"} textAlign="justify" px={10}>
                Cuenta
            </Text>

            <Text bold fontSize={"lg"} px={10}>
                {nombre} {apellido}.
            </Text>
            <Text fontWeight={"semibold"} fontSize={"md"} px={10}>
                {correo}
            </Text>


            <Stack direction={["column", "row"]} space={4} p={2}>
                <OpcionUser
                    titulo="Información personal"
                    texto="Visualiza y edita tus datos personales"
                    funcion={() => UserNav("/UserInfo")}
                    icon={PiUserList} />
                <OpcionUser
                    titulo="Carrito de compras"
                    texto="Continua con tu compra"
                    funcion={() => UserNav("/Carrito")}
                    icon={PiShoppingCartBold} />
                <OpcionUser
                    titulo="Favoritos"
                    texto="Visualiza tus favoritos"
                    funcion={() => UserNav("/Favoritos")}
                    icon={BsHouseHeart} />

                <OpcionUser
                    titulo="Historial de compras"
                    texto="Revisa el historial de tus compras"
                    funcion={() => UserNav("/UserHistory")}
                    icon={MdOutlineWorkHistory} />


            </Stack>

            <LogoutConfirmationPopover onConfirm={handleSalir} />

        </View>
    );

}
export default UserPanel;