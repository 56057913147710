import React from 'react'
import Slider from 'react-slick'
import { Stack, Pressable, View, Text, Center, Image, HStack, VStack } from 'native-base'

const AliadosSwiper = () => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <div className='slider-container'>
      <Slider {...settings}>
        <Image
          source={{
            uri: 'https://luvtulum.com/wp-content/uploads/2018/02/LuvTulum_Letters_Logo_dark3.png',
          }}
          alt='Alternate Text'
          alignSelf={'center'}
          w={{ base: '60%', lg: '30%' }}
          mt={6}
          h={24}
          resizeMode='center'
        />
        <Image
          source={{
            uri: 'https://mareaproperties.com.mx/imagenes/anderson.png',
          }}
          alt='Alternate Text'
          alignSelf={'center'}
          w={{ base: '60%', lg: '30%' }}
          mt={6}
          h={24}
          resizeMode='center'
        />
        <Image
          source={{
            uri: 'https://mareaproperties.com.mx/imagenes/logoaliado.png',
          }}
          alt='Alternate Text'
          alignSelf={'center'}
          w={{ base: '60%', lg: '30%' }}
          mt={6}
          h={24}
          resizeMode='center'
        />
        <Image
          source={{
            uri: 'https://static.wixstatic.com/media/7efc9e_ebe0bd51c4e9455eb3e482312e343884~mv2.png/v1/fit/w_320,h_320,q_90/7efc9e_ebe0bd51c4e9455eb3e482312e343884~mv2.png',
          }}
          alt='Alternate Text'
          alignSelf={'center'}
          w={{ base: '80%', lg: '30%' }}
          h={32}
          resizeMode='center'
        />
        <Image
          source={{
            uri: 'https://tulumtables.com/images/clubs/logo/balabamba-tulum.png',
          }}
          alt='Alternate Text'
          alignSelf={'center'}
          w={{ base: '80%', lg: '30%' }}
          h={32}
          resizeMode='center'
        />
        <Image
          source={{
            uri: 'https://www.siteminder.com/wp-content/uploads/2019/03/ahau-tulum-1.jpg',
          }}
          alt='Alternate Text'
          alignSelf={'center'}
          w={{ base: '80%', lg: '30%' }}
          h={32}
          resizeMode='center'
        />
      </Slider>
    </div>
  )
}

export default AliadosSwiper
