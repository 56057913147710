import { View, Image, VStack, Text, HStack, Box, Stack } from 'native-base'
import NosotrosSwiper from '../components/Swipers/NosotrosSwiper'

const About = () => {
  const Valores = ({ valor, texto }) => {
    return (
      <VStack w='80%' alignSelf='center'>
        <Text style={{ fontFamily: 'Circular' }} fontSize={['md', 'md', 'md', 'lg', 'lg']}>
          {valor}
        </Text>
        <Text
          fontSize={['sm', 'sm', 'sm', 'md', 'md']}
          style={{ fontFamily: 'Avenir' }}
          color='muted.700'>
          {texto}
        </Text>
      </VStack>
    )
  }

  const ImagenDeFondo = {
    backgroundImage: 'url("https://mareaproperties.com.mx/imagenes/img294.jpg")', // Reemplaza 'ruta-de-tu-imagen.jpg' con la ubicación de tu imagen
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
  }

  return (
    <View w='100%' bg='#dadada'>
      <View style={ImagenDeFondo} w='100%' h={96}>
        <Text
          style={{ fontFamily: 'Circular', textShadow: '2px 2px 4px rgba(0, 0, 0, 1)' }}
          bold
          fontSize={'6xl'}
          mx={10}
          color='#fff'
          alignSelf='center'
          mt={{ base: 16, lg: 40 }}
          letterSpacing={2}
          textAlign='center'>
          SOBRE NOSOTROS
        </Text>
      </View>

      <Box w='70%' alignSelf='center'>
        <NosotrosSwiper />
      </Box>

      <Stack
        w='100%'
        mt={10}
        direction={['column', 'column', 'row', 'row', 'row']}
        justifyContent={'center'}
        alignSelf={'center'}
        alignItems={'center'}>
        <VStack
          w={['90%', '90%', '50%', '50%', '50%']}
          py={3}
          mb={10}
          alignSelf='center'
          bg='#fff'
          shadow={8}>
          <Text style={{ fontFamily: 'Circular' }} fontSize='2xl' alignSelf='center'>
            Valores
          </Text>
          <Valores
            valor='Transparencia'
            texto='Operamos con claridad y apertura, asegurando una comunicación honesta con nuestros clientes y socios.'
          />
          <Valores
            valor='Integridad'
            texto='Mantenemos los más altos estándares éticos en todas nuestras operaciones, fomentando la confianza y el respeto.'
          />
          <Valores
            valor='Excelencia'
            texto='Nos esforzamos por superar las expectativas, ofreciendo experiencias vacacionales inolvidables y una gestión de propiedades impecable.'
          />
          <Valores
            valor='Compromiso'
            texto='Estamos dedicados a cumplir nuestras promesas, trabajando incansablemente para alcanzar nuestros objetivos comunes.'
          />
          <Valores
            valor='Profesionalismo'
            texto='Actuamos con maestría y competencia, asegurando un servicio de la más alta calidad en cada interacción.'
          />
          <Valores
            valor='Colaboración'
            texto='Fomentamos el trabajo en equipo y la cooperación, creyendo que juntos podemos lograr más.'
          />
        </VStack>
        <Image
          source={{
            uri: 'https://mareaproperties.com.mx/imagenes/banner01.jpg',
          }}
          alt='Alternate Text'
          w={['99%', '99%', '50%', '50%', '50%']}
          h={'450px'}
          alignSelf='center'
          resizeMode='contain'
          borderRadius={10}
          shadow={7}
        />
      </Stack>

      {/* <Stack direction={['column', 'column', 'row', 'row']} w='100%' my={5}>
        <VStack
          w={['90%', '90%', '45%', '45%', '45%']}
          py={3}
          mb={10}
          alignSelf='center'
          bg='#fff'
          mx='auto'
          shadow={8}>
          <Valores
            valor='Compromiso'
            texto='Estamos dedicados a cumplir nuestras promesas, trabajando incansablemente para alcanzar nuestros objetivos comunes.'
          />
          <Valores
            valor='Profesionalismo'
            texto='Actuamos con maestría y competencia, asegurando un servicio de la más alta calidad en cada interacción.'
          />
          <Valores
            valor='Colaboración'
            texto='Fomentamos el trabajo en equipo y la cooperación, creyendo que juntos podemos lograr más.'
          />
        </VStack>

        <Image
          source={{
            uri: 'https://mareaproperties.com.mx/imagenes/img97.jpg',
          }}
          alt='Alternate Text'
          w={['90%', '90%', '45%', '45%', '45%']}
          h={96}
        />
      </Stack> */}

      <HStack w='90%' flex={1} alignSelf='center' justifyContent='space-between' mx={20}>
        <Image
          source={{
            uri: 'https://mareaproperties.com.mx/imagenes/airbnb.png',
          }}
          alt='Alternate Text'
          w='30%'
          h={32}
          resizeMode='center'
        />
        <Image
          source={{
            uri: 'https://mareaproperties.com.mx/imagenes/booking.png',
          }}
          alt='Alternate Text'
          w='30%'
          h={32}
          resizeMode='center'
        />
        <Image
          source={{
            uri: 'https://mareaproperties.com.mx/imagenes/expedia.png',
          }}
          alt='Alternate Text'
          w='30%'
          h={32}
          resizeMode='center'
        />
      </HStack>
    </View>
  )
}
export default About
