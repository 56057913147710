import React from "react";
import { View } from "native-base";
import FlatListInmuebles from "../components/FlatListInmuebles";


const Propiedades = () => {

    return (
        <View alignContent={"center"} pt={20} bg="#dadada" w="100%">
            <FlatListInmuebles />

        </View>
    )
}
export default Propiedades;