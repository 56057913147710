import React from 'react'
import { View, Box, Text, HStack, VStack, Stack, Pressable } from 'native-base'
import { FaPhone, FaRegEnvelope, FaLocationDot } from 'react-icons/fa6'
import ContactoSimple from '../components/ContactoSimple'
import MapaGoogle from '../components/detalleInmueble/MapaGoogle'

const Contacto = () => {
  const ImagenDeFondo = {
    backgroundImage: 'url("https://mareaproperties.com.mx/imagenes/img188.jpg")', // Reemplaza 'ruta-de-tu-imagen.jpg' con la ubicación de tu imagen
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
  }

  return (
    <View w='100%'>
      <View style={ImagenDeFondo} w='100%' h={96}>
        <Text
          style={{ fontFamily: 'Circular', textShadow: '2px 2px 4px rgba(0, 0, 0, 1)' }}
          bold
          fontSize={['6xl']}
          mx={10}
          color='#fff'
          alignSelf='center'
          mt={40}
          letterSpacing={2}
          textAlign='center'>
          CONTACTO
        </Text>
      </View>

      <Stack direction={['column', 'column', 'column', 'column', 'row']} w='100%'>
        <VStack
          w={['100%', '100%', '100%', '100%', '50%']}
          alignSelf={['center', 'center', 'center', 'center', 'justify']}
          flex={1}
          py={12}>
          <Text textAlign='center' style={{ fontFamily: 'Circular' }} fontSize='4xl'>
            ÚNETE A NUESTRO ECOSISTEMA
          </Text>

          <VStack pt={12} space={8} alignItems='center' justifyContent='center'>
            <HStack
              space={2}
              w='100%'
              alignSelf='center'
              alignItems='center'
              justifyContent='center'>
              <FaLocationDot />
              <VStack alignSelf='center'>
                <Text
                  fontFamily='Circular'
                  fontSize={['lg', 'lg', 'xl', '2xl', '2xl']}
                  textAlign={'center'}>
                  Marea Properties
                </Text>
                <Text fontFamily='Avenir' fontSize={['md', 'md', 'lg', 'xl', '2xl']}>
                  Av. Sayil 1, Plaza Azuna, Piso 11 Oficina 1101
                </Text>
                <Text fontFamily='Avenir' fontSize={['md', 'md', 'lg', 'xl', '2xl']}>
                  Zona Hotelera, C.P 77500, Cancún, Q.R.
                </Text>

                <MapaGoogle linkMapa='https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d232.56881227759305!2d-86.81929651949653!3d21.148378636616957!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses-419!2smx!4v1713220578583!5m2!1ses-419!2smx' />
              </VStack>
            </HStack>

            <VStack w='90%' alignItems='center' justifyContent='center'>
              <Pressable>
                <HStack space={2} alignItems='center'>
                  <FaPhone />
                  <Text fontFamily='Avenir' fontSize={['md', 'md', 'lg', 'xl', '2xl']}>
                    998 713 3458
                  </Text>
                </HStack>
              </Pressable>

              <Pressable py={2}>
                <HStack space={2} fontSize={['md', 'md', 'lg', 'xl', 'xl']} alignItems='center'>
                  <FaRegEnvelope />
                  <Text fontFamily='Avenir' fontSize={['md', 'md', 'lg', 'xl', '2xl']}>
                    contacto@mareaproperties.com.mx
                  </Text>
                </HStack>
              </Pressable>
            </VStack>
          </VStack>
        </VStack>

        <Box bg='muted.300' w={['100%', '100%', '100%', '100%', '50%']} py={5} px={5}>
          <ContactoSimple />
        </Box>
      </Stack>
    </View>
  )
}

export default Contacto
