import { Stack, Text, View, Center, Pressable, Divider } from 'native-base'
import React from 'react'
import { FaRegCircleCheck } from 'react-icons/fa6'
import { azulclaro, turquesa } from '../helper/colors'

const VacacionComponent = () => {
  const Locacion = ({ imagen, texto, titulo }) => {
    return (
      <Pressable
        flex={1}
        bg='#fff'
        shadow={3}
        px={8}
        _hover={{
          shadow: 9,
          pb: 4,
          mt: -4,
        }}>
        <Stack flex={1} pb={4}>
          <Center mt={5}>
            <FaRegCircleCheck size={56} color={azulclaro} />
          </Center>
          <Text
            textAlign='center'
            mt={3}
            style={{ fontFamily: 'Circular' }}
            fontSize={['lg', 'lg', 'lg', '2xl', '2xl']}>
            {titulo}
          </Text>
          <Text
            textAlign='center'
            mt={3}
            color='#685f5f'
            style={{ fontFamily: 'Avenir' }}
            fontSize={['md', 'md', 'md', 'lg', 'lg']}>
            {texto}
          </Text>
        </Stack>
      </Pressable>
    )
  }

  return (
    <View w='100%' flex={1} my={4}>
      <Divider />
      <Text
        fontSize={['2xl', '2xl', '4xl', '4xl', '4xl']}
        fontWeight='bold'
        textAlign='center'
        my={8}
        color='coolGray.800'
        style={{ fontFamily: 'Circular' }}>
        HAZ DE TUS VACACIONES UNA EXPERIENCIA INCREÍBLE
      </Text>

      <Stack
        w={['75%', '75%', '99%', '95%', '95%']}
        direction={['column', 'column', 'row', 'row', 'row']}
        mx={'auto'}
        alignSelf='center'
        space={[9, 9, 3, 9, 9]}
        alignContent='center'
        justifyContent='center'>
        <Locacion
          imagen='https://wallpaperaccess.com/full/317501.jpg'
          texto='Experimente la maravilla de la naturaleza, explore los impresionantes cenotes y los antiguos sitios arqueológicos. Nuestras ubicaciones están a solo minutos de los destinos y actividades más interesantes. ¡Prepárate para crear recuerdos inolvidables!'
          titulo='Ubicaciones excepcionales'
        />
        <Locacion
          imagen='https://wallpaperaccess.com/full/317501.jpg'
          texto='Nuestras unidades totalmente equipadas están diseñadas para satisfacer todas sus necesidades y superar sus expectativas. Cada propiedad cuenta con un diseño impresionante que captura la esencia del área local. ¡Confíe en nosotros para brindarle una estadía excepcional que no olvidará!'
          titulo='Propiedades increíbles'
        />
        <Locacion
          imagen='https://wallpaperaccess.com/full/317501.jpg'
          texto='Nuestro equipo profesional está disponible las 24 horas para brindarle el apoyo que necesita. ¡Estamos comprometidos a adaptar nuestros servicios para satisfacer sus necesidades únicas y garantizar que tenga una experiencia agradable y fluida!'
          titulo='Servicio personalizado'
        />
      </Stack>
    </View>
  )
}

export default VacacionComponent
