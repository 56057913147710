import React from 'react';
import { Alert, VStack, HStack, Text } from 'native-base';

const AlertComponent = ({ title, text, color }) => {
    return (
        <Alert w="100%" status={color}>
            <VStack space={2} flexShrink={1} w="100%">
                <HStack flexShrink={1} space={2} justifyContent="space-between">
                    <HStack space={2} flexShrink={1}>
                        <Alert.Icon />
                        <Text fontSize="md" fontWeight="medium" color="coolGray.800">
                            {title}
                        </Text>
                    </HStack>

                </HStack>
                <Text color="coolGray.600">
                    {text}
                </Text>
            </VStack>
        </Alert>
    );
};

export default AlertComponent;
