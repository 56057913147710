import React from 'react'
import { Text, View, Image, VStack, Button, Stack } from 'native-base'
import { useNavigate } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll'

const Unete = () => {
  const ImagenDeFondo = {
    backgroundImage: 'url("https://mareaproperties.com.mx/imagenes/retrato-parte.jpg")', // Reemplaza 'ruta-de-tu-imagen.jpg' con la ubicación de tu imagen
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
  }
  const navigate = useNavigate()

  const handleClickMenu = (vista) => {
    scroll.scrollToTop()
    navigate(vista)
  }

  return (
    <View w='100%' bg='#dadada'>
      <View style={ImagenDeFondo} w='100%' h={96}>
        <Text
          style={{ fontFamily: 'Circular', textShadow: '2px 2px 4px rgba(0, 0, 0, 1)' }}
          bold
          fontSize={['6xl']}
          mx={10}
          color='#fff'
          alignSelf='center'
          mt={{ base: 16, lg: 40 }}
          letterSpacing={2}
          textAlign='center'>
          ÚNETE A NUESTRO EQUIPO
        </Text>
      </View>

      <Stack direction={{ base: 'column', lg: 'row' }} w='100%' my={7} flex={1}>
        <VStack flex={1} mx={6} mt={2} p={3} mb={3} alignSelf='center' bg='#fff' shadow={8}>
          <Text style={{ fontFamily: 'Circular' }} fontSize='xl' mb={2}>
            ÚNETE A NUESTRO EQUIPO
          </Text>

          <Text style={{ fontFamily: 'Avenir' }} fontSize='md' textAlign='justify'>
            En Marea Properties nos dedicamos a proporcionar la mejor experiencia para los huéspedes
            que alquilan propiedades vacacionales a corto plazo y a ofrecer a nuestros propietarios
            los mejores rendimientos en la industria inmobiliaria. Para lograr esto, contamos con el
            equipo más calificado del mercado en cada área de la empresa, quienes son responsables
            de deleitar a nuestros clientes y proporcionarles una experiencia extraordinaria.
            Estamos constantemente en busca del mejor talento humano para cumplir con el compromiso
            de Marea Properties, con la experiencia, conocimientos, habilidades y atributos
            personales que marcan la diferencia en la industria. Marea Properties te ofrece un
            universo de posibilidades y crecimiento profesional para que puedas continuar tu carrera
            en una empresa con un futuro extraordinario. Descubre hoy las oportunidades
            profesionales que tenemos para ti.
          </Text>

          <Button
            onPress={() => handleClickMenu('/Contacto')}
            w={80}
            mt={3}
            shadow={7}
            alignSelf={'center'}>
            <Text letterSpacing={2} style={{ fontFamily: 'Circular' }} fontSize='xl' color='#fff'>
              CONTÁCTANOS
            </Text>
          </Button>
        </VStack>

        <Image
          source={{
            uri: 'https://mareaproperties.com.mx/imagenes/img97.jpg',
          }}
          alt='Alternate Text'
          w='45%'
          h={96}
          display={{ base: 'none', lg: 'flex' }}
        />
      </Stack>
    </View>
  )
}
export default Unete
