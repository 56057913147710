import React, { useState } from 'react'
import { Menu, Pressable, Text, HamburgerIcon, VStack, HStack } from 'native-base'
import { useNavigate } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll'
import { FaCaretDown } from 'react-icons/fa'

const MenuHamburger = () => {
  const navigate = useNavigate()
  const handleClickMenu = (vista) => {
    scroll.scrollToTop()
    navigate(vista)
  }

  const [menuNosotros, setMenuNosotros] = useState(false)
  const handlePress = () => {
    window.open('https://app.hoone.io/booking/marea-properties', '_blank')
  }

  return (
    <Menu
      borderWidth={1}
      zIndex={9999}
      shadow={6}
      mt={4}
      right={3}
      borderColor='muted.400'
      display={{ base: 'flex', lg: 'none' }}
      trigger={(triggerProps) => {
        return (
          <Pressable
            display={{ base: 'block', lg: 'none' }}
            alignSelf={'center'}
            justifyContent='center'
            p={2}
            borderRadius={10}
            accessibilityLabel='More options menu'
            {...triggerProps}>
            <HamburgerIcon color={'#fff'} size={7} />
          </Pressable>
        )
      }}>
      <Menu.Item>
        <HStack alignItems='center'>
          <Pressable onPress={() => handlePress()}>
            <Text fontFamily='Circular' fontSize={'xs'}>
              Rentas Vacacionales
            </Text>
          </Pressable>
          {/* <Pressable onPress={() => setMenuViaje(!menuViaje)}
                        p={3}>
                        <FaCaretDown />
                    </Pressable> */}
        </HStack>
      </Menu.Item>

      <Menu.Item onPress={() => handleClickMenu('/BienesRaices')}>
        <Text fontFamily='Circular' fontSize={'xs'}>
          Gestión de propiedades
        </Text>
      </Menu.Item>

      <Menu.Item>
        <HStack alignItems='center' justifyContent='space-between'>
          <Pressable onPress={() => handleClickMenu('Nosotros')}>
            <Text fontSize={'xs'} fontFamily='Circular'>
              Sobre Nosotros
            </Text>
          </Pressable>
          <Pressable onPress={() => setMenuNosotros(!menuNosotros)} p={3}>
            <FaCaretDown />
          </Pressable>
        </HStack>
      </Menu.Item>
      {menuNosotros ? (
        <VStack ml={5}>
          <Menu.Item onPress={() => handleClickMenu('/Unete')}>
            <Text style={{ fontFamily: 'Avenir' }} fontSize={['2xs', '2xs', '2xs', 'xs', 'sm']}>
              ÚNETE A NUESTRO EQUIPO
            </Text>
          </Menu.Item>
          <Menu.Item onPress={() => handleClickMenu('/Faqs')}>
            <Text style={{ fontFamily: 'Avenir' }} fontSize={['2xs', '2xs', '2xs', 'xs', 'sm']}>
              PREGUNTAS FRECUENTES
            </Text>
          </Menu.Item>
          <Menu.Item onPress={() => handleClickMenu('/Cancelaciones')}>
            <Text fontSize={['2xs', '2xs', '2xs', 'xs', 'sm']} style={{ fontFamily: 'Avenir' }}>
              CANCELACIONES Y REEMBOLSOS
            </Text>
          </Menu.Item>
        </VStack>
      ) : null}

      <Menu.Item onPress={() => handleClickMenu('Contacto')}>
        <Text fontFamily='Circular' fontSize={'xs'}>
          Contacto
        </Text>
      </Menu.Item>
    </Menu>
  )
}
export default MenuHamburger
