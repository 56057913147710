import React, { useState, useEffect } from 'react';
import { Text, View } from 'native-base';
import { useUser } from '../helper/UserContext';

const Carrito = () => {

    const { carrito, totalStripe } = useUser();
    const [carritoSting, setCarritoString] = useState(JSON.stringify(carrito));


    useEffect(() => {
        setCarritoString(JSON.stringify(carrito))
        console.log("carrito", carrito)
        console.log("carrito json", carritoSting)

    }, [carrito, carritoSting]);

    // Función para calcular el GranTotal
    const calcularGranTotal = () => {
        let granTotal = carrito.reduce((total, producto) => total + producto.TotalCompra, 0);

        totalStripe(granTotal);
        // Redondear a dos decimales y convertir a número
        return parseFloat(granTotal.toFixed(2));
    };



    // Llama a la función para obtener el GranTotal
    const granTotal = calcularGranTotal();

    return (
        <View mt={[12, 12, 20, 24]} >
            <Text alignSelf={"center"} fontSize={"3xl"} my={20}>
                CARRITO
            </Text>

            <Text alignSelf={"center"} fontSize={"3xl"} my={20}>
                Total: {granTotal}
            </Text>
        </View>

    );
};

export default Carrito;
