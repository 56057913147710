import React from "react";
import { useUser } from "../../helper/UserContext";
import Login from "../Login";
import HistoryUserComponent from "../../components/users/HistoyrUserComponent";


const UserHistory = () => {

    const { tipo, userId } = useUser();


    return (
        <>
            {userId !== null && (
                tipo === "0" || "24" ? (
                    <HistoryUserComponent />
                ) : (
                    <Login />
                )
            )}
        </>
    );
}

export default UserHistory; 