import React, { useState, useEffect } from 'react'
import { View, Text, Pressable, Stack, Button } from 'native-base'
import { useNavigate } from 'react-router-dom'
import './Video.css'

const VideoComponent = () => {
  const navigate = useNavigate()
  const [isSmallScreen, setIsSmallScreen] = useState(false)
  const [videoHeight, setVideoHeight] = useState('950px')
  const videoUrl = 'https://mareaproperties.com.mx/imagenes/videoplayback.mp4'

  useEffect(() => {
    const breakpoints = {
      base: 0,
      sm: 480,
      md: 768,
      lg: 992,
      xl: 1280,
    }
    const handleResize = () => {
      const screenWidth = window.innerWidth
      setIsSmallScreen(screenWidth < breakpoints.md)

      if (screenWidth >= breakpoints.xl) {
        setVideoHeight('600px')
      } else if (screenWidth >= breakpoints.lg) {
        setVideoHeight('600px')
      } else if (screenWidth >= breakpoints.md) {
        setVideoHeight('550px')
      } else {
        setVideoHeight('500px')
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const videoBackgroundStyle = {
    position: 'relative',
    width: '100%',
    height: videoHeight,
    overflow: 'hidden',
  }

  const mediaStyle = {
    width: '100%',
    height: videoHeight,
    objectFit: 'cover',
    pointerEvents: 'none',
  }

  const contentStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
  }

  const handlePress = () => {
    window.open('https://app.hoone.io/booking/marea-properties', '_blank')
  }

  return (
    <View style={videoBackgroundStyle}>
      <div style={contentStyle}>
        <Text
          noOfLines={3}
          mb={10}
          bold
          w={['90%', '80%', '80%', '80%', '80%']}
          color='white'
          style={{
            fontFamily: "'Circular', sans-serif",
            textShadow: '2px 2px 4px rgba(0, 0, 0, 1)',
            animation: 'fadeInOut 16s ease-in-out infinite alternate',
          }}
          fontSize={['sm', 'md', '2xl', '5xl']}
          textAlign='center'
          rounded={'lg'}
          className={'fade-in-out'}
          mt={[-20, -20, 0, 0, 0]}>
          GESTIONAMOS, CUIDAMOS Y OPTIMIZAMOS TU INMUEBLE PARA CONVERTIRLO EN EL DESTINO IDEAL DE
          TUS FUTUROS HUÉSPEDES
        </Text>
      </div>
      <video autoPlay loop muted style={mediaStyle}>
        <source src={videoUrl} type='video/mp4' />
        Tu navegador no soporta videos HTML5.
      </video>

      <div style={contentStyle}>
        <Stack direction={{ base: 'column', lg: 'row' }} space={{ base: 4, lg: 4 }} mt={5}>
          <Button
            onPress={() => navigate('/BienesRaices')}
            mt={[32, 32, 64, 64, 64]}
            zIndex={999}
            p={2}
            borderRadius={10}
            shadow={9}
            px={[2, 6, 10, 16]}>
            <Text
              color='white'
              style={{ fontFamily: "'Circular', sans-serif" }}
              fontSize={['sm', 'md', 'lg', 'xl']}
              textAlign='center'>
              ADMINISTRAMOS TU PROPIEDAD
            </Text>
          </Button>

          <Button
            onPress={() => handlePress()}
            mt={[4, 4, 4, 64, 64]}
            zIndex={999}
            p={2}
            borderRadius={10}
            shadow={9}
            px={[2, 6, 10, 16]}>
            <Text
              color='white'
              style={{ fontFamily: "'Circular', sans-serif" }}
              fontSize={['sm', 'md', 'lg', 'xl']}
              textAlign='center'>
              ALQUILA UN DEPARTAMENTO
            </Text>
          </Button>
        </Stack>
      </div>
    </View>
  )
}

export default VideoComponent
