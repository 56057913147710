import { Heading, View } from "native-base";
import React from "react";

const VerVentasComponent = () => {
    return (
        <View w="100%" mt={[12, 12, 20, 24]} >
            <Heading my={40}>
                Ventas Admin
            </Heading>
        </View>
    )
}

export default VerVentasComponent;