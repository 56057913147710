import React, { useState, useEffect } from 'react';
import { VStack, Input, FormControl, TextArea, Box, Button, View, useToast, Text, Spinner, HStack, Stack } from 'native-base';
import URL from '../helper/baseURL';
import fetchPost from '../helper/fetchPost';
import { turquesa, azulclaro } from '../helper/colors';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';

const ContactoSimple = () => {

    const toast = useToast();

    const mostrarToast = (mensaje) => {
        toast.show({
            description: mensaje,
            placement: "top", // Puedes cambiar la ubicación según sea necesario
            render: () => {
                return <Box bg={azulclaro} px="6" py="4" rounded="sm" mb={5} borderWidth={3} borderColor={turquesa} shadow={7}>
                    <Text fontFamily="Circular" color="white">
                        {mensaje}
                    </Text>
                </Box>;
            }
        });
    };


    useEffect(() => {
        loadCaptchaEnginge(4, 'white', '#0d9aa1', 'upper');
        // Aquí, 6 es el número de caracteres en el captcha
    }, []);


    const [nombre, setNombre] = useState("");
    const [email, setEmail] = useState("");
    const [telefono, setTelefono] = useState("");
    const [mensaje, setMensaje] = useState("");

    const [isLoading, setIsLoading] = useState(false);

    const validarFormulario = () => {
        let userCaptchaValue = document.getElementById('user_captcha_input').value;
        // Validar nombre
        if (nombre.length <= 3) {
            mostrarToast('El nombre debe tener más de 3 letras.');
            return false;
        }

        // Validar correo electrónico
        const regexEmail = /\S+@\S+\.\S+/;
        if (!regexEmail.test(email)) {
            mostrarToast('Por favor, introduce un correo electrónico válido.');
            return false;
        }

        // Validar teléfono
        if (!telefono.match(/^\d{10,13}$/)) {
            mostrarToast('El teléfono debe tener al menos 10 dígitos');
            return false;
        }

        // Validar mensaje
        if (mensaje.trim() === '') {
            mostrarToast('Por favor, escribe un mensaje.');
            return false;
        }

        if (!validateCaptcha(userCaptchaValue)) {
            mostrarToast('Captcha Incorrecto');
            return false;
        }

        return true;
    };


    const handleSubmit = async () => {
        validarFormulario();
        if (!validarFormulario()) {
            return // Detiene la función si la validación falla
        } else {



            const BASE_URL = URL.BASE_URL;

            const dataContacto = new FormData();
            //para enviar datos por POST
            dataContacto.append("nombre", nombre);
            dataContacto.append("email", email);
            dataContacto.append("telefono", telefono);
            dataContacto.append("mensaje", mensaje);
            const url = `${BASE_URL}enviarcorreo`
            const options = {
                method: 'POST',
                body: dataContacto
            };
            setIsLoading(true);
            console.log("url: ", url)
            try {
                const response = await fetchPost(url, options);
                console.log("respuesta: ", response);
                if (response.status) {

                    // Si el estado es verdadero, muestra un mensaje de éxito
                    window.alert('Mensaje enviado');
                    setIsLoading(false);
                } else {
                    // Si el estado es falso, muestra un mensaje de error
                    window.alert('Error al enviar el mensaje');
                    setIsLoading(false);
                }
            } catch (error) {
                // Manejar cualquier otro error (por ejemplo, problemas de red)
                window.alert('Error al procesar la solicitud');
                setIsLoading(false);
            }
        }

    };




    return (
        <View w="100%"  >


            {/* FORMULARIO CONTACTO */}
            <VStack w="100%" space={4} alignSelf="center" bg="#fff" shadow={7}
                pb={8} mt={10} borderRadius={5} my={5} >
                <Text style={{ fontFamily: 'Circular' }} bg={turquesa} py={3}
                    textAlign="center" fontSize="2xl" color={"#fff"}>
                    Contáctanos
                </Text>

                <VStack w="90%" space={4} alignSelf="center" bg="#fff"
                    px={2} mt={5} borderRadius={5} my={5} >

                    <FormControl isRequired>
                        {/* <FormControl.Label _text={{ bold: true }}>Nombre: </FormControl.Label> */}
                        <Input shadow={7} value={nombre} placeholder="Nombre" onChangeText={(e) => setNombre(e)} />
                    </FormControl>

                    <HStack flex={1} space={5}>
                        <FormControl isRequired flex={1}>
                            {/* <FormControl.Label _text={{ bold: true }}>Correo: </FormControl.Label> */}
                            <Input shadow={6} value={email} type="email" placeholder="Email" onChangeText={(e) => setEmail(e)} />
                        </FormControl>

                        <FormControl isRequired flex={1}>
                            {/* <FormControl.Label _text={{ bold: true }}>Teléfono: </FormControl.Label> */}
                            <Input shadow={6} value={telefono} placeholder="Teléfono" onChangeText={(e) => setTelefono(e)} />
                        </FormControl>

                    </HStack>


                    <FormControl isRequired>
                        {/* <FormControl.Label _text={{ bold: true }}>Mensaje: </FormControl.Label> */}
                        <TextArea shadow={6} value={mensaje} placeholder="Escribe tu mensaje aquí" onChangeText={(e) => setMensaje(e)} />
                    </FormControl>
                    <Stack direction={["column", "row"]} space={8} mt={7} alignSelf="center" >
                        <LoadCanvasTemplate reloadText="Actualiza el Captcha" reloadColor={turquesa} />
                        <input
                            type="text" id="user_captcha_input" placeholder=" Valor del captcha" />

                    </Stack>
                    {isLoading ? <Spinner mt={4} size={56} color={turquesa} /> :
                        <Button shadow={6} colorScheme="amber" onPress={() => handleSubmit()} my={4} size="lg" py={3} w="60%" alignSelf="center">
                            Enviar
                        </Button>
                    }


                </VStack>

            </VStack>

        </View >
    );
};

export default ContactoSimple;